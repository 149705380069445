import axios from 'axios'
import { Message } from 'element-ui'
import Vue from "vue"
import $loading from "@/components/loading"

Vue.use($loading)

// axios 默认get请求  可以不写参数method: 'get'

// 创建实例
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API_URL
})

var isShow = false
// request interceptor
service.interceptors.request.use(
	config => {
		// 封装参数 是post get 通用data参数
		if(config.method == 'get'){
			config.params = config.data
			config.data = ''
		}

		isShow = false;
		isShow = config.isShow
		if (config.isShow) {
			Vue.$loading.show('拼命加载中...')
		}
		config.headers["Access-Control-Allow-Origin"] = "*"
		return config
	},
	error => {
		Vue.$loading.hide()
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	response => {
		const res = response.data
		if (res.code !== 'v' && res.code !== 'pglist') {
			if (res.code == 401) {
				Vue.$loading.hide()
				Message({
					message: res.msg || '请求失败',
					type: 'error',
					duration: 5 * 1000
				})
				return Promise.reject(new Error(res.msg || '请求失败'))
			} else {
				Message({
					message: res.msg || '请求失败',
					type: 'error',
					duration: 5 * 1000
				})
				Vue.$loading.hide()
				return Promise.reject(new Error(res.msg || '请求失败'))
			}

		} else {
			Vue.$loading.hide()
			return res
		}
	},
	error => {
		Message({
			message: error.msg,
			type: 'error',
			duration: 5 * 1000
		})
		Vue.$loading.hide()
		return Promise.reject(error)
	}
)

export default service
