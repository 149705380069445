<template>
    <div class="resume">
        <humanResources tabIndex="1"></humanResources>
        <div class="jobBg">
            <div class="textTitle2">瑞和安惠项目管理集团应聘信息登记表</div>
        </div>
        <div class="mainBox">
            <el-form ref="form" class="personInfo" :model="form" :rules="rules" label-width="100px">
                <el-col :span="8">
                    <el-form-item label="姓名" placeholder="请输入姓名" prop="truename">
                        <el-input v-model="form.truename" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="性别" prop="sex">
                        <el-select v-model="form.sex" placeholder="请选择性别" style="width:100%">
                            <el-option label="男" value="男"></el-option>
                            <el-option label="女" value="女"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="血型" prop="bloodtype">
                        <el-select v-model="form.bloodtype" placeholder="请选择血型" style="width:100%">
                            <el-option label="A" value="A"></el-option>
                            <el-option label="B" value="B"></el-option>
                            <el-option label="AB" value="AB"></el-option>
                            <el-option label="O" value="O"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="出生日期" prop="birthday">
                        <el-date-picker
                            v-model="form.birthday"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择出生日期" style="width:100%">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="学历" prop="education">
                        <el-select v-model="form.education" placeholder="请选择学历" style="width:100%">
                            <el-option label="高中" value="高中"></el-option>
                            <el-option label="中专" value="中专"></el-option>
                            <el-option label="大专" value="大专"></el-option>
                            <el-option label="本科" value="本科"></el-option>
                            <el-option label="硕士" value="硕士"></el-option>
                            <el-option label="博士" value="博士"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="民族" prop="nation">
                        <el-input v-model="form.nation" placeholder="请输入民族"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="应聘职位" prop="position">
                        <el-input v-model="form.position" placeholder="请输入应聘职位"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="户籍所在地" prop="household">
                        <el-input v-model="form.household" placeholder="请输入户籍所在地"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="政治面貌" prop="zzmm">
                        <el-input v-model="form.zzmm" placeholder="请输入政治面貌"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="16">
                    <el-form-item label="籍贯" prop="birthplace">
                        <el-input v-model="form.birthplace" placeholder="请输入籍贯"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="婚姻状况" prop="maritalstatus">
                        <el-select v-model="form.maritalstatus" placeholder="请选择婚姻状况" style="width:100%">
                            <el-option label="已婚" value="已婚"></el-option>
                            <el-option label="未婚" value="未婚"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="16">
                    <el-form-item label="身份证号" prop="identityid">
                        <el-input v-model="form.identityid" placeholder="请输入身份证号"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="驾驶证">
                        <el-select v-model="form.driversLicense" placeholder="请选择有无驾驶证" style="width:100%">
                            <el-option label="有" value="shanghai"></el-option>
                            <el-option label="无" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="16">
                    <el-form-item label="家庭住址" prop="address">
                        <el-input v-model="form.address" placeholder="请输入家庭住址"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="个人电话" prop="phone">
                        <el-input v-model="form.phone" placeholder="请输入个人电话" :maxlength="11"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="16">
                    <el-form-item label="毕业学校" prop="graduateschool">
                        <el-input v-model="form.graduateschool" placeholder="请输入毕业学校"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="所学专业" prop="profession">
                        <el-input v-model="form.profession" placeholder="请输入所学专业"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="毕业时间">
                        <el-date-picker
                        v-model="form.dateOfGraduation"
                        type="date"
                        placeholder="选择毕业时间" style="width:100%">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="职称证级别" prop="titlelevel">
                        <el-select v-model="form.titlelevel" placeholder="请选择职称证级别" style="width:100%">
                            <el-option label="无" value="无"></el-option>
                            <el-option label="初级" value="初级"></el-option>
                            <el-option label="中级" value="中级"></el-option>
                            <el-option label="高级" value="高级"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="职称证专业" prop="titleprofession">
                        <el-input v-model="form.titleprofession" placeholder="请输入职称证专业"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="工作经历" prop="workdetail">
                        <el-input type="textarea" class="textarea" v-model="form.workdetail" placeholder="请输入工作经历"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="验证码：" prop="code">
                        <el-input v-model="form.code" placeholder="请输入验证码"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <img class="captchaImage" :src="img" @click="getCaptchaImage"/>
                </el-col>
            </el-form>
            <div class="btnBox">
                <div class="btn" @click="onSubmit">确认保存</div>
                <div class="btn" @click="resetForm('form')">重置</div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
    .jobBg{
        width: 100%;
        height: 561px;
        background: url('../../assets/humanResources/jobBg.png');
        background-size: 100% 100%;
    }
    .mainBox{
        width: 1200px;
        margin: 0 auto;
        background-color: #fff;
        box-shadow: 0px 0px 23px 1px rgba(0,0,0,0.07);
        margin-top: -345px;
        border-radius: 10px;
        padding: 66px 40px;
    }
    .resume {
        padding-bottom: 42px;
        .textTitle2 {
            color: #333;
            font-size: 30px;
            padding-top: 85px;
        }
        .captchaImage {
            margin-left: 20px;
            height: 40px;
            cursor: pointer;
        }

        .btnBox {
            overflow: hidden;
            margin: 13px auto 0;
            width: 280px;
        }
        .btn {
            float: left;
            cursor: pointer;
            width: 120px;
            height: 42px;
            line-height: 42px;
            background: #247CD6;
            font-size: 14px;
            color: #fff;
            margin: 0 auto;
            text-align: center;
            font-weight: bold;
            border-radius: 3px;
        }
        .btn:first-child {
            margin-right: 40px;       
        }
        .textarea {
            textarea {
                height: 300px;
            }
        }
    }
    .personInfo {
        overflow: hidden;
    }
</style>
<style>
    .personInfo .el-input__icon{
        line-height: 36px;
    }
</style>
<script>
import { Message } from 'element-ui'
import humanResources from '../../components/humanResources'
export default {
    name: 'Resume',
    components: {
        humanResources
    },
    data() {
        return {
            showId: '001',
            date:'',
            canSubmit: true,
            form: {
                truename: '',
                sex: '',
                bloodtype: '',
                birthday: '',
                education: '',
                nation: '',
                position: '',
                household: '',
                zzmm: '',
                birthplace: '',
                maritalstatus: '',
                identityid: '',
                // driversLicense: '',
                address: '',
                phone: '',
                graduateschool: '',
                profession: '',
                // dateOfGraduation: '',
                titlelevel: '',
                titleprofession: '',
                workdetail: '',
                code: '',
                uuid: '',
            },
            img: '',
            rules:{},
            rule: {
                truename: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                sex: [
                    { required: true, message: '请选择性别', trigger: 'change' }
                ],
                bloodtype: [
                    { required: true, message: '请选择血型', trigger: 'change' }
                ],
                birthday: [
                    { required: true, message: '请选择出生日期', trigger: 'blur' }
                ],
                education: [
                    { required: true, message: '请选择学历', trigger: 'change' }
                ],
                nation: [
                    { required: true, message: '请输入民族', trigger: 'blur' }
                ],
                position: [
                    { required: true, message: '请输入应聘职位', trigger: 'blur' }
                ],
                household: [
                    { required: true, message: '请输入户籍所在地', trigger: 'blur' }
                ],
                zzmm: [
                    { required: true, message: '请输入政治面貌', trigger: 'blur' }
                ],
                birthplace: [
                    { required: true, message: '请输入籍贯', trigger: 'blur' }
                ],
                maritalstatus: [
                    { required: true, message: '请输入婚姻状况', trigger: 'blur' }
                ],
                identityid: [
                    { required: true, message: '请输入身份证号', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '请输入家庭住址', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入个人电话', trigger: 'blur' },
                    { min: 11, max: 11, message: '个人电话格式不正确', trigger: 'blur' }
                ],
                graduateschool: [
                    { required: true, message: '请输入毕业学校', trigger: 'blur' }
                ],
                profession: [
                    { required: true, message: '请输入所学专业', trigger: 'blur' }
                ],
                titlelevel: [
                    { required: true, message: '请选择职称证级别', trigger: 'change' }
                ],
                titleprofession: [
                    { required: true, message: '请选择职称证专业', trigger: 'change' }
                ],
                workdetail: [
                    { required: true, message: '请输入工作经历', trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        // 提交数据
        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid && this.canSubmit) {
                    this.canSubmit = false
                    this.Request({
                        url: '/webindex/renli/deliverResume',
                        data: this.form,
                        method: 'post',
                    }).then((data) => {
                        Message({
                            message: '提交成功',
                            type: 'success',
                            duration: 3000
                        })
                        this.$router.replace({
                            path: '/humanResources/job'
                        })
                    }).catch((data) => {
                        this.canSubmit = true
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 重置
        resetForm(formName) {
            console.log(this.$refs[formName].resetFields)
            this.$refs[formName].resetFields();
        },
        // 获取验证码
        getCaptchaImage(){
            this.Request({
                url: '/webindex/captchaImage',
            }).then((data) => {
                this.form.uuid = data.tdata.uuid
                this.img = `data:image/png;base64,${data.tdata.img}`
            })
        }
    },
    mounted(){
        this.getCaptchaImage()
    }
}
</script>