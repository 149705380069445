<template>
    <div class="introductionBox">
       <businessBanner tabIndex="3"></businessBanner>
       <div class="mainShow">
            <p class="font20 fontw">招标代理</p>
            <p class="mt36 jies">招标代理业务是集团最早开展的业务板块，早在2000年集团成立之初就作为主营业务发展至今。集团公司也是省内最早一批取得工程建设甲级、政府采购甲级、中央投资甲级、国际招标甲级等原四甲招标资质的代理机构。</p>
       </div>
       <div class="mainShows" >
            <!-- <div class="main clearfix">
                <div class="fl cont">
                    <p class="contT">技术人才</p>
                    <p>经过二十多年的积累与沉淀，集团招标代理业务已发展成为拥有20余个独立业务部门，100余名专业技术人员的业务板块。</p>
                </div>
                <div class="fr techBox">
                    <img src="@/assets/businessScope/duli.png" alt="">
                    <img src="@/assets/businessScope/zhuany.png" alt="">
                </div>
            </div> -->
            <div class="mainShow clearfix" style="padding-top:30px;padding-bottom:72px">
                <div class="fl contLeft">
                    <p class="contT">招标代理业务</p>
                    <p>集团招标代理项目年立项2600余项，年累计招标资金数百亿元，无论在项目数量还是在招标金额方面，集团招标代理业务常年稳居省内前三。</p>
                </div>
                <img class="fr zhaobImg" src="../../assets/businessScope/zhaob.png" alt="">
            </div>
       </div>
       <div class="mainShow">
            <div class="main clearfix" style="padding-bottom:60px">
                <img class="fl dianx" src="../../assets/businessScope/dianx.png" alt="">
                <div class="fr contd" style="margin-top:-20px">
                    <p class="contT">典型案例</p>
                    <p>行业领域覆盖全面，业绩案例影响广泛，完成的典型案例有邢衡、京新、南绕城、延崇、京雄、荣乌、秦唐、绥满、曲港等高速公路主体工程、石家庄新客站项目、石家庄轨道交通1-6号线工程等国家及省市重点项目招标。</p>
                </div>
            </div>
       </div>
       <div class="mainShows">
            <div class="mainShow clearfix">
                <div class="fl contLeft" style="margin-top:10px">
                    <p class="contT">业务荣誉</p>
                    <p>凭借优质的资质资源、突出的业务能力以及丰富的业绩案例，集团获得了多家国家及省级行业权威机构认可：中国招标投标协会AAA级信用企业、中国招标投标协会会员单位、河北省招标投标协会副会长单位、河北省公共资源交易协会副会长单位，并多次获得国家及省市级荣誉称号：全国政府采购百强代理机构、全国政府采购十佳代理机构、全国招标代理机构诚信单位、中国招标代理机构综合实力百强等。</p>
                </div>
                <img class="fr yjalImg" src="../../assets/businessScope/yjal.png" alt="">
            </div>
       </div>
    </div>
</template>

<script>
import businessBanner from '../../components/businessBanner'
	export default {
		name:'EarlierConsultation',
        components:{businessBanner},
        data() {
            return {
                
            }
        },
        mounted() {
           
        }
	}
</script>

<style scoped>
    .mainShows{
        width: 100%;
        background-color: #F7F9FD;
        margin-top: 42px;
    }
    .main{
        width: 1200px;
        margin: 0 auto;
        padding-top:47px;
        padding-bottom: 100px;
    }
    .jies{
        line-height: 30px;
        text-indent: 2em;
    }
    .cont{
        width: 525px;
        color: #666;
    }
    .contT{
        color: #247CD6;
        font-size: 28px;
        margin-top: 60px;
        margin-bottom: 31px;
    }
    .contT+p{
        line-height: 36px;
    }
    .techBox{
        display: flex;
        justify-content: space-between;
        margin-top: 44px;
        margin-right: 100px;
        width: 433px;
    }
    .techBox img{
        width: 172px;
        height: 161px;
    }
    .conts{
        width: 520px;
        color: #666;
        margin-top: 10px;
    }
    .zhaobImg{
        width: 372px;
        margin-right: 170px;
        margin-top: 66px;
    }
    .dianx{
        width: 678px;
        margin-left: -10px;
        margin-top: 22px;
    }
    .contd{
        width: 530px;
        color: #666;
    }
    .contLeft{
        width:525px;
        color: #666;
        margin-top: 30px;
    }
    .yjalImg{
        width: 608px;
        margin-top: 57px;
        margin-bottom: 105px;
    }
</style>