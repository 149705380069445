<template>
	<div>
		<Header/>
		<div class="contentBox">
			<router-view></router-view>
		</div>
		<Footer/>
	</div>
</template>

<style lang="less" scoped>
	.contentBox {
		min-height: calc(100vh - 80px - 170px);
	}
</style>

<script>
	import Header from "./Header.vue";
	import Footer from "./Footer.vue";
	export default {
		name:'Index',
		components: {
			Header,
			Footer
		},
		/* beforeDestroy() {
			console.log('About组件即将被销毁了')
		},*/
		/* mounted() {
			console.log('About组件挂载完毕了',this)
			window.aboutRoute = this.$route
			window.aboutRouter = this.$router
		},  */
	}
</script>