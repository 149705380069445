<template>
    <div class="workersGroupDetails">
        <mediaCenter tabIndex="6"></mediaCenter>
        <div class="contentBox">
           <div class="content">
                <p class="title">{{info.title}}</p>
                <div class="contents" v-html="info.content"></div>
           </div>
        </div>
    </div>
</template>

<script>
     import mediaCenter from '@/components/mediaCenter'
	export default {
		name:'mediaCenterd',
        components: {
            mediaCenter
        },
        data() {
            return {
                info:''
            }
        },
        mounted(){
           this.getInfo()
        },
       methods:{
         //获取行业资讯详情
         getInfo(){
            this.Request({
                url: '/webindex/news/newsDetail',
                data: {
                    id: this.$route.query.id
                },
                method: 'get',
            }).then((data) => {
                this.info = data.tdata
            })
        }
       }
	}
</script>

<style scoped lang="less">
    .contentBox{
        background-color: #F7F8F9;
        padding: 42px 0;
    }
    .content{
        width: 1200px;
        margin: 0 auto;
        background-color: #fff;
        padding: 44px;
        border-radius: 3px;
    }
    .title{
        margin-top: 13px;
        text-align: center;
        font-size: 21px;
        font-weight: bold;
    }
    .contents{
        margin-top: 64px;
    }
    .contents img{
        max-width: 100%;
    }
</style>