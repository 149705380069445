<template>
    <div class="headerBox">
        <div class="headerContent">
            <div class="logo">
                <img src="@/assets/logo.png">
            </div>
            <el-menu :default-active="activeIndex" class="el-menu-demo menuBox" mode="horizontal" @select="handleSelect">
                <el-menu-item class="menuItem" index="index">首页</el-menu-item>
                <el-menu-item class="menuItem" index="aboutUs">关于瑞和</el-menu-item>
                <el-menu-item index="businessScope">业务范围</el-menu-item>
                <el-menu-item index="humanResources">职业发展</el-menu-item>
                <el-menu-item index="cooperate">合作共赢</el-menu-item>
                <el-menu-item index="mediaCenter">媒体中心</el-menu-item>
                <el-menu-item index="linkUs">联系我们</el-menu-item>
            </el-menu>
        </div>
    </div>
</template>

<style lang="less">
.headerBox {
    display: flex;
    justify-content: center;
}

.headerContent {
    width: 1200px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
        img {
            height: 80px;
            width: auto;
            margin-top: 5px;
        }
    }
}
.el-menu-item:hover{
    color: #247CD6 !important;
}
.el-menu--horizontal>.el-menu-item.is-active{
    color: #247CD6 !important;
}
.el-menu--horizontal>.el-menu-item.is-active .el-menu-item__title{
    color: #247CD6 !important;
}
</style>

<script>
export default {
    name: 'Header',
    data() {
        return {
            activeIndex: '1',
        };
    },
    watch: {
        $route: {
            handler(value){
                // 初始化activeIndex
                let path = value.path.split('/')
                if(path){
                    let activeIndex = path[1]
                    if(activeIndex == 'resume'){
                        activeIndex = 'job'
                    }
                    this.activeIndex = activeIndex
                }
            },
            immediate: true
        }
    },
    mounted(){
    },
    methods: {
        handleSelect(key, keyPath) {
            let path = ''
            if(key=='index'){ //首页
                path+='/index'
            }
            if(key=='aboutUs'){ //关于瑞和
                path+='/aboutUs/CorporateCulture'
            }
            if(key=='businessScope'){ //业务范围
                path+='/businessScope/earlierConsultation'
            }
            if(key=='humanResources'){ //职业发展
                path+='/humanResources/Job'
            }
            if(key=='cooperate'){ //合作共赢
                path+='/cooperate/Index'
            }
            if(key=='mediaCenter'){ //媒体中心
                path+='/mediaCenter/IndustryNews'
            }
            if(key=='linkUs'){ //联系我们
                path+='/linkUs/ContactUs'
            }
            this.$router.push({
                path,
            })
        }
    }
}
</script>