<template>
    <div>
        <div class="headerBanner">
            <div class="headerBannerBg" :style="`background-image: url(${bannerBg});`">
                <img :class="'title'+tabIndex" :src="title" alt="">
                <img :class="'desc'+tabIndex" :src="desc" alt="">
            </div>
            <div class="daohang">
                <div class="daohBox">
                    <div v-for="(item,index) in list" :key="index" :class="tabIndex==index+1?'active':''" @click="turnClick(item.url)">{{item.name}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '../assets/linkUs/banner.png'
import title1 from '../assets/linkUs/linkTtitle.png'
import title2 from '../assets/linkUs/feedTitle.png'
import desc1 from '../assets/linkUs/linkDesc.png'
import desc2 from '../assets/linkUs/feedDesc.png'
export default {
    name: 'aboutBanner',
    props:['tabIndex'],
    data() {
        return {
            bannerBg:banner,
            title:'',
            desc:'',
            list:[
                {
                    name:'联系方式',
                    url:'/linkUs/ContactUs'
                },
                {
                    name:'反馈中心',
                    url:'/linkUs/FeedBack'
                },
            ]
        };
    },
    watch: {
            $route: {
                handler(value){
                    let bannerBg = value.matched[value.matched.length - 1].meta.bannerBg
                    switch(bannerBg){
                        case 'banner001': 
                            this.title = title1
                            this.desc= desc1
                            break;
                        case 'banner002': 
                            this.title = title2
                            this.desc= desc2
                            break;
                        default:
                            this.title = title1
                            this.desc= desc1
                    }
                    window.scrollTo(0,0)
                },
                immediate: true
            }
        },
    mounted(){
        
    },
    methods: {
        //二级导航跳转
        turnClick(url){
            this.$router.push({
                path:url
            })
        }
    }
}
</script>
<style scoped>
     .headerBannerBg {
        height: 260px;
        background-size: cover;
        background-position: center;
    }
    .title1{
        display: block;
        width:785px;
        margin: 0 auto;
        padding-top: 76px;
    }
    .desc1{
        display: block;
        width: 538px;
        margin: 0 auto;
        margin-top: 35px;
    }
    .title2{
        display: block;
        width:192px;
        margin: 0 auto;
        padding-top: 76px;
    }
    .desc2{
        display: block;
        width: 835px;
        margin: 0 auto;
        margin-top: 35px;
    }
    .daohang{
        width: 100%;
        height: 50px;
        background-color: #fff;
        box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.04);
    }
    .daohBox{
        width: 200px;
        margin: 0 auto;
        line-height: 48px;
        display: flex;
        justify-content: space-around;
        color: #333;
        font-size: 16px;
        font-weight: 400;
    }
    .daohBox div{
        cursor: pointer;
        padding: 0 5px;
    }
    .active{
        color: #247CD6;
        border-bottom: 2px solid #247CD6;
        padding: 0 5px;
        font-weight: bold;
    }
</style>