<template>
    <div>
        <div class="headerBanner">
            <div class="headerBannerBg" :style="`background-image: url(${bannerBg});`">
                <img class="title" :src="title" alt="">
                <img class="desc" :src="desc" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import banner from '../assets/cooperate/banner.png'
import title from '../assets/cooperate/title.png'
import desc from '../assets/cooperate/desc.png'
export default {
    name: 'cooperate',
    data() {
        return {
            bannerBg:banner,
            title:title,
            desc:desc,
        };
    },
    watch: {
        },
    mounted(){
        
    },
    methods: {
        //二级导航跳转
        turnClick(url){
            this.$router.push({
                path:url
            })
        }
    }
}
</script>
<style scoped>
     .headerBannerBg {
        height: 260px;
        background-size: cover;
        background-position: center;
    }
    .title{
        display: block;
        width:439px;
        margin: 0 auto;
        padding-top: 76px;
    }
    .desc{
        display: block;
        width: 631px;
        margin: 0 auto;
        margin-top: 35px;
    }
</style>