<template>
	<div class="footBox">
		<div class="foot">
			<div class="footLeft">
				<img class="logos" src="../assets/logos.png" alt="">
				<div class="info">
					<div class="infoTel">
						<p class="iconT">联系电话：</p>
						<p>+86-0311-69052085</p>
					</div>
				</div>
				<div class="addr">
					<p class="iconT">公司地址：</p>
					<p>河北省石家庄市建设南大街269号河北师范大学科技园B座11、12层</p>
				</div>
				<div class="ewmBox clearfix">
					<img class="fl" src="../assets/wx.png" alt="">
					<div class="fl">
						<p>瑞和安惠集团公众号</p>
						<p class="iconT">快速获取瑞和热点信息</p>
					</div>
				</div>
			</div>
			<div class="footRight">
				<div class="daohang">
					<p class="font16">瑞和首页</p>
					<div class="">
						<p class="iconT" v-for="(item,index) in homeList" :key="index+'h'" @click="jumpUrl(item.url)">{{item.name}}</p>
					</div>
				</div>
				<div class="daohang">
					<p class="font16">关于瑞和</p>
					<div class="">
						<p class="iconT" v-for="(item,index) in aboutList" :key="index+'h'" @click="jumpUrl(item.url)">{{item.name}}</p>
					</div>
				</div>
				<div class="daohang">
					<p class="font16">业务范围</p>
					<div class="">
						<p class="iconT" v-for="(item,index) in areaList" :key="index+'h'" @click="jumpUrl(item.url)">{{item.name}}</p>
					</div>
				</div>
				<div class="daohang">
					<p class="font16">职业发展</p>
					<div class="">
						<p class="iconT" v-for="(item,index) in quaList" :key="index+'h'" @click="jumpUrl(item.url)">{{item.name}}</p>
					</div>
				</div>
				<div class="daohang">
					<p class="font16">合作共赢</p>
					<div class="">
						<p class="iconT" v-for="(item,index) in coorList" :key="index+'h'" @click="jumpUrl(item.url)">{{item.name}}</p>
					</div>
				</div>
				<div class="daohang">
					<p class="font16">媒体中心</p>
					<div class="">
						<p class="iconT" v-for="(item,index) in mediaList" :key="index+'h'" @click="jumpUrl(item.url)">{{item.name}}</p>
					</div>
				</div>
				<div class="daohang">
					<p class="font16">联系我们</p>
					<div class="">
						<p class="iconT" v-for="(item,index) in linkList" :key="index+'h'" @click="jumpUrl(item.url)">{{item.name}}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="banqBox">
			<div class="banqs">版权：瑞和安惠项目管理集团  <span @click="beibans">冀ICP备2023023821号-1</span> </div>
			<div class="beian clearfix" @click="beiban">
				<img class="" src="../assets/jing.png">
				<span>冀公网安备 13010802001839号</span>
			</div>
			<a href="https://xyt.xcc.cn/getpcInfo?sn=1796453152581427200&language=CN&certType=8&url=www.ruihepm.com" target="_blank" style="position: absolute;top: 10px;right:-100px;">
				<embed src="https://program.xinchacha.com/web/1796453152581427200=www.ruihepm.com.svg" width="103" height="38" type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/"/>
			</a>
			<a href="https://xyt.xcc.cn/getpcInfo?sn=1796453152581427200&language=EN&certType=8&url=www.ruihepm.com" target="_blank" style="position: absolute;top: 10px;right:-220px;">
			<embed src="https://program.xinchacha.com/web/1796453152581427200=www.ruihepm.comen.svg" width="103" height="38" type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/"/></a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Footer',
	data() {
		return {
			homeList:[
				{
					name:'关于瑞和',
					url:'/aboutUs/CorporateCulture'
				},
				{
					name:'业务范围',
					url:'/businessScope/earlierConsultation'
				},
				{
					name:'职业发展',
					url:'/humanResources/Job'
				},
				{
					name:'合作共赢',
					url:'/cooperate/Index'
				},
				{
					name:'媒体中心',
					url:'/mediaCenter/IndustryNews'
				},
				{
					name:'联系我们',
					url:'/linkUs/ContactUs'
				}
			],
			aboutList:[
				{
					name:'企业文化',
					url:'/aboutUs/CorporateCulture'
				},
				{
					name:'公司简介',
					url:'/aboutUs/Introduction'
				},
				{
					name:'奖项与荣誉',
					url:'/aboutUs/AwardsAndHonors'
				},
				{
					name:'社会价值',
					url:'/aboutUs/SocialValue'
				},
				{
					name:'党工妇团',
					url:'/aboutUs/workWomenCorps'
				},
				{
					name:'分支结构',
					url:'/aboutUs/Branch'
				}
			],
			areaList:[
				{
					name:'前期咨询',
					url:'/businessScope/earlierConsultation'
				},
				{
					name:'造价咨询',
					url:'/businessScope/costConsultation'
				},
				{
					name:'招标代理',
					url:'/businessScope/biddingAgent'
				},
				{
					name:'管理咨询',
					url:'/businessScope/projectManagement'
				},
				{
					name:'全咨业务',
					url:'/businessScope/consultationBusiness'
				},
				{
					name:'数字化应用',
					url:'/businessScope/BIMApplication'
				}
			],
			quaList:[
				{
					name:'人才招聘',
					url:'/humanResources/job'
				},
				{
					name:'团队活动',
					url:'/humanResources/groupActivities'
				}
			],
			coorList:[
				{
					name:'对外合作',
					url:'/cooperate/foreignCooperation'
				},
			],
			mediaList:[
				{
					name:'行业资讯',
					url:'/mediaCenter/industryNews'
				},
				{
					name:'企业动态',
					url:'/mediaCenter/companyNews'
				},
				{
					name:'公告',
					url:'/mediaCenter/notice'
				},
				{
					name:'公示',
					url:'/mediaCenter/Public'
				},
				{
					name:'澄清与修改',
					url:'/mediaCenter/Clarify'
				},
				{
					name:'公众参与公示',
					url:'/mediaCenter/NoticePub'
				},
			],
			linkList:[
				{
					name:'联系方式',
					url:'/linkUs/ContactUs'
				},
				{
					name:'反馈中心',
					url:'/linkUs/FeedBack'
				},
			]
		};
	},
	mounted() { },
	methods: {
		//页面跳转
		jumpUrl(url){
			this.$router.push({
				path:url
			})
			// window.location.assign(url)
		},
		beibans(){
			window.location.href='https://beian.miit.gov.cn/#/Integrated/index'
		},
		//点击备案
		beiban(){
			window.location.href='https://beian.mps.gov.cn/#/query/webSearch'
		}
	},
};
</script>

<style scoped>
	.footBox{
		width: 100%;
		height: 497px;
		background-color: #1D2129;
		/* margin-top: 44px; */
		padding-top:40px;
		color: #fff;
		font-size: 14px;
	}
	.foot{
		width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: space-around;
		border-bottom: 1px solid #31363D;
		padding-bottom: 28px;
	}
	.footLeft{
		width: 417px;
	}
	.logos{
		width: 211px;
		height: 40px;
	}
	.info{
		margin-top: 24px;
		display: flex;
		justify-content: flex-start;
	}
	.iconT{
		color: #fff;
		opacity: 0.5;
		margin-bottom: 5px;
	}
	.infoTel{
		margin-right: 60px;
	}
	.addr{
		margin-top: 30px;
	}
	.ewmBox{
		width: 417px;
		height: 100px;
		background-color: #2A303C;
		margin-top: 30px;
		padding: 15px 20px;
	}
	.ewmBox img{
		width: 70px;
		height: 70px;
		margin-right: 20px;
	}
	.ewmBox div{
		margin-top: 10px;
	}
	.ewmBox .iconT{
		margin-top: 5px;
	}
	.footRight{
		width: calc(100% - 417px);
		padding-left: 40px;
		display: flex;
		justify-content: space-between;
	}
	.footRight .iconT:hover{
		color: #3d81ff;
	}
	.font16{
		font-size: 16px;
		margin-bottom: 20px;
	}
	.daohang .iconT{
		cursor: pointer;
		line-height: 30px;
	}
	.banqBox{
		position: relative;
		width: 400px;
		margin: 0 auto;
	}
	.banqs{
		width: 100%;
		margin: 0 auto;
		margin-top: 40px;
		text-align: center;
		opacity: 0.5;
	}
	.banqs span{
		cursor: pointer;
	}
	.beian{
		width: 230px;
		margin: 0 auto;
		margin-top: 20px;
		text-align: center;
		cursor: pointer;
	}
	.beian img{
		width: 18px;
		height: 20px;
		float: left;
		margin-right: 6px;
	}
	.beian span{
		opacity: 0.5;
	}
</style>
