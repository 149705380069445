<template>
    <div class="IndustryNews">
        <mediaCenter tabIndex="2"></mediaCenter>
        <div class="industryBox">
            <div class="industryList clearfix" v-for="(item,index) in companyNewsList" :key="index">
                <div class="fl insLeft">
                    <div class="fl day">{{item.addtime.split('-')[2]}}/</div>
                    <div class="fl yearMon">
                        <p>{{item.addtime.split('-')[1]}}月</p>
                        <p>{{item.addtime.split('-')[0]}}</p>
                    </div>
                </div>
                <div class="fl insRight">
                    <p class="title curp biaot" @click="jumpToDetails(item.id)">{{item.title}}</p>
                    <p class="desc overh2" v-html="item.descirbes"></p>
                    <p class="lookInfo" @click="jumpToDetails(item.id)">查看详情</p>
                </div>
            </div>
            <div class="" v-if="companyNewsList.length==0">
                <el-empty description="暂无数据"></el-empty>
            </div>
            <el-pagination class="pageBox" v-if="companyNewsList.length!=0" background @current-change="handleCurrentChange" :current-page.sync="currentPage" layout="prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
    </div>
</template>

<script>
import mediaCenter from '@/components/mediaCenter'
	export default {
		name:'IndustryNews',
        components: {
            mediaCenter
        },
        data() {
            return {
                companyNewsList: [],
                currentPage:1,
                pageindex:1,
                total:0
            }
        },
        mounted(){
            this.getCompanyNews()
        },
        methods: {
             //获取企业动态列表
             getCompanyNews(){
                this.Request({
                    url: '/webindex/news/companyNewsList',
                    method: 'get',
                    data:{
                        pageindex:this.pageindex,
                        pagesize:10
                    }
                }).then((data) => {
                    if(data.rows && data.rows.length > 0){
                        this.companyNewsList = data.rows
                        this.total = data.total
                    }
                })
            },
            jumpToDetails(id) {
                // this.$router.push({
                //     path: '/mediaCenter/CompanyNewsDetails?id='+id,
                // })
                window.location.assign('/mediaCenter/CompanyNewsDetails?id='+id)
            },
            handleCurrentChange(val){
                this.currentPage = val
                this.pageindex=val
                this.getCompanyNews()
            }

        }
	}
</script>

<style scoped>
    .industryBox{
        width: 1200px;
        margin: 0 auto;
        margin-top: 17px;
    }
    .industryList{
        padding: 35px 30px 35px 28px;
        border-bottom: 1px solid #E1E1E1;
    }
    .insLeft{
        padding-right: 38px;
        border-right: 1px solid #E1E1E1;
        padding-top: 47px;
        padding-bottom: 60px;
    }
    .day{
        font-size: 42px;
    }
    .yearMon{
        font-size: 14px;
        color: #333;
        margin-top: 10px;
        margin-left: 5px;
    }
    .insRight{
        padding-left: 50px;
        width: 990px;
    }
    .title{
        font-size: 20px;
        font-weight: 400;
    }
    .desc{
        color: #666;
        margin-top: 27px;
        line-height: 28px;
    }
    .lookInfo{
        margin-top: 24px;
        color: #247CD6;
        cursor: pointer;
    }
    .pageBox{
        text-align: center;
        margin-top: 35px;
        margin-bottom: 42px;
    }
</style>