//引入Vue
import Vue from 'vue'
//引入App
import App from './App.vue'
//引入VueRouter
import VueRouter from 'vue-router'
//引入路由器
import router from './router'
// 引入request
import Request from './utils/Request'
import 'element-ui/lib/theme-chalk/index.css';
import store from './store'
import ElementUI from 'element-ui'

import './css/common.css'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap,{
	ak:'WxCDb8T3BlyKFESDWd5zaZDFsd6qt0na'
})
//关闭Vue的生产提示
Vue.config.productionTip = false
//应用插件
Vue.use(VueRouter)
Vue.use(ElementUI)
Vue.mixin({
	created(){
		this.Request = Request
	}
})

//创建vm
new Vue({
	el:'#app',
	render: h => h(App),
	store,
	router:router
})

