<template>
    <div>
        <div class="headerBanner">
            <div class="headerBannerBg" :style="`background-image: url(${bannerBg});`">
                <template v-if="tabIndex==1">
                    <img class="title title1" :src="title" alt="">
                </template>
                <template v-if="tabIndex==2">
                    <img class="title title2" :src="title" alt="">
                    <img class="desc" :class="'desc'+tabIndex" :src="desc" alt="">
                </template>
                <template v-if="tabIndex==3">
                    <img class="title title3" :src="title" alt="">
                </template>
                <template v-if="tabIndex==4">
                    <img class="title title4" :src="title" alt="">
                </template>
                <template v-if="tabIndex==5">
                    <img class="title title5" :src="title" alt="">
                    <img class="desc" :class="'desc'+tabIndex" :src="desc" alt="">
                </template>
                <template v-if="tabIndex==6">
                    <img class="title title6" :src="title" alt="">
                    <img class="desc" :class="'desc'+tabIndex" :src="desc" alt="">
                </template>
            </div>
            <div class="daohang">
                <div class="daohBox">
                    <div v-for="(item,index) in list" :key="index" :class="tabIndex==index+1?'active':''" @click="turnClick(item.url)">{{item.name}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import banner001 from '../assets/aboutUs/banner.png'
import banner002 from '../assets/aboutUs/dbanner.png'
import title1 from '../assets/aboutUs/title1.png'
import title2 from '../assets/aboutUs/title2.png'
import title3 from '../assets/aboutUs/title3.png'
import title4 from '../assets/aboutUs/title4.png'
import title5 from '../assets/aboutUs/title5.png'
import desc2 from '../assets/aboutUs/desc2.png'
import desc5 from '../assets/aboutUs/desc5.png'
export default {
    name: 'aboutBanner',
    props:['tabIndex'],
    data() {
        return {
            bannerBg:'',
            desc:'',
            title:'',
            list:[
                {
                    name:'企业文化',
                    url:'/aboutUs/CorporateCulture'
                },
                {
                    name:'公司简介',
                    url:'/aboutUs/Introduction'
                },
                {
                    name:'奖项与荣誉',
                    url:'/aboutUs/AwardsAndHonors'
                },
                {
                    name:'社会价值',
                    url:'/aboutUs/SocialValue'
                },
                {
                    name:'党工妇团',
                    url:'/aboutUs/workWomenCorps'
                },
                // {
                //     name:'分支机构',
                //     url:'/aboutUs/Branch'
                // }
            ]
        };
    },
    watch: {
        $route: {
            handler(value){
                let bannerBg = value.matched[value.matched.length - 1].meta.bannerBg
                switch(bannerBg){
                    case 'banner001': 
                        this.bannerBg = banner001
                        this.title = title1
                        break;
                    case 'banner002': 
                        this.bannerBg = banner001
                        this.title = title2
                        this.desc= desc2
                        break;
                    case 'banner003': 
                        this.bannerBg = banner001
                        this.title = title3
                        break;
                    case 'banner004': 
                        this.bannerBg = banner001
                        this.title = title4
                        break;
                    case 'banner005': 
                        this.bannerBg = banner002
                        this.title = title5
                        this.desc= desc5
                        break;
                    default:
                        this.bannerBg = banner001
                        this.title = title1
                }
                window.scrollTo(0,0)
            },
            immediate: true
        }
    },
    mounted(){
        
    },
    methods: {
        //二级导航跳转
        turnClick(url){
            this.$router.push({
                path:url
            })
        }
    }
}
</script>
<style scoped>
     .headerBannerBg {
        height: 260px;
        background-size: cover;
        background-position: center;
    }
    .title{
        display: block;
        margin: 0 auto;
    }
    .title1{
        width: 930px;
        padding-top: 108px;
    }
    .title2{
        width: 631px;
        padding-top: 75px;
    }
    .title3{
        width: 535px;
        padding-top: 108px;
    }
    .title4{
        width: 732px;
        padding-top: 108px;
    }
    .title5{
        width: 182px;
        padding-top: 75px;
    }
    .title6{
        width: 535px;
        padding-top: 75px;
    }
    .desc{
        display: block;
        margin: 0 auto;
        margin-top: 35px;
    }
    .desc1{
        width: 472px;
    }
    .desc2{
        width: 371px;
    }
    .desc3{
        width:1035px;
    }
    .desc4{
        width: 1105px;
    }
    .desc5{
        width: 765px;
    }
    .desc6{
        width: 541px;
    }
    .daohang{
        width: 100%;
        height: 50px;
        background-color: #fff;
        /* border-bottom: 3px solid #F9F9FA; */
        box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.04);
    }
    .daohBox{
        width: 600px;
        margin: 0 auto;
        line-height: 48px;
        display: flex;
        justify-content: space-around;
        color: #333;
        font-size: 16px;
        font-weight: 400;
    }
    .daohBox div{
        cursor: pointer;
        padding: 0 5px;
    }
    .active{
        color: #247CD6;
        border-bottom: 2px solid #247CD6;
        padding: 0 5px;
        font-weight: bold;
    }
</style>