<template>
    <div class="introductionBox">
       <businessBanner tabIndex="4"></businessBanner>
       <div class="mainShow">
            <p class="font20 fontw">管理咨询</p>
            <p class="mt36 jies">集团管理咨询业务开展于2001年，是集合了代建、项目管理总承包模式（PMC，决策－执行）、项目管理模式(决策－执行)、项目管理模式(一体化)以及咨询顾问等管理模式的综合性业务板块。</p>
       </div>
       <div class="mainShows">
            <div class="main clearfix">
                <div class="fl cont">
                    <p class="contT">业务资质</p>
                    <p>集团管理咨询业务现有资质包括房屋建筑工程监理甲级资质和市政公用工程监理乙级资质。</p>
                </div>
                <img class="fr manaImg1" src="@/assets/businessScope/mana1.png" alt="">
            </div>
       </div>
       <div class="mainShow clearfix">
            <img class="fl zhaobImg" src="../../assets/businessScope/mana2.png" alt="">
            <div class="fr contLeft">
                <p class="contT" style="margin-top:65px">服务范围</p>
                <p>服务范围涵盖了项目准备阶段、设计管理、招标采购管理、进度管理、质量管理、投资管理、施工管理、档案管理、竣工验收管理、项目后期管理等工程建设项目全生命周期。</p>
            </div>
        </div>
       <div class="mainShows">
            <div class="main clearfix">
                <div class="fl contd">
                    <p class="contT">典型案例</p>
                    <p>完成的典型案例有曹妃甸四大联检办公楼代建、承德奥林匹克体育中心代建、石家庄市档案馆项目管理、廊坊市中心医院项目管理、承德医学院项目管理、衡水滏阳文化公园项目管监一体、曹妃甸新区配套产业园区生活水厂工程监理、中共石家庄市藁城区委党校工程监理等。</p>
                </div>
                <img class="fr dianx" src="../../assets/businessScope/mana3.png" alt="">
            </div>
       </div>
       <div class="mainShow clearfix">
            <img class="fl yjalImg" src="../../assets/businessScope/mana4.png" alt="">
            <div class="fr contLeft mt30">
                <p class="contT">业内荣誉</p>
                <p>多年来，集团一直活跃于国家及省市管理咨询行业，是中国建设监理协会会员单位、河北省建筑业协会会员单位、河北省建筑市场发展研究会副会长单位，并多次获得业内荣誉奖项。</p>
            </div>
        </div>
    </div>
</template>

<script>
import businessBanner from '../../components/businessBanner'
	export default {
		name:'EarlierConsultation',
        components:{businessBanner},
        data() {
            return {
                
            }
        },
        mounted() {
           
        }
	}
</script>

<style scoped>
    .mainShows{
        width: 100%;
        background-color: #F7F9FD;
        margin-top: 42px;
    }
    .main{
        width: 1200px;
        margin: 0 auto;
        padding-top:47px;
        padding-bottom: 100px;
    }
    .jies{
        line-height: 30px;
        text-indent: 2em;
    }
    .cont{
        width: 525px;
        color: #666;
    }
    .contT{
        color: #247CD6;
        font-size: 28px;
        margin-top: 50px;
        margin-bottom: 20px;
    }
    .contT+p{
        line-height: 36px;
    }
    .techBox{
        display: flex;
        justify-content: space-between;
        margin-top: 44px;
        margin-right: 100px;
        width: 433px;
    }
    .manaImg1{
        width: 432px;
        margin-right: 100px;
        margin-top: 23px;
    }
    .conts{
        width: 520px;
        color: #666;
        margin-top: 10px;
    }
    .zhaobImg{
        width: 585px;
        margin-left: 42px;
        margin-top: 47px;
        margin-bottom: 45px;
    }
    .dianx{
        width: 655px;
        height: 189px;
        margin-top: 60px;
    }
    .contd{
        width: 520px;
        color: #666;
    }
    .contLeft{
        width:525px;
        color: #666;
    }
    .yjalImg{
        width: 515px;
        margin-top: 30px;
        margin-bottom: 70px;
    }
</style>