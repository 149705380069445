<template>
    <div class="corporateCultureBox">
        <aboutBannerVue tabIndex="4"></aboutBannerVue>
        <div class="socialBox">
            <p class="font20 fontw">知识成果</p>
            <div class="clearfix mt42">
                <img class="fl knowImg1" src="@/assets/aboutUs/social/tu1.png" alt="">
                <div class="fr knowCont1">从实践中来，到实践中去。瑞和安惠总结从业二十余年的项目管理精华与实践经验，投入各专业领域精英数十人，经过五年的梳理与提炼，出版发行了“工程咨询理论与实践研究系列丛书”，共计10本著作。</div>
            </div>
            <div class="clearfix">
                <div class="fl knowCont2">
                    <p>主编新型建筑方式与工程项目管理创新丛书《全过程工程咨询管理》与《建筑企业项目化管理理论与实践》。</p>
                    <p>参编“全国二级造价工程师职业资格考试培训教材（河北版）”《建设工程计量与计价实务》（安装工程）教材。</p>
                    <p>“C市奥体中心，投资管控为核心的全过程工程咨询简析”“某商业广场项目全过程造价咨询实践”案例分别入选中价协 《全过程工程咨询典型案例—以投资控制为核心》2019和2020年版）。</p>
                </div>
                <img class="fr knowImg2" src="@/assets/aboutUs/social/tu2.png" alt="">
            </div>
            <div class="clearfix">
                <img class="fl knowImg3" src="@/assets/aboutUs/social/tu3.png" alt="">
                <div class="fr knowCont3">引领技术创新，推动行业进步。瑞和安惠项目管理集团依托成熟的全过程工程咨询和BIM应用经验，依据现行国家标准，参与编制了多项国家及河北省工程建设标准。</div>
            </div>
            <p class="font20 fontw mt42">典型业绩</p>
            <div class="tabT">
                <button :class="leix==0?'active':'moren'"  @click="changeT(0,ids)">全部</button>
                <button :class="leix==index+1?'active':'moren'" v-for="(item,index) in list" :key="index" @click="changeT(index+1,item.id)">{{item.title}}</button>
            </div>
            <div class="socialShow">
                <div class="socialList" v-for="(item,index) in achineveList" :key="index+'d'" @click="detail(item.id)">
                    <img :src="item.picture" alt="">
                    <p class="overh2">{{item.title}}</p>
                </div>
            </div>
            <div class="" v-if="achineveList.length==0" style="text-align:center">
                <el-empty description="暂无数据"></el-empty>
            </div>
            <div class="moreBtn" @click="lookMore">
                <div>查看更多</div>
            </div>
        </div>
    </div>
</template>

<script>
import aboutBannerVue from '@/components/aboutBanner'
	export default {
		name:'CorporateCulture',
        components:{aboutBannerVue},
        data() {
            return {
                ids:'',
                tabId:'',
                leix:0,
                list:[],
                achineveList:[],
                pageindex:1,
                total:0
            }
        },
        mounted(){
            this.getCata()
        },
        methods:{
            // 切换分类
             changeT(index,ids){
                this.leix = index
                if(index!=0){
                    this.tabId = ids
                }else{
                    this.tabId = this.ids
                }
                this.currentPage = 1
                this.pageindex=1
                this.achineveList=[]
                this.getAchievment(ids)
            },
            // 查询分类
            getCata(){
                this.Request({
                    url: '/webindex/zjrh/typicalAchievementCata',
                    method: 'get',
                }).then((data) => {
                    var arr=[]
                    if(data.tdata && data.tdata.length > 0){
                        data.tdata.forEach(item => {
                            arr.push(item.id)
                        });
                        this.ids = arr.toString()
                        this.tabId = arr.toString()
                        this.list = data.tdata
                        this.getAchievment(this.ids)
                    }
                })
            },

            //查询社会价值列表
            getAchievment(ids){
                this.Request({
                    url: '/webindex/zjrh/typicalAchievement',
                    method: 'get',
                    data:{
                        id:ids,
                        pageindex:1,
                        pagesize:12
                    }
                }).then((data) => {
                    if(data.rows && data.rows.length > 0){
                        this.achineveList = data.rows
                    }
                })
            },
            //查看社会价值详情
            detail(id){
                window.location.assign('/aboutUs/SocialValueInfo?id='+id)
            },
            //查看更多分类
            lookMore(){
                window.location.assign('/aboutUs/SocialValueList')
            }
        }
	}
</script>
<style scoped>
    .socialBox{
        width: 1200px;
        margin: 0 auto;
        margin-top: 42px;
        padding-bottom: 42px;
    }
    .tabT{
        margin-top: 27px;
    }
    .active{
        padding: 10px 20px;
        color: #fff;
        background-color: #247CD6;
        border-radius: 5px;
        border: none;
        margin-right: 16px;
        cursor: pointer;
    }
    .moren{
        padding: 10px 20px;
        color: #333;
        background-color: #F4F4F4;
        border-radius: 5px;
        border: none;
        margin-right: 16px;
        cursor: pointer;
    }
    .tabT .moren:hover{
        color: #247CD6;   
    }
    .socialShow{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 28px;
    }
    .socialList{
        width: 388px;
        margin-right: 18px;
        margin-bottom: 32px;
        text-align: center;
        cursor: pointer;
    }
    .socialList img{
        width: 388px;
        height:240px;
        margin-bottom: 20px;
    }
    .socialList:nth-child(3n){
        margin-right: 0;
    }
    .socialList:hover p{
        color: #247CD6;
    }
    .knowImg1{
        width: 221px;
        height: 240px;
    }
    .knowCont1{
        width: 929px;
        margin-top: 80px;
        text-indent: 2em;
        line-height: 36px;
    }
    .knowImg2{
        width: 362px;
        height: 266px;
        margin-top: -20px;
    }
    .knowCont2{
        width: 836px;
        margin-top: 52px;
        text-indent: 2em;
        line-height: 36px;
    }
    .knowImg3{
        width: 765px;
        height: 254px;
    }
    .knowCont3{
        width: 416px;
        text-indent: 2em;
        line-height: 36px;
        margin-top: 80px;
    }
    .moreBtn div{
        color: #157FCC;
        width: 121px;
        height: 43px;
        line-height: 43px;
        text-align: center;
        border: 1px solid #247CD6;
        border-radius: 3px;
        cursor: pointer;
        font-size: 14px;
    }
</style>