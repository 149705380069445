const teamList=[
    {
        picture:require('../assets/person/胡勇.jpg'),
        title:'胡勇',
        tag:'正高级工程师',
        power:'注册咨询师（投资）、注册造价师、注册一级建造师、注册监理工程师、IPMP（B级）、工料测量师',
        area:'企业管理、战略管理、全过程咨询',
    },
    {
        picture:require('../assets/person/郭建淼.jpg'),
        title:'郭建淼',
        tag:'正高级工程师',
        power:'注册咨询师（投资）、注册造价师、注册一级建造师、注册监理工程师、IPMP（B级）',
        area:'全过程咨询、造价咨询、合约咨询、BIM咨询'
    },
    {
        picture:require('../assets/person/李淼.jpg'),
        title:'李淼',
        tag:'正高级经济师',
        power:'注册咨询师、IPMP（B级）',
        area:'项目前期咨询、宏观经济与政策、规划咨询、财务评价、PPP咨询'
    },
    {
        picture:require('../assets/person/车明海.jpg'),
        title:'车明海',
        tag:'正高级工程师',
        power:'注册咨询师、注册监理师、一级建造师、IPMP（B级）',
        area:'企业管理、市场营销、全过程咨询、招标采购咨询'
    },
    {
        picture:require('../assets/person/郭永林.jpg'),
        title:'郭永林',
        tag:'高级会计师',
        power:'注册咨询师、IPMP（C级）',
        area:'绩效管理、财务管理'
    },
    {
        picture:require('../assets/person/李永涛.jpg'),
        title:'李永涛',
        tag:'高级工程师',
        power:'注册咨询师、注册监理师、注册造价师、一级建造师、二级建造师、IPMP（C级）',
        area:'招标、特许经营项目咨询'
    },
    {
        picture:require('../assets/person/聂庆宇.jpg'),
        title:'聂庆宇',
        tag:'高级工程师',
        power:'注册咨询师、注册监理师、一级建造师、二级建造师、IPMP（C级）',
        area:'招标'
    },
    {
        picture:require('../assets/person/鲁健.jpg'),
        title:'鲁健',
        tag:'高级工程师',
        power:'注册咨询师、一级建造师、IPMP（C级）',
        area:'交通项目招标咨询'
    },
    {
        picture:require('../assets/person/杨建华.png'),
        title:'杨建华',
        tag:'高级工程师',
        power:'注册咨询师',
        area:'项目前期咨询、宏观经济与政策、稳评'
    },
    {
        picture:require('../assets/person/樊永刚.jpg'),
        title:'樊永刚',
        tag:'高级工程师',
        power:'注册咨询师',
        area:'项目前期咨询、财务评价、稳评'
    },
    {
        picture:require('../assets/person/殷文聪.jpg'),
        title:'殷文聪',
        tag:'高级工程师',
        power:'注册咨询师、IPMP（C级）',
        area:'项目前期咨询、宏观经济与政策、规划咨询、财务评价、PPP咨询'
    },
    {
        picture:require('../assets/person/甄利凯.jpg'),
        title:'甄利凯',
        tag:'高级工程师',
        power:'注册咨询师、IPMP（C级）',
        area:'项目前期咨询、宏观经济与政策、规划咨询、财务评价、PPP咨询'
    },
    {
        picture:require('../assets/person/张慧敬.jpg'),
        title:'张慧敬',
        tag:'高级工程师',
        power:'注册咨询师、二级建造师',
        area:'投资决策综合性咨询、政府和社会资本合作咨询、工程咨询数字化应用'
    },
    {
        picture:require('../assets/person/李小玲.jpg'),
        title:'李小玲',
        tag:'工程师',
        power:'注册咨询师、注册城乡规划师',
        area:'发展规划、产业规划编制cad、ps '
    },
    {
        picture:require('../assets/person/贾向敬.jpg'),
        title:'贾向敬',
        tag:'高级工程师',
        power:'注册咨询师、注册监理师、注册造价师、一级建造师',
        area:'造价咨询，土建、市政、信息化 '
    },
    {
        picture:require('../assets/person/张凤荣.jpg'),
        title:'张凤荣',
        tag:'正高级工程师',
        power:'注册咨询师、注册造价师、IPMP（C级）',
        area:'造价咨询、BIM咨询、给排水及暖通技术 '
    },
    {
        picture:require('../assets/person/赵戌峰.jpg'),
        title:'赵戌峰',
        tag:'高级工程师',
        power:'注册咨询师、注册监理师、注册造价师、IPMP（C级）',
        area:'造价咨询、电气 '
    },
    {
        picture:require('../assets/person/耿艳娜.jpg'),
        title:'耿艳娜',
        tag:'高级工程师',
        power:'注册监理师、注册造价师',
        area:'造价咨询、土建 '
    },
    {
        picture:require('../assets/person/宋志红.jpg'),
        title:'宋志红',
        tag:'高级工程师',
        power:'注册咨询师、注册监理师、注册造价师、一级建造师、IPMP（C级）',
        area:'全过程咨询、项目管理、BIM咨询、合约咨询 '
    },
    {
        picture:require('../assets/person/刘志伟.jpg'),
        title:'刘志伟',
        tag:'正高级工程师',
        power:'注册咨询师、注册监理师、注册造价师、一级建造师',
        area:'全过程咨询、项目管理 '
    },
    {
        picture:require('../assets/person/李东坡.png'),
        title:'李东坡',
        tag:'高级工程师',
        power:'注册咨询师、注册监理师、注册造价师、一级建造师',
        area:'全过程咨询、项目管理 '
    },
    {
        picture:require('../assets/person/彭祥俊.jpg'),
        title:'彭祥俊',
        tag:'高级工程师',
        power:'注册监理师',
        area:'全过程咨询、项目管理 '
    },
    {
        picture:require('../assets/person/郑俊.jpg'),
        title:'郑俊',
        tag:'高级工程师',
        power:'注册咨询师',
        area:'节能、稳评、工业类可研、外贷、项目申报 '
    },
    {
        picture:require('../assets/person/巴音王子.jpg'),
        title:'巴音王子',
        tag:'',
        power:'执业律师',
        area:'法律顾问、业务合同条款咨询、PPP项目合同的编写、谈判 '
    },
    {
        picture:require('../assets/person/张红楼.jpg'),
        title:'张红楼',
        tag:'高级工程师',
        power:'注册监理师、一级建造师',
        area:'全过程咨询、项目管理 '
    },
]

export default teamList