<template>
    <div class="introductionBox">
       <humanResources tabIndex="1"></humanResources>
       <div class="humanBox">
            <div class="jobBox" v-for="(item,index) in jobList" :key="index">
                <div class="clearfix">
                    <p class="fl"><span class="jobt">{{item.title}}</span></p>
                    <div class="fr clearfix jobRight" @click="changeState(index)">
                        <span class="fl mt2">{{item.addtime}} 发布</span>
                        <i class="fr mt4" :class="item.show==0?'el-icon-arrow-down':'el-icon-arrow-up'"></i>
                    </div>
                </div>
                <!-- <div class="jobLeft">
                    <p class="mt25 c66 font14">{{item.descirbes}}</p>
                </div> -->
                <div class="jobInfo" v-if="item.show==1">
                    <div v-html="item.content"></div>
                    <el-button class="onlineBtn" type="primary" @click="online">在线投简历</el-button>
                </div>
            </div>
       </div>
    </div>
</template>

<script>
    import humanResources from '../../components/humanResources'
	export default {
		name:'EarlierConsultation',
        components:{humanResources},
        data() {
            return {
                jobList:[] ,
                pageindex:1
            }
        },
        mounted() {
           this.getInfo()
        },
        methods:{
            changeState(index){
                var jobList = this.jobList
                jobList[index].show = !jobList[index].show
                for(var i=0;i<jobList.length;i++){
                    if(jobList[index].show==1){
                        if(index!=i){
                            jobList[i].show=0
                        }
                    }
                }
            },

            //获取人才招聘信息
            getInfo(){
                this.Request({
                    url: '/webindex/renli/recruitmentInfoList',
                    method: 'get',
                    data:{
                        pageindex:this.pageindex,
                        pagesize:10
                    }
                }).then((data) => {
                    if(data.rows && data.rows.length > 0){
                        var rows = data.rows
                        for(var i=0;i<rows.length;i++){
                            rows[i].show=1
                            if(rows[i].workExperience&&rows[i].workExperience.indexOf(' 性 别：&amp;nbsp;')!=-1){
                                rows[i].workExperience= rows[i].workExperience.replace(' 性 别：&amp;nbsp;','')
                            }
                            if(rows[i].education&&rows[i].education.indexOf('&amp;nbsp;')!=-1){
                                rows[i].education= rows[i].education.replace('&amp;nbsp;','')
                            }
                            if(rows[i].salary&&rows[i].salary.indexOf('&amp;nbsp;')!=-1){
                                rows[i].salary= rows[i].salary.replace('&amp;nbsp;','')
                            }
                        }
                        this.jobList = data.rows
                    }
                })
            },
            //在线写简历
            online(){
                window.location.assign('/humanResources/resume')
            }
        }
	}
</script>

<style scoped>
   .humanBox{
       background-color: #F7F8F9;
       padding-top: 42px;
       padding-bottom: 42px;
   }
   .jobBox{
        width: 1200px;
        margin: 0 auto;
        padding: 30px;
        background-color: #fff;
        margin-bottom: 16px;
   }
   .jobt{
        font-size: 18px;
        font-weight: bold;
        margin-right: 30px;
   }
   .money{
        color: #FE574A;
   }
   .jobLeft{
        width: 100%;
        line-height: 28px;
   }
   .jobRight{
        width: 143px;
        /* margin-top: 30px; */
        color: #666;
        font-size: 14px;
        cursor: pointer;
   }
   .jobRight:hover i{
    color: #247CD6;
   }
   .jobInfo{
        border-top: 1px solid #EBEBEB;
        padding-top: 40px;
        font-size: 14px;
        margin-top: 15px;
        padding-bottom: 20px;
   }
   .jobInfos{
        margin-top: 25px;
        color: #666;
        line-height: 30px;
   }
   .onlineBtn{
        margin-top: 41px;
   }
</style>