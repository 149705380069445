<template>
    <div class="corporateCultureBox">
        <aboutBannerVue tabIndex="3"></aboutBannerVue>
        <div class="honorBox">
            <p class="font20 fontw">资质资信</p>
            <div class="honorShow">
                <div class="honorList" v-for="(item,index) in picList" :key="index">
                    <div class="honDiv">
                        <img class="honorImg" :src="item.imgurl" alt="">
                    </div>
                    <p>{{item.title}}</p>
                </div>
            </div>
            <div class="honorShow" style="justify-content: space-between;">
                <div class="honorLists" v-for="(item,index) in picLists" :key="index">
                    <div :class="index<7?'honDiv':'honDivs'">
                        <img :class="index<7?'honorImg':'honorsImg'" :src="item.imgurl" alt="">
                    </div>
                    <p>{{item.title}}</p>
                </div>
            </div>
            <p class="font20 fontw">荣誉奖项</p>
            <div class="rongyBox">
                <div class="rongyLeft">
                    <p class="fenlei">国家</p>
                    <div class="rongyList clearfix" v-for="(item,index) in guojList" :key="index">
                        <img class="fl" src="@/assets/aboutUs/honour/biao.png" alt="">
                        <p class="fl">{{item.title}}</p>
                    </div>
                </div>
                <div class="rongyRight">
                    <p class="fenlei">先进</p>
                    <div class="rongyList clearfix" v-for="(item,index) in xianjList" :key="index">
                        <img class="fl" src="@/assets/aboutUs/honour/biao.png" alt="">
                        <p class="fl">{{item.title}}</p>
                    </div>
                    <p class="fenlei" style="margin-top:69px">成果</p>
                    <div class="rongyList clearfix" v-for="(item,index) in chengList" :key="index">
                        <img class="fl" src="@/assets/aboutUs/honour/biao.png" alt="">
                        <p class="fl">{{item.title}}</p>
                    </div>
                </div>
            </div>
            <div class="honorzhans">
                <img src="@/assets/aboutUs/honour/honour.png" alt="">
            </div>
        </div>
    </div>
</template>

<style scoped>
   
</style>
<script>
import aboutBannerVue from '@/components/aboutBanner'
	export default {
		name:'CorporateCulture',
        components:{aboutBannerVue},
        data() {
            return {
                picList:[
                    {
                        imgurl:require('../../assets/aboutUs/honour/icon1.png'),
                        title:'工程咨询单位甲级综合资信证书'
                    },
                    {
                        imgurl:require('../../assets/aboutUs/honour/icon2.png'),
                        title:'工程咨询单位甲级专项资信证书'
                    },
                    {
                        imgurl:require('../../assets/aboutUs/honour/icon3.png'),
                        title:'工程咨询单位甲级专业资信证书'
                    }
                    
                ],
                picLists:[
                    {
                        imgurl:require('../../assets/aboutUs/honour/icon4.png'),
                        title:'工程监理资质'
                    },
                    {
                        imgurl:require('../../assets/aboutUs/honour/icon5.png'),
                        title:'质量体系认证'
                    },
                    {
                        imgurl:require('../../assets/aboutUs/honour/icon6.png'),
                        title:'环境体系认证'
                    },
                    {
                        imgurl:require('../../assets/aboutUs/honour/icon7.png'),
                        title:'职业健康安全管理体系认证'
                    },
                    {
                        imgurl:require('../../assets/aboutUs/honour/icon8.png'),
                        title:'工程咨询单位乙级资信证书'
                    }
                ],
                guojList:[
                    {
                        title:'中国招标投标协会企业信用等级AAA'
                    },
                    {
                        title:'中国建设工程造价管理协会工程造价企业信用等级AAA'
                    },
                    {
                        title:'中国招投标协会行业先锋单位'
                    },
                    {
                        title:'河北省建筑市场发展研究会工程监理企业信用等级AAA'
                    },
                    {
                        title:'河北省建筑市场发展研究会工程造价企业信用等级AAA'
                    },
                    {
                        title:'河北省建设工程招标投标协会诚实守信5A级招标代理机构'
                    },
                    {
                        title:'河北省建设工程造价管理协会计价信用承诺AAAAA'
                    },
                    {
                        title:'河北省信用协会信用评价等级AAA'
                    },
                    {
                        title:'河北省信用协会守合同重信用、诚信共建企业'
                    },
                    {
                        title:'河北省公共资源交易行业协会平台建设贡献奖、行业诚信建设奖、公共资源交易优质服务机构'
                    },
                    {
                        title:'河北省建筑市场发展研究会河北省BIM应用先锋单位'
                    },
                    {
                        title:'河北省公共资源交易行业协会抗击新冠疫情工作先进集体'
                    }
                ],
                xianjList:[
                    {
                        title:'河北省建设工程招标投标协会招标投标工作先进单位'
                    },
                    {
                        title:'石家庄市建筑协会招标代理先进企业'
                    },
                    {
                        title:'河北省建设工程造价管理协会先进单位'
                    },
                    {
                        title:'河北省建筑市场发展研究会先进单位'
                    },
                    {
                        title:'石家庄市工程勘察设计咨询业协会造价咨询行业先进单位'
                    }
                ],
                chengList:[
                    {
                        title:'中国工程咨询协会全国优秀工程咨询成果三等奖'
                    },
                    {
                        title:'湖北省工程咨询协会工程咨询优秀成果一等奖'
                    },
                    {
                        title:'河北省建筑市场发展研究会造价优秀成果一、二、三等奖'
                    },
                    {
                        title:'河北省建设工程造价管理协会优秀工程造价成果奖'
                    },
                    {
                        title:'石家庄市工程勘察设计咨询业协会优秀造价成果一、二、三等奖'
                    },
                    {
                        title:'苏州市工程咨询协会工程咨询优秀成果二、三等奖'
                    }
                ]
            }
        },
	}
</script>
<style scoped>
    .honorBox{
        width: 1200px;
        margin: 0 auto;
        margin-top: 42px;
        padding-bottom: 42px;
    }
    .honorShow{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 36px;
    }
    .honorList{
        width: 362px;
        margin-bottom: 30px;
        margin-right: 52px;
        text-align: center;
    }
    .honorList:nth-child(3n){
        margin-right: 0;
    }
    .honorList div{
        width: 362px;
        height: 250px;
        box-sizing: border-box;
        line-height: 250px;
        padding-bottom: 5px;
    }
    .honorList .honDivs{
        border: 1px solid #E6E9F0;
    }
    .honorImg{
        width: 100%;
        vertical-align:middle;
    }
    .honorsImg{
        width: 200px;
        margin: 0 auto;
        vertical-align:middle;
    }
    .honorList p{
        margin-top: 19px;
    }

    .honorLists{
        width: 220px;
        margin-bottom: 30px;
        /* margin-right: 20px; */
        text-align: center;
    }
    .honorLists:nth-child(5n){
        margin-right: 0;
    }
    .honorLists div{
        /* width: 220px; */
        height: 307px;
        box-sizing: border-box;
        line-height: 307px;
        padding-bottom: 5px;
    }
    .honorLists .honDivs{
        border: 1px solid #E6E9F0;
    }
    .honorLists p{
        margin-top: 19px;
    }

    .rongyBox{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 35px;
    }
    .rongyLeft,.rongyRight{
        width: 50%;
    }
    .fenlei{
        color: #247CD6;
        font-size: 16px;
        margin-bottom: 17px;
        font-weight: bold;
    }
    .rongyList{
        width: 100%;
        margin-bottom: 27px;
    }
    .rongyList img{
        width: 18px;
        height: 20px;
        margin-right: 11px;
    }
    .rongyList p{
        width: 534px;
        line-height: 30px;
        margin-top: -6px;
    }
    .honorzhans{
        width:1200px;
        height: 600px;
        background-color: #F5F8FF;
        margin-top: 34px;
        padding-top: 76px;
    }
    .honorzhans img{
        display: block;
        width: 1124px;
        margin: 0 auto;
    }
</style>