<template>
    <div class="corporateCultureBox">
        <aboutBannerVue tabIndex="4"></aboutBannerVue>
        <div class="socialBox">
            <p class="font20 fontw">典型业绩</p>
            <div class="tabT">
                <button :class="leix==0?'active':'moren'"  @click="changeT(0,ids)">全部</button>
                <button :class="leix==index+1?'active':'moren'" v-for="(item,index) in list" :key="index" @click="changeT(index+1,item.id)">{{item.title}}</button>
            </div>
            <div class="socialShow">
                <div class="socialList" v-for="(item,index) in achineveList" :key="index+'d'" @click="detail(item.id)">
                    <img :src="item.picture" alt="">
                    <p class="overh2">{{item.title}}</p>
                </div>
            </div>
            <div class="" v-if="achineveList.length==0">
                <el-empty description="暂无数据"></el-empty>
            </div>
            <el-pagination class="pageBox" v-if="achineveList.length!=0" background @current-change="handleCurrentChange" :current-page.sync="currentPage" layout="prev, pager, next, jumper" :page-size="12" :total="total"></el-pagination>
        </div>
    </div>
</template>

<script>
import aboutBannerVue from '../../components/aboutBanner'
	export default {
		name:'CorporateCulture',
        components:{aboutBannerVue},
        data() {
            return {
                ids:'',
                tabId:'',
                leix:0,
                list:[],
                achineveList:[],
                pageindex:1,
                currentPage:1,
                total:0
            }
        },
        mounted(){
            this.getCata()
        },
        methods:{
             // 切换分类
             changeT(index,ids){
                this.leix = index
                if(index!=0){
                    this.tabId = ids
                }else{
                    this.tabId = this.ids
                }
                this.currentPage = 1
                this.pageindex=1
                this.achineveList=[]
                this.getAchievment(ids)
            },
            // 查询分类
            getCata(){
                this.Request({
                    url: '/webindex/zjrh/typicalAchievementCata',
                    method: 'get',
                }).then((data) => {
                    var arr=[]
                    if(data.tdata && data.tdata.length > 0){
                        data.tdata.forEach(item => {
                            arr.push(item.id)
                        });
                        this.ids = arr.toString()
                        this.tabId = arr.toString()
                        this.list = data.tdata
                        this.getAchievment(this.ids)
                    }
                })
            },

            //查询社会价值列表
            getAchievment(ids){
                this.Request({
                    url: '/webindex/zjrh/typicalAchievement',
                    method: 'get',
                    data:{
                        id:ids,
                        pageindex:this.pageindex,
                        pagesize:12
                    }
                }).then((data) => {
                    if(data.rows && data.rows.length > 0){
                        this.total = data.total
                        this.achineveList = data.rows
                    }
                })
            },
            handleCurrentChange(val){
                this.currentPage = val
                this.pageindex=val
                this.getAchievment(this.tabId)
            },
            //查看社会价值详情
            detail(id){
                window.location.assign('/aboutUs/SocialValueInfo?id='+id)
            },
        }
	}
</script>
<style scoped>
    .socialBox{
        width: 1200px;
        margin: 0 auto;
        margin-top: 42px;
        padding-bottom: 42px;
    }
    .tabT{
        margin-top: 27px;
    }
    .active{
        padding: 10px 20px;
        color: #fff;
        background-color: #247CD6;
        border-radius: 5px;
        border: none;
        margin-right: 16px;
        cursor: pointer;
    }
    .moren{
        padding: 10px 20px;
        color: #333;
        background-color: #F4F4F4;
        border-radius: 5px;
        border: none;
        margin-right: 16px;
        cursor: pointer;
    }
    .tabT .moren:hover{
        color: #247CD6;   
    }
    .socialShow{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 28px;
    }
    .socialList{
        width: 388px;
        margin-right: 18px;
        margin-bottom: 32px;
        text-align: center;
        cursor: pointer;
    }
    .socialList img{
        width: 388px;
        height:240px;
        margin-bottom: 20px;
    }
    .socialList:nth-child(3n){
        margin-right: 0;
    }
    .socialList:hover p{
        color: #247CD6;
    }
    .knowImg1{
        width: 221px;
        height: 240px;
    }
    .knowCont1{
        width: 929px;
        margin-top: 80px;
        text-indent: 2em;
        line-height: 36px;
    }
    .knowImg2{
        width: 362px;
        height: 266px;
        margin-top: -20px;
    }
    .knowCont2{
        width: 836px;
        margin-top: 52px;
        text-indent: 2em;
        line-height: 36px;
    }
    .knowImg3{
        width: 749px;
        height: 256px;
    }
    .knowCont3{
        width: 432px;
        text-indent: 2em;
        line-height: 36px;
        margin-top: 80px;
    }
    .pageBox{
        text-align: center;
        margin-top: 35px;
        margin-bottom: 42px;
    }
</style>