<template>
    <div class="indexBox">
        <div class="sessionOneBox">
            <!-- <div class="" @click="jumpTo('/businessScope/earlierConsultation')">
                <img src="@/assets/index/sessionOneBg.png" alt="">
            </div> -->
            <el-carousel autoplay :height="height">
                <el-carousel-item>
                    <img src="@/assets/index/sessionOneBg.png" alt="">
                </el-carousel-item>
                <el-carousel-item>
                    <img src="@/assets/index/banner2.png" alt="">
                </el-carousel-item>
                <el-carousel-item>
                    <img src="@/assets/index/banner3.png" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="sessionBox">
            <div class="session swiperSession">
                <div class="centerTitle">业务范围</div>
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="item" @click="jumpTo('/businessScope/earlierConsultation')">
                                <div class="introductionPic introductionPic01"></div>
                                <div class="title">前期咨询</div>
                                <div class="introduction">
                                    <p>集团前期咨询业务开展于2004年，经过多年的 潜心发展，如今已成为资信水平领先，技术力量雄厚，业绩能力突出的综合型咨询业务。拥</p>
                                    <p>有综合甲级；建筑，电子、信息工程...</p>
                                </div>
                            </div>
                            <div class="item" @click="jumpTo('/businessScope/costConsultation')">
                                <div class="introductionPic introductionPic03"></div>
                                <div class="title">造价咨询</div>
                                <div class="introduction">
                                    <p>集团造价咨询业务开展于2003年，并于2005年 在业内率先取得工程造价咨询甲级资质。服务 过程涵盖了项目决策阶段、招投标阶段、施工</p>
                                    <p>阶段、竣工阶段等项目全生命周期...</p>
                                </div>
                            </div>
                            <div class="item" @click="jumpTo('/businessScope/biddingAgent')">
                                <div class="introductionPic introductionPic02"></div>
                                <div class="title">招标代理</div>
                                <div class="introduction">
                                    <p>招标代理业务是集团最早开展的业务板块，早 在2000年集团成立之初就作为主营业务发展至今。集团公司也是省内最早一批取得工程建设</p>
                                    <p>甲级、政府采购甲级、中央投资甲级...</p>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="item" @click="jumpTo('/businessScope/projectManagement')">
                                <div class="introductionPic introductionPic05"></div>
                                <div class="title">管理咨询</div>
                                <div class="introduction">
                                    <p>集团管理咨询业务开展于2001年，是集合了代 建、项目管理总承包模式（PMC，决策－执行）、项目管理模式(决策－执行)、项目管理</p>
                                    <p>模式(一体化)以及咨询顾问等管理模式的...</p>
                                </div>
                            </div>
                            <div class="item" @click="jumpTo('/businessScope/consultationBusiness')">
                                <div class="introductionPic introductionPic08"></div>
                                <div class="title">全咨业务</div>
                                <div class="introduction">
                                    <p>多年来，集团公司持续改进全咨业务流程，不断完善全咨业务体系，完成了很多影响广泛的全过程工程咨询项目，其中典型的案例有雄县</p>
                                    <p>城区雄州路雨污分流改造工程全过程咨询...</p>
                                </div>
                            </div>
                            <div class="item" @click="jumpTo('/businessScope/BIMApplication')">
                                <div class="introductionPic introductionPic06"></div>
                                <div class="title">数字化应用</div>
                                <div class="introduction">
                                    <p>集团提出了“数字化赋能全过程工程咨询”的战略目标，并在此规划下积极开展数字化应用，应用自主研发的OA、CRM、PMO、惠</p>
                                    <p>招标、惠造价、惠管理、云咨空间等平台</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sessionBox sessionTwoBox">
            <div class="session">
                <div class="centerTitle">媒体中心</div>
                <div class="mediaBox">
                    <div class="mediaLeft">
                        <div class="clearfix changeT">
                            <div class="mediaTab fl">
                                <div :class="type==1?'active fontw':''" @click="changType(1)">企业动态</div>
                                <div :class="type==2?'active fontw':''" @click="changType(2)">行业资讯</div>
                            </div>
                            <div class="more fr" @click="jumpTo('/mediaCenter/companyNews',type)">更多&gt;</div>
                        </div>
                        <div class="newsBox" v-if="type==1">
                            <div v-for="(item,index) in companyNewsList" :key="index">
                                <div class="clearfix" v-if="index==0" style="margin-bottom:30px;margin-top:12px">
                                    <img class="fl mediaImg" v-if="item.picture" :src="item.picture" alt="">
                                    <img class="fl mediaImg" v-else src="@/assets/index/media.png" alt="">
                                    <div class="fr mediaCont">
                                        <p class="overh2 c33 curp biaot" @click="jumpTo(`/mediaCenter/CompanyNewsDetails?id=${item.id}`)">{{item.title}}</p>
                                        <p class="textr">{{item.addtime}}</p>
                                    </div>
                                </div>
                                <div class="clearfix" v-else>
                                    <p class="fl overh ptitle biaot" @click="jumpTo(`/mediaCenter/CompanyNewsDetails?id=${item.id}`)">{{item.title}}</p>
                                    <span class="fr">{{item.addtime}}</span>
                                </div>
                            </div>
                            <el-empty v-if="companyNewsList.length==0" description="暂无数据"></el-empty>
                        </div>
                        <div class="newsBox" v-if="type==2">
                            <div v-for="(item,index) in industryNewsList" :key="index+'ss'">
                                <div class="clearfix" v-if="index==0" style="margin-bottom:30px;margin-top:12px">
                                    <img class="fl mediaImg" v-if="item.picture" :src="item.picture" alt="">
                                    <img class="fl mediaImg" v-else src="@/assets/index/media.png" alt="">
                                    <div class="fr mediaCont">
                                        <p class="overh2 c33 curp biaot" @click="jumpTo(`/mediaCenter/CompanyNewsDetails?id=${item.id}`)">{{item.title}}</p>
                                        <p class="textr">{{item.addtime}}</p>
                                    </div>
                                </div>
                                <div class="clearfix" v-else>
                                    <p class="fl overh ptitle biaot" @click="jumpTo(`/mediaCenter/industryNewsDetails?id=${item.id}`)">{{item.title}}</p>
                                <span class="fr">{{item.addtime}}</span>
                                </div>
                            </div>
                            <el-empty v-if="industryNewsList.length==0" description="暂无数据"></el-empty>
                        </div>
                    </div>
                    <div class="mediaRight">
                        <div class="clearfix changeT">
                            <div class="mediaTabs fl">
                                <div :class="leix==1?'active fontw':''" @click="changLeix(1)">公告</div>
                                <div :class="leix==2?'active fontw':''" @click="changLeix(2)">公示</div>
                                <div :class="leix==3?'active fontw':''" @click="changLeix(3)">澄清与修改</div>
                                <div :class="leix==4?'active fontw':''" @click="changLeix(4)" style="margin-right:0">公众参与公示</div>
                            </div>
                            <div class="more fr" v-if="leix==1" @click="jumpTo('/mediaCenter/Notice')">更多&gt;</div>
                            <div class="more fr" v-if="leix==2" @click="jumpTo('/mediaCenter/Public')">更多&gt;</div>
                            <div class="more fr" v-if="leix==3" @click="jumpTo('/mediaCenter/Clarify')">更多&gt;</div>
                            <div class="more fr" v-if="leix==4" @click="jumpTo('/mediaCenter/NoticePub')">更多&gt;</div>
                        </div>
                        <div class="newsBox" v-if="leix==1">
                            <div class="clearfix" v-for="(item,index) in noticeList" :key="index">
                                <p class="fl overh biaot" @click="jumpTo(`/mediaCenter/NoticeDetails?id=${item.id}`)">{{item.title}}</p>
                                <span class="fr">{{item.addtime}}</span>
                            </div>
                            <el-empty v-if="noticeList.length==0" description="暂无数据"></el-empty>
                        </div>
                        <div class="newsBox" v-if="leix==2">
                            <div class="clearfix" v-for="(item,index) in noticeList" :key="index">
                                <p class="fl overh biaot" @click="jumpTo(`/mediaCenter/PublicDetails?id=${item.id}`)">{{item.title}}</p>
                                <span class="fr">{{item.addtime}}</span>
                            </div>
                            <el-empty v-if="noticeList.length==0" description="暂无数据"></el-empty>
                        </div>
                        <div class="newsBox" v-if="leix==3">
                            <div class="clearfix" v-for="(item,index) in noticeList" :key="index">
                                <p class="fl overh biaot" @click="jumpTo(`/mediaCenter/ClarifyDetails?id=${item.id}`)">{{item.title}}</p>
                                <span class="fr">{{item.addtime}}</span>
                            </div>
                            <el-empty v-if="noticeList.length==0" description="暂无数据"></el-empty>
                        </div>
                        <div class="newsBox" v-if="leix==4">
                            <div class="clearfix" v-for="(item,index) in noticeList" :key="index">
                                <p class="fl overh biaot" @click="jumpTo(`/mediaCenter/NoticePubDetails?id=${item.id}`)">{{item.title}}</p>
                                <span class="fr">{{item.addtime}}</span>
                            </div>
                            <el-empty v-if="noticeList.length==0" description="暂无数据"></el-empty>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sessionBox sessionThreeBox">
            <div class="mapBox">
                <div class="centerTitle">瑞和在中国</div>
               <div class="mapshowBox">
                <img class="mapImg" src="../../assets/index/sessionMap1.png" alt="">
                <div class="pointD" v-for="(item,index) in list" :key="index" :class="'pointD'+(index+1)" :style="{'top':item.top,'left':item.left}">
                    <div class="words">{{item.name}}</div>
                    <div class="point"></div>
                </div>
               </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name:'Index',
        data() {
            return {
                height:'570px',
                type:1,
                leix:1,
                companyNewsList: [], //企业冬天
                industryNewsList:[], //行业资讯
                webcode:'100136102',
                noticeList:[],
                list:[
                    {
                        index:0,
                        name:'通辽',
                        top:'221px',
                        left:'816px'
                    },
                    {
                        index:1,
                        name:'辽宁',
                        top:'259px',
                        left:'899px'
                    },
                    {
                        index:2,
                        name:'葫芦岛',
                        top:'299px',
                        left:'858px'
                    },
                    {
                        index:3,
                        name:'天津',
                        top:'349px',
                        left:'813px'
                    },
                    {
                        index:4,
                        name:'河北',
                        top:'366px',
                        left:'765px'
                    },
                    {
                        index:5,
                        name:'太原',
                        top:'405px',
                        left:'699px'
                    },
                    {
                        name:'滨州',
                        top:'405px',
                        left:'819px'
                    },
                    {
                        name:'青岛',
                        top:'429px',
                        left:'862px'
                    },
                    {
                        name:'焦作',
                        top:'441px',
                        left:'751px'
                    },
                    {
                        name:'河南',
                        top:'489px',
                        left:'708px'
                    },
                    {
                        name:'开封',
                        top:'471px',
                        left:'785px'
                    },
                    {
                        name:'商丘',
                        top:'502px',
                        left:'783px'
                    },
                    {
                        name:'江苏',
                        top:'476px',
                        left:'879px'
                    },
                    {
                        name:'苏州',
                        top:'515px',
                        left:'896px'
                    },
                    {
                        name:'上海',
                        top:'531px',
                        left:'925px'
                    },
                    {
                        name:'成都',
                        top:'567px',
                        left:'506px'
                    },
                    {
                        name:'重庆',
                        top:'582px',
                        left:'645px'
                    },
                    {
                        name:'武汉',
                        top:'557px',
                        left:'725px'
                    },
                    {
                        name:'安徽',
                        top:'532px',
                        left:'831px'
                    },
                    {
                        name:'泸州',
                        top:'625px',
                        left:'583px'
                    },
                    {
                        name:'长沙',
                        top:'638px',
                        left:'708px'
                    },
                    {
                        name:'南昌',
                        top:'629px',
                        left:'795px'
                    },
                    {
                        name:'大理',
                        top:'717px',
                        left:'464px'
                    },
                    {
                        name:'海口',
                        top:'828px',
                        left:'712px'
                    },
                    {
                        name:'海南',
                        top:'859px',
                        left:'695px'
                    },
                ]
            }
        },
        mounted() {
            var height = window.innerHeight
            this.height = height*570/931+'px'
            this.getCompanyNews()
            this.getNotice()
        },
        methods: {
            jumpTo(path,type){
                // this.$router.push({
                //     path
                // })
                if(type==1){
                    window.location.assign('/mediaCenter/companyNews')
                }else if(type==2){
                    window.location.assign('/mediaCenter/IndustryNews')
                }else{
                    window.location.assign(path)
                }
            },
            // 提取HTML中的纯文本
            getHtmlText(html){
                let div = document.createElement('div')
                div.innerHTML = html
                div.style.display = 'none'
                document.body.appendChild(div)
                let text = div.innerText
                return text
            },

            //切换企业和行业资讯
            changType(type){
                this.type=type
                if(type==1){
                    this.getCompanyNews()
                }
                if(type==2){
                    this.getIndustryNews()
                }
            },
            //切换公告 公示 澄清与修改
            changLeix(type){
                this.leix= type
                var codeList=['100136102','100136103','100136104','100145']
                this.webcode= codeList[type-1]
                this.noticeList=[]
                this.getNotice()
            },

            //获取企业动态列表
            getCompanyNews(){
                this.Request({
                    url: '/webindex/news/companyNewsList',
                    method: 'get',
                    data:{
                        pagesize:8
                    }
                }).then((data) => {
                    if(data.rows && data.rows.length > 0){
                        this.companyNewsList = data.rows
                    }
                })
            },
            //获取行业资讯列表
            getIndustryNews(){
                this.Request({
                    url: '/webindex/news/industryNewsList',
                    method: 'get',
                    data:{
                        pagesize:8
                    }
                }).then((data) => {
                    if(data.rows && data.rows.length > 0){
                        this.industryNewsList = data.rows
                    }
                })
            },

            //获取公告/公示接口
            getNotice(){
                this.Request({
                    url: '/webindex/news/noticeList',
                    method: 'get',
                    data:{
                        pagesize:11,
                        webcode:this.webcode
                    }
                }).then((data) => {
                    if(data.rows && data.rows.length > 0){
                        this.noticeList = data.rows
                    }
                })
            },


        },
	}
</script>
<style scoped lang="less">
    .indexBox {
        background: #F8F8F8;
        .sessionOneBox{
            width: 100%;
            cursor: pointer;
        }
        .sessionOneBox img{
            width: 100%;
            height: 100%;
        }
        .sessionOneBox /deep/.el-carousel__indicators--horizontal{
            bottom: 10px;
        }
        .sessionTwoBox{
            height: 767px;
            background-image: url('../../assets/index/mediaBg.png');
            background-size: cover;
            background-position: center;
            margin-top: 60px;
        }
        .sessionThreeBox {
            // height: 881px;
            background-color: #fff;
            padding-bottom: 75px;
        }
        .mapImg{
            width: 1082px;
            height: 871px;
        }
        .centerTitle {
            text-align: center;
            margin-top: 68px;
            font-size: 36px;
            color: #333333;
        }
        .item:hover .title{
            color: #247CD6;
        }
        .sessionBox {
            display: flex;
            justify-content: center;
            .session {
                width: 1200px;
                .title {
                    font-size: 32px;
                    margin-top: 60px;
                }
                .introduce {
                    font-size: 18px;
                    margin-top: 20px;
                }
                .learnMore {
                    width: 90px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 14px;
                    margin-top: 279px;
                    border: 1px solid white;
                    border-radius: 20px;
                    text-align: center;
                    cursor: pointer;
                }
                .swiper-container {
                    margin-top: 68px;
                    background: #F8F8F8;
                    .swiper-slide {
                        display: flex;
                        // height: 410px;
                        .item:last-child {
                            margin-right: 0;
                        }
                        .item {
                            user-select: none;
                            cursor: pointer;
                            color: #333333;
                            width: 380px;
                            height:436px;
                            background: white;
                            box-shadow: 0px 0px 7px 1px rgba(38,115,231,0.15);
                            margin-right: 30px;
                            margin-bottom: 30px;
                            border-radius: 10px;
                            .introductionPic {
                                width: 380px;
                                height: 206px;
                                background-position: center;
                                background-size: cover;
                                border-top-left-radius: 10px;
                                border-top-right-radius: 10px;
                            }
                            .introductionPic01 {
                                background-image: url('../../assets/index/businessIntroduction001.png');
                            }
                            .introductionPic02 {
                                background-image: url('../../assets/index/businessIntroduction002.png');
                            }
                            .introductionPic03 {
                                background-image: url('../../assets/index/businessIntroduction003.png');
                            }
                            .introductionPic04 {
                                background-image: url('../../assets/index/businessIntroduction004.png');
                            }
                            .introductionPic05 {
                                background-image: url('../../assets/index/businessIntroduction005.png');
                            }
                            .introductionPic06 {
                                background-image: url('../../assets/index/businessIntroduction006.png');
                            }
                            .introductionPic07 {
                                background-image: url('../../assets/index/businessIntroduction007.png');
                            }
                            .introductionPic08 {
                                background-image: url('../../assets/index/businessIntroduction008.png');
                            }
                            .introductionPic09 {
                                background-image: url('../../assets/index/businessIntroduction009.png');
                            }
                            .title {
                                margin-top: 30px;   
                                font-size: 21px;
                                font-weight: bold;
                                padding: 0 24px;
                            }
                            .introduction {
                                margin-top: 20px;
                                font-size: 16px;
                                padding: 0 24px;
                                line-height: 30px;
                            }
                            .introduction p:first-child{
                                text-align: justify;
                                text-align-last: justify;
                            }
                        }
                    }
                }
                .newsList {
                    padding: 57px 0 100px;
                    .newsItemBox {
                        cursor: pointer;
                        margin-bottom: 62px;
                        display: flex;
                        .newsPic {
                            width: 170px;
                            height: 124px;
                            background-position: center;
                            background-size: cover;
                            background-image: url('../../assets/index/sessionThree.png');
                        }
                        .newsContent {
                            width: calc(100% - 170px);
                            padding-left: 25px;
                            .newsTitle {
                                font-size: 18px;
                                font-weight: bold;
                            }
                            .newsIntroduce{
                                font-size: 16px;
                                line-height: 28px;
                                color: #333333;
                                text-overflow: -o-ellipsis-lastline;
                                overflow: hidden;				//溢出内容隐藏
                                text-overflow: ellipsis;		//文本溢出部分用省略号表示
                                display: -webkit-box;			//特别显示模式
                                -webkit-line-clamp: 2;			//行数
                                line-clamp: 2;					
                                -webkit-box-orient: vertical;	//盒子中内容竖直排列
                                margin-top: 18px;
                            }
                            .newsDate  {
                                margin-top: 22px;
                                font-size: 16px;
                                color: #999999;
                                text-align: right;
                            }
                        }
                    }
                    .newsItemBox:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }
        .sessionFourBox {
            .btn {
                width: 140px;
                height: 50px;
                line-height: 50px;
                background: #FFFFFF;
                border: 1px solid #C4C4C4;
                text-align: center;
                margin: 0px auto;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                cursor: pointer;
            }
        }
    }
    .mediaImg{
        width: 184px;
        height: 111px;
    }
    .mediaCont{
        width: 416px;
        margin-top: 20px;
        line-height: 30px;
    }
    .mediaCont p:first-child{
        font-size: 16px;
        font-weight: 400;
    }
    .mediaBox{
       display: flex;
       justify-content: space-between;
       margin-top: 70px;
    }
    .mediaLeft{
        width: 700px;
        height: 530px;
        background-color: #fff;
        border-radius: 10px;
    }
    .mediaRight{
        width: 484px;
        height: 530px;
        background-color: #fff;
        border-radius: 10px;
    }
    .changeT{
        border-bottom: 1px solid #F1F1F1;
    }
    .mediaTab,.mediaTabs{
        display: flex;
        justify-content: flex-start;
        height: 65px;
        line-height: 65px;
        padding: 0 36px;
    }
    .mediaTab div{
        margin-right: 20px;
        padding: 0 8px;
        cursor: pointer;
    }
    .mediaTabs div{
        padding: 0 8px;
        cursor: pointer;
    }
    .mediaTab div:hover , .mediaTabs div:hover{
        color: #247CD6;
    }
    .active{
        color: #0072C6;
        border-bottom: 2px solid #0072C6;
    }
    .newsBox{
        padding: 15px 36px;
        line-height: 38px;
        color: #666;
        font-size: 14px;
    }
    .mediaLeft .newsBox .ptitle{
        width: 510px;
        cursor: pointer;
        font-size: 14px;
    }
    .mediaRight .newsBox p{
        width: 317px;
        cursor: pointer;
    }
    .more{
        text-align: center;
        color: #999;
        cursor: pointer;
        font-size: 14px;
        line-height: 65px;
        margin-right: 36px;
    }
    .more:hover{
        color: #247CD6;
    }
    .biaot:hover{
        color: #247CD6;
    }
    .mapshowBox{
        position: relative;
        margin-top:40px;
    }
    .pointD{
        position: absolute;
        color: #0072C6;
        font-size: 12px;
    }
    .words{
        position: absolute;
        width: 50px;
        left: 12px;
        top: -5px;
    }
    .point,.point::before,.point::after{position: absolute;width: 8px; height: 8px; border-radius: 50%;content: ''; }
    // .point::before{animation: scale 5s infinite; }
    // .point::after{animation: scale2 5s infinite; }
    .pointD5 .point{
        width: 20px;
        height: 19px;
        background: url('../../assets/index/star.png');
        background-size: 100% 100%;
    }
    .pointD5 .words{
        top: 15px;
        left: 0;
    }


    .pointD1 .point::before{animation: scale 5s infinite; }
    .pointD1 .point::after{animation: scale2 5s infinite; }

    .pointD2 .point::before{animation: scale 5s infinite ease-in-out 0.5s forwards; }
    .pointD2 .point::after{animation: scale2 5s infinite ease-in-out 0.5s forwards; }

    .pointD3 .point::before{animation: scale 5s infinite ease-in-out 0.8s forwards; }
    .pointD3 .point::after{animation: scale2 5s infinite ease-in-out 0.8s forwards; }

    .pointD4 .point::before{animation: scale 5s infinite ease-in-out 1.2s forwards; }
    .pointD4 .point::after{animation: scale2 5s infinite ease-in-out 1.2s forwards; }

    .pointD5 .point::before{animation: scale 5s infinite ease-in-out 1.8s forwards; }
    .pointD5 .point::after{animation: scale2 5s infinite ease-in-out 1.8s forwards; }

    .pointD6 .point::before{animation: scale 5s infinite ease-in-out 2.4s forwards; }
    .pointD6 .point::after{animation: scale2 5s infinite ease-in-out 2.4s forwards; }

    .pointD7 .point::before{animation: scale 5s infinite ease-in-out 0.5s forwards; }
    .pointD7 .point::after{animation: scale2 5s infinite ease-in-out 0.5s forwards; }

    .pointD8 .point::before{animation: scale 5s infinite ease-in-out 1.5s forwards; }
    .pointD8 .point::after{animation: scale2 5s infinite ease-in-out 1.5s forwards; }

    .pointD9 .point::before{animation: scale 5s infinite ease-in-out 2.5s forwards; }
    .pointD9 .point::after{animation: scale2 5s infinite ease-in-out 2.5s forwards; }

    .pointD10 .point::before{animation: scale 5s infinite ease-in-out 0.2s forwards; }
    .pointD10 .point::after{animation: scale2 5s infinite ease-in-out 0.2s forwards; }

    .pointD11 .point::before{animation: scale 5s infinite ease-in-out 2.5s forwards; }
    .pointD11 .point::after{animation: scale2 5s infinite ease-in-out 2.5s forwards; }

    .pointD12 .point::before{animation: scale 5s infinite ease-in-out 3.5s forwards; }
    .pointD12 .point::after{animation: scale2 5s infinite ease-in-out 3.5s forwards; }

    .pointD13 .point::before{animation: scale 5s infinite ease-in-out 0.5s forwards; }
    .pointD13 .point::after{animation: scale2 5s infinite ease-in-out 0.5s forwards; }

    .pointD14 .point::before{animation: scale 5s infinite ease-in-out 1.5s forwards; }
    .pointD14 .point::after{animation: scale2 5s infinite ease-in-out 1.5s forwards; }

    .pointD15 .point::before{animation: scale 5s infinite ease-in-out 0.5s forwards; }
    .pointD15 .point::after{animation: scale2 5s infinite ease-in-out 0.5s forwards; }

    .pointD16 .point::before{animation: scale 5s infinite ease-in-out 2s forwards; }
    .pointD16 .point::after{animation: scale2 5s infinite ease-in-out 2s forwards; }

    .pointD17 .point::before{animation: scale 5s infinite ease-in-out 2.5s forwards; }
    .pointD17 .point::after{animation: scale2 5s infinite ease-in-out 2.5s forwards; }

    .pointD18 .point::before{animation: scale 5s infinite ease-in-out 3s forwards; }
    .pointD18 .point::after{animation: scale2 5s infinite ease-in-out 3s forwards; }

    .pointD19 .point::before{animation: scale 5s infinite ease-in-out 0.5s forwards; }
    .pointD19 .point::after{animation: scale2 5s infinite ease-in-out 0.5s forwards; }

    .pointD20 .point::before{animation: scale 5s infinite ease-in-out 0.5s forwards; }
    .pointD20 .point::after{animation: scale2 5s infinite ease-in-out 0.5s forwards; }

    .pointD21 .point::before{animation: scale 5s infinite ease-in-out 1.5s forwards; }
    .pointD21 .point::after{animation: scale2 5s infinite ease-in-out 1.5s forwards; }

    .pointD22 .point::before{animation: scale 5s infinite ease-in-out 2s forwards; }
    .pointD22 .point::after{animation: scale2 5s infinite ease-in-out 2s forwards; }

    .pointD23 .point::before{animation: scale 5s infinite ease-in-out 2.5s forwards; }
    .pointD23 .point::after{animation: scale2 5s infinite ease-in-out 2.5s forwards; }

    .pointD24 .point::before{animation: scale 5s infinite ease-in-out 3s forwards; }
    .pointD24 .point::after{animation: scale2 5s infinite ease-in-out 3s forwards; }

    .pointD25 .point::before{animation: scale 5s infinite ease-in-out 0.5s forwards; }
    .pointD25 .point::after{animation: scale2 5s infinite ease-in-out 0.5s forwards; }

    @keyframes scale{
        0%{ transform: scale(1); opacity:.6} 
        100%{ transform: scale(10); opacity: 0;}}
    @keyframes scale2{
        0%{ transform: scale(1);opacity:.6;} 
        100%{ transform: scale(25);opacity:0;}
    }
    .point,.point::before,.point::after{
        background-color: rgb(0, 114, 198,.5);
    }
</style>
