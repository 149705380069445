<template>
    <div class="corporateCultureBox">
        <aboutBannerVue tabIndex="1"></aboutBannerVue>
       <div class="corMainBox">
            <p class="corTitle">品牌文化</p>
            <div class="cultureBox">
                <div class="cultrueW">
                    <div class="cultureList" v-for="(item,index) in list" :key="index">
                        <span class="sortName">{{item.index}}</span>
                        <span>{{item.name}}</span>
                        <span>——</span>
                        <span>{{item.desc}}</span>
                    </div>
                </div>
                <div class="cultrueW">
                    <div class="cultureList" v-for="(item,index) in lists" :key="index">
                        <span class="sortName">{{item.index}}</span>
                        <span>{{item.name}}</span>
                        <span>——</span>
                        <span>{{item.desc}}</span>
                    </div>
                </div>
            </div>
            <p class="corTitle">价值观实践体会</p>
            <div class="valueBox" ref="container" @scroll="handleScroll" v-if="valueList.length!=0">
                <div class="valItem" v-for="(item,index) in valueList" :key="index">
                    <div class="clearfix">
                        <img class="headImg fl" :src="'http://rhjtoa.ruihepm.com'+item.imageUrl" alt="">
                        <div class="headCont fl">
                            <p class="font18"><span class="mr10">{{item.userName}}</span>{{item.departmentName}}</p>
                            <p class="desc font16 mt10">{{item.title}}</p>
                        </div>
                    </div>
                    <div class="contBox">
                        <p>{{item.experience}}</p>
                    </div>
                </div>
            </div>
            <div v-if="valueList.length==0">
                <el-empty description="暂无数据"></el-empty>
            </div>
       </div>
    </div>
</template>

<script>
import aboutBannerVue from '../../components/aboutBanner'
	export default {
		name:'CorporateCulture',
        components:{aboutBannerVue},
        data() {
            return {
                pageindex:1,
                totalPage:0,
                list:[
                    {
                        name:'使命',
                        desc:'推动管理进步，赋能智慧工程',
                        index:1
                    },
                    {
                        name:'愿景',
                        desc:'领先的全过程咨询服务提供商',
                        index:2
                    },
                    {
                        name:'价值观',
                        desc:'成就客户、务实守信、合作共进、创新发展',
                        index:3
                    },
                    {
                        name:'服务理念',
                        desc:'诚信至高处、服务无限时',
                        index:4
                    },
                ],
                lists:[
                    {
                        name:'企业精神',
                        desc:'严谨 创新 卓越 奉献',
                        index:5
                    },
                    {
                        name:'管理理念',
                        desc:'宽容和谐 以人为本 以德兴企',
                        index:6
                    },
                    {
                        name:'经营理念',
                        desc:'以机制提升业绩，用知识改变命运',
                        index:7
                    },
                    {
                        name:'人才观',
                        desc:'精诚公正 崇智尚真 德才兼备',
                        index:8
                    }
                ],
                valueList:[]
            }
        },
        mounted(){
            this.getValinfo()
        },
        methods:{
            getValinfo(){
                this.Request({
                    url: '/webindex/zjrh/getValuesExcellentCase',
                    method: 'get',
                    data:{
                        pageindex:this.pageindex,
                        pagesize:8
                    }
                }).then(res=>{
                    var list= res.rows
                    for(var i=0;i<list.length;i++){
                        this.valueList.push(list[i])
                    }
                    this.totalPage = Math.ceil(res.total/8)
                })
            },

            // 活动加载数据
            handleScroll(){
                const container = this.$refs.container
                if (container.scrollTop + container.offsetHeight >= container.scrollHeight) {
                    // 加载数据
                    if(this.pageindex<this.totalPage){
                        this.pageindex++
                        this.getValinfo()
                    }
                }
            }
        }
	}
</script>
<style scoped>
    .corMainBox{
        width: 1200px;
        margin: 0 auto;
    }
    .corTitle{
        font-size: 20px;
        font-weight: bold;
        margin-top: 56px;
    }
    .cultureBox{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .cultrueW{
        width: 50%;
    }
    .cultureList{
        width: 100%;
        margin-top: 36px;
        display: flex;
        justify-content: flex-start;
        color: #666;
        line-height: 26px;
    }
    .sortName{
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: #DEDEDE;
        color: #666;
        text-align: center;
        line-height: 26px;
        font-size: 12px;
        margin-right: 15px;
    }
    .valueBox{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 42px;
        max-height: 900px;
        margin-top: 30px;
        overflow-y: auto;
        padding: 0 10px;
        padding-bottom: 40px;
    }
    .valItem{
        width: calc(400px - 22px);
        margin-top: 24px;
        min-height: 265px;
        padding: 10px;
        box-shadow: 0 0 12px 1px rgba(36,124,214,.2);
        border-radius: 8px;
        margin-right: 20px;
    }
    .valItem:nth-child(3n){
        margin-right: 0;
    }
    .headImg{
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
    .headCont{
        width: calc(100% - 80px);
        padding-left: 10px;
        padding-top: 10px;
    }
    .contBox{
        width: 100%;
        border-radius: 8px;
        margin-top: 20px;
        padding: 10px;
    }
    .contBox p{
        margin-bottom: 5px;
        line-height: 20px;
        font-size: 14px;
    }
</style>

<style>

	/* 滚动条样式 */
.valueBox::-webkit-scrollbar {
     width: 4px; /*  设置纵轴（y轴）轴滚动条 */
     height: 4px; /*  设置横轴（x轴）轴滚动条 */
 }
 /* 滚动条滑块（里面小方块） */
 .valueBox::-webkit-scrollbar-thumb {
     border-radius: 10px;
     box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
     background: #8b8b8b;
 }
 /* 滚动条轨道 */
 .valueBox::-webkit-scrollbar-track {
     border-radius: 0;
     box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
     background: #f0f0f0;
 }

</style>