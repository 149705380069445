<template>
    <div class="contactUs">
        <linkUs tabIndex="1"></linkUs>
        <div class="linkBox">
            <div>
                <p class="title">公司地址</p>
                <p>河北省石家庄市建设南大街269号河北师范大学科技园B座11、12层</p>
            </div>
            <div style="margin-left:100px">
                <p class="title">联系电话</p>
                <p>+86-0311-69052085/69052075</p>
            </div>
            <!-- <div>
                <p class="title">电子邮件</p>
                <p>ruihepm@126.com</p>
            </div> -->
        </div>
        <div class="mapBox">
            <!-- <iframe width="1200" height="380" frameborder='0' scrolling='no' marginheight='0' marginwidth='0' src="https://surl.amap.com/3DbvPorF5k4"></iframe> -->
            <baidu-map class="bm-view" ak="WxCDb8T3BlyKFESDWd5zaZDFsd6qt0na" :center="center" :zoom="zoom" style="width:1200px;height:380px">
                <!-- <bm-marker :position="center" :dragging="true"></bm-marker> -->
                <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                <bm-marker :position="center" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
                    <bm-overlay
                        pane="labelPane"
                        :class="{sample: true, active}"
                        @mouseover.native="active = true"
                        @mouseleave.native="active = false">
                        <div>瑞和安惠项目管理集团有限公司</div>
                    </bm-overlay>
                </bm-marker>
            </baidu-map>
        </div>
    </div>
</template>

<style scoped lang="less">
    .linkBox{
        width: 1200px;
        margin: 0 auto;
        margin-top: 42px;
        display: flex;
        justify-content: flex-start;
        color: #000;
    }
    .linkBox .title{
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .mapBox{
        width: 1200px;
        height: 380px;
        margin: 0 auto;
        margin-top: 42px;
        margin-bottom: 42px;
    }
    .sample {
        width: 260px;
        height: 50px;
        line-height: 50px;
        background: #fff;
        border: 1px solid #e5e5e5;
        color: #333;
        text-align: center;
        position: absolute;
        margin-top: 95px;
        margin-left: 470px;
    }
</style>
<script>
import linkUs from '@/components/linkUs'
	export default {
		name:'ContactUs',
        components:{linkUs},
        data() {
            return {
                center:{
                    lng:114.52044,
                    lat:38.00271 
                },
                zoom:20,
                active:true
            }
        },
        mounted(){
           
        }
	}
</script>