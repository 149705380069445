<template>
    <div class="corporateCultureBox">
        <aboutBannerVue tabIndex="5"></aboutBannerVue>
        <div class="mainShow">
            <p>本着务实进取不断探索的企业经营发展实践，努力践行党和国家提出的做一个对国家、对民族、对社会具有新时代中国特色社会主义价值观的民营企业。瑞和安惠项目管理集团有限公司紧跟党的发展路线先后成立了党支部、工会委员会、 “妇女之家”、团支部，笃定信念，听党话跟党走，不忘初心、牢记使命，打造瑞和安惠红色阵地党建品牌。</p>
            <p>在公司党支部的引领带动下，不断增强党群组织活力，通过党建引领，让党群工作始终贯穿于企业文化中，充分发挥党组织在企业经营发展中的领导作用。</p>
            <h3>瑞和安惠项目管理集团有限公司党支部</h3>
            <p>自党支部成立以来，积极投身参与党建学习公益活动。在日常工作中，支部党员时刻带头担当，本着对公司高度负责的精神，切实履行好本职工作岗位的相关职责，增强宗旨意识、责任意识，用党员的模范作用带领员工，用党员的先进性影响员工，使公司的各项工作得以全面落实。</p>
            <p>在接下来的工作中，公司党支部将立足本职，迎接挑战，不忘初心，牢记使命，准确把握“守初心、担使命，找差距、抓落实”这个总要求，增强“四个意识”、坚定“四个自信”、做到“两个维护”。让党建工作始终贯穿于企业文化当中，努力为创建一个具有全方位为国家、为社会、为客户提供优良价值取向的文化企业而不断奋斗和发展。</p>
            <h3>瑞和安惠项目管理集团有限公司工会委员会</h3>
            <p>瑞和安惠项目管理集团有限公司工会委员会自成立以来，坚持“以人为本”积极发挥党联系员工群众的桥梁和纽带作用，积极打造人文关怀的氛围，帮助员工解决实际困难，体现公司对员工的重视和关心，真心关注员工的发展。工会委员会将继续做好职工思想引导工作，团结引领广大职工听党话、跟党走，为职工营造一个舒适、优质的生活和工作环境；同时，定期开展企业文化建设，发挥工会职能，更好稳定人心、凝聚人心，构建和谐劳动关系，让职工真切感受到组织的温暖无处不在！</p>
            <h3>瑞和安惠项目管理集团有限公司「妇女微家」</h3>
            <p>为进一步激发广大妇女立足岗位，充分发挥妇联组织在企业发展中的重要作用，在上级妇联的指导下，我公司成立了“妇女微家”，致力于打造贴近妇女群众的坚定阵地和温暖之家，团结引领广大妇女坚定不移跟党走，关爱每一个妇女群体，维护广大妇女群众的权益，使“妇女微家”成为妇女群众的知心人、贴心人和“娘家人”。</p>
            <p>今后，我们将集训发挥“妇女微家”的积极作用,充分调动所有女职工的积极性和创造性，用周到的服务凝聚妇女、用切实的行动引领妇女，打造妇联服务品牌，打响“妇女微家”的名片，努力发挥党建带妇建的示范典型作用。</p>
            <h3>瑞和安惠项目管理集团有限公司团支部</h3>
            <p>为不断巩固和扩大党的青年群众基础，充分发挥团员青年的主力军作用，提高团组织的战斗力和凝聚力，经上级组织批准，由瑞和安惠牵头成立了师大科技园成立了楼宇联合团支部。</p>
            <p>下一步，我们将不断加强团组织的自身建设，团结带领团员青年发挥先锋模范作用，不断增强团的吸引力和凝聚力，为企业发展和组织队伍壮大注入新鲜血液和青春力量！</p>
            <p>未来，瑞和安惠党支部将更好的发挥党组织的组织、政治优势，创新非公党群工作新方法、新途径，努力为创建一个具有全方位为国家、为社会、为客户提供优良价值取向的文化企业而不断奋斗和发展！</p>
            <div class="dangBox">
                <img src="../../assets/aboutUs/dang/dang1.png" alt="">
                <img src="../../assets/aboutUs/dang/dang2.png" alt="">
                <img src="../../assets/aboutUs/dang/dang3.png" alt="">
                <img src="../../assets/aboutUs/dang/dang4.png" alt="">
                <img src="../../assets/aboutUs/dang/dang5.png" alt="">
                <img src="../../assets/aboutUs/dang/dang6.png" alt="">
            </div>
        </div>
    </div>
</template>

<style scoped>
   
</style>
<script>
import aboutBannerVue from '../../components/aboutBanner'
	export default {
		name:'CorporateCulture',
        components:{aboutBannerVue},
        data() {
            return {
                
            }
        },
	}
</script>
<style scoped>
    .mainShow{
        padding-bottom: 42px;
    }
    .mainShow p{
        line-height: 30px;
        color: #333;
        text-indent: 2em;
    }
    h3{
        font-size: 20px;
        margin-top: 42px;
        margin-bottom: 35px;
    }
    .dangBox{
        width: 1200px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 40px;
    }
    .dangBox img{
        width: 387px;
        height: 239px;
        margin-bottom: 20px;
    }
</style>