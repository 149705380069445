<template>
    <div class="IndustryNews">
        <mediaCenter tabIndex="3"></mediaCenter>
        <div class="industryBox">
            <div class="industryList clearfix" v-for="(item,index) in noticeList" :key="index">
                <p class="fl biaot" @click="jumpToDetails(item.id)">{{item.title}}</p>
                <span class="fr time">{{item.addtime}}</span>
            </div>
            <div class="" v-if="noticeList.length==0">
                <el-empty description="暂无数据"></el-empty>
            </div>
            <el-pagination class="pageBox" v-if="noticeList.length!=0" background @current-change="handleCurrentChange" :current-page.sync="currentPage" layout="prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
    </div>
</template>

<script>
import mediaCenter from '@/components/mediaCenter'
	export default {
		name:'IndustryNews',
        components: {
            mediaCenter
        },
        data() {
            return {
                noticeList: [],
                currentPage:1,
                pageindex:1,
                total:0
            }
        },
        mounted(){
            this.getNotice()
        },
        methods: {
             //获取公告
             getNotice(){
                this.Request({
                    url: '/webindex/news/noticeList',
                    method: 'get',
                    data:{
                        pageindex:this.pageindex,
                        pagesize:10,
                        webcode:100136102
                    }
                }).then((data) => {
                    if(data.rows && data.rows.length > 0){
                        this.noticeList = data.rows
                        this.total = data.total
                    }
                })
            },
            jumpToDetails(id) {
                // this.$router.push({
                //     path: '/mediaCenter/NoticeDetails?id='+id,
                // })
                window.location.assign('/mediaCenter/NoticeDetails?id='+id)
            },
            handleCurrentChange(val){
                this.currentPage = val
                this.pageindex=val
                this.getNotice()
            }

        }
	}
</script>

<style scoped>
    .industryBox{
        width: 1200px;
        margin: 0 auto;
        margin-top: 17px;
    }
    .industryList{
        padding: 32px 0;
        border-bottom: 1px solid #E1E1E1;
    }
    .industryList p{
        max-width: 90%;
        cursor: pointer;
    }
    .pageBox{
        text-align: center;
        margin-top: 35px;
        margin-bottom: 42px;
    }
</style>