<template>
  <div class="corporateCultureBox">
    <aboutBannerVue tabIndex="6"></aboutBannerVue>
    <div class="mapShow" id="main" style="width:1200px;height:735px"></div>
  </div>
</template>

<style scoped>
.mapShow {
  /* width: 1200px;
  height: 735px; */
  margin: 42px auto;
}
</style>

<script>
import aboutBannerVue from "../../components/aboutBanner";
import * as echarts from "echarts";
import 'echarts/extension/bmap/bmap'
export default {
  name: "CorporateCulture",
  components: { aboutBannerVue },
  data() {
    return {};
  },
  mounted() {
    this.getEchart()
  },
  methods: {
    getEchart() {
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;
      const data = [
        { name: "石家庄",value:125, tel:'12512',address:'地址' },
        { name: "承德",value:125, tel:'0314-7665666',address:'承德市火车站对面承市之光A座2单元1205'  },
        { name: "秦皇岛",  value:125,tel:'',address:'地址'  },
        { name: "张家口",  value:125,tel:'',address:'地址'  },
        { name: "唐山", value:125,tel:'',address:'地址'  },
        { name: "廊坊", value:125,tel:'',address:'地址'  },
        { name: "雄安", value:125,tel:'',address:'地址'  },
        { name: "保定", value:125,tel:'',address:'地址'  },
        { name: "沧州", value:125,tel:'',address:'地址'  },
        { name: "衡水", value:125,tel:'',address:'地址'  },
        { name: "邢台", value:125,tel:'',address:'地址'  },
        { name: "邯郸", value:125,tel:'',address:'地址'  },
        { name: "通辽", value:125,tel:'',address:'地址'  },
        { name: "辽宁", value:125,tel:'',address:'地址'  },
        { name: "天津", value:125,tel:'',address:'地址'  },
        { name: "太原", value:125,tel:'',address:'地址'  },
        { name: "滨州", value:125,tel:'',address:'地址'  },
        { name: "青岛", value:125,tel:'',address:'地址'  },
        { name: "焦作", value:125,tel:'',address:'地址'  },
        { name: "开封", value:125,tel:'',address:'地址'  },
        { name: "河南", value:125,tel:'',address:'地址'  },
        { name: "商丘", value:125,tel:'',address:'地址'  },
        { name: "江苏", value:125,tel:'',address:'地址'  },
        { name: "苏州", value:125,tel:'',address:'地址'  },
        { name: "安徽", value:125,tel:'',address:'地址'  },
        { name: "上海", value:125,tel:'',address:'地址'  },
        { name: "武汉", value:125,tel:'',address:'地址'  },
        { name: "成都", value:125,tel:'',address:'地址'  },
        { name: "重庆", value:125,tel:'',address:'地址'  },
        { name: "泸州", value:125,tel:'',address:'地址'  },
        { name: "长沙", value:125,tel:'',address:'地址'  },
        { name: "南昌", value:125,tel:'',address:'地址'  },
        { name: "大理", value:125,tel:'',address:'地址'  },
        { name: "海口", value:125,tel:'',address:'地址'  },
        { name: "海南", value:125,tel:'',address:'地址'  }

      ];
      const geoCoordMap = {
        石家庄: [114.53952, 38.03647],
        承德: [117.80024, 40.95913],
        秦皇岛: [119.48458, 39.83507],
        张家口: [114.89257 , 40.77324],
        唐山: [118.46023, 39.27313],
        廊坊: [116.68572, 39.50311],
        // 雄安: [118.87, 42.28], //数据不对
        保定: [115.45875, 38.87757],
        沧州: [116.86638, 38.31404],
        衡水: [115.57938, 37.55085],
        邢台: [114.507132, 37.06787],
        邯郸: [114.49339, 36.61853],
        通辽: [122.25615, 43.62244],
        // 辽宁: [123.97, 47.33], //信息不对
        天津: [117.30983, 39.71755],
        太原: [112.48699, 37.94036],
        滨州: [118.02279, 37.42726],
        青岛: [120.39629, 36.30744],
        焦作: [113.23064,35.24052],
        开封: [114.34816, 34.78861],
        // 河南: [118.87, 42.28], //数据不对
        商丘: [115.61396, 34.44379],
        // 江苏: [125.03, 46.58], //数据不对
        苏州: [120.63132, 31.30227],
        // 安徽: [109.781327, 39.608266], //数据不对
        上海: [121.48941, 31.40527],
        武汉: [114.02919, 30.58203],
        成都: [104.10194, 30.65984],
        重庆: [106.54041, 29.40268],
        泸州: [105.43501, 28.87875],
        长沙: [112.98626, 28.25591],
        南昌: [115.94422, 28.54538],
        大理: [100.26764, 25.60648],
        海口: [110.32941, 20.02971],
        // 海南: [125.03, 46.58] //数据不对
      };
      const convertData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value,data[i].tel,data[i].address),
            });
          }
        }
        return res;
      };
      option = {
        title: {
          text: "全国分支机构",
          subtext: "",
          sublink: "",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        bmap: {
          center: [119.53952, 30.03647],
          zoom: 6,
          roam: false, //控制地图是否可以缩放
          mapStyle: {  //地图显示样式
            styleJson: [
              {
                featureType: "water",
                elementType: "all",
                stylers: {
                  color: "#d1d1d1",
                },
              },
              {
                featureType: "land",
                elementType: "all",
                stylers: {
                  color: "#f3f3f3",
                },
              },
              {
                featureType: "railway",
                elementType: "all",
                stylers: {
                  visibility: "off",
                },
              },
              {
                featureType: "highway",
                elementType: "all",
                stylers: {
                  color: "#fdfdfd",
                },
              },
              {
                featureType: "highway",
                elementType: "labels",
                stylers: {
                  visibility: "off",
                },
              },
              {
                featureType: "arterial",
                elementType: "geometry",
                stylers: {
                  color: "#fefefe",
                },
              },
              {
                featureType: "arterial",
                elementType: "geometry.fill",
                stylers: {
                  color: "#fefefe",
                },
              },
              {
                featureType: "poi",
                elementType: "all",
                stylers: {
                  visibility: "off",
                },
              },
              {
                featureType: "green",
                elementType: "all",
                stylers: {
                  visibility: "off",
                },
              },
              {
                featureType: "subway",
                elementType: "all",
                stylers: {
                  visibility: "off",
                },
              },
              {
                featureType: "manmade",
                elementType: "all",
                stylers: {
                  color: "#d1d1d1",
                },
              },
              {
                featureType: "local",
                elementType: "all",
                stylers: {
                  color: "#d1d1d1",
                },
              },
              {
                featureType: "arterial",
                elementType: "labels",
                stylers: {
                  visibility: "off",
                },
              },
              {
                featureType: "boundary",
                elementType: "all",
                stylers: {
                  color: "#fefefe",
                },
              },
              {
                featureType: "building",
                elementType: "all",
                stylers: {
                  color: "#d1d1d1",
                },
              },
              {
                featureType: "label",
                elementType: "labels.text.fill",
                stylers: {
                  color: "#999999",
                },
              },
            ],
          },
        },
        series: [
          
          {
            name: "信息",
            type: "effectScatter",
            coordinateSystem: "bmap",
            data: convertData(data),
            symbolSize: function (val) {
              return val[2] / 10;
            },
            encode: {
              value: 2,
            },
            showEffectOn: "render", 
            rippleEffect: {
              brushType: "stroke",
            },
            tooltip:{
              formatter(value) {
                return value.data.name;
                // + "<br/>" + "电话：" +value.data.value[3]+'<br>'+"地址："+value.data.value[4]
              },
              show: true
            },
            label: {
              formatter: "{b}",
              position: "left",
              show: true,
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "#333",
            },
            emphasis: {
              scale: true,
            },
            zlevel: 1,
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>