<template>
    <div class="introductionBox">
       <businessBanner tabIndex="2"></businessBanner>
       <div class="mainShow">
        <p class="font20 fontw">造价咨询</p>
        <p class="mt36 jies">集团造价咨询业务开展于2003年，并于2005年在业内率先取得工程造价咨询甲级资质。 </p>
       </div>
       <div class="mainShows">
            <!-- <div class="main clearfix">
                <div class="fl cont">
                    <p class="contT">技术人才</p>
                    <p>经过多年的积累与沉淀，集团造价业务已发展成为拥有10余个独立业务部门，90余名专业技术人员，其中注册一级造价师40余名，包含建筑、交通、水利等专业；中级职称及以上人员占比约60%。</p>
                </div>
                <div class="fr techBox">
                    <div class="techs">
                        <img src="@/assets/businessScope/icons1.png" alt="">
                        <p class="techT">10+</p>
                        <p class="techC">独立业务部门</p>
                    </div>
                    <div class="techs">
                        <img src="@/assets/businessScope/icons2.png" alt="">
                        <p class="techT">90+</p>
                        <p class="techC">专业技术人员</p>
                    </div>
                    <div class="techs">
                        <img src="@/assets/businessScope/icons3.png" alt="">
                        <p class="techT">40+</p>
                        <p class="techC">注册一级造价师</p>
                    </div>
                    <div class="techs">
                        <img src="@/assets/businessScope/icons4.png" alt="">
                        <p class="techT">60%</p>
                        <p class="techC">中级职称及以上</p>
                    </div>
                </div>
            </div> -->
            <div class="main clearfix">
                <div class="fl contLeft">
                    <p class="contT">造价产品</p>
                    <p>集团造价咨询项目年立项600余项，服务过程涵盖了项目决策阶段、招投标阶段、施工阶段、竣工阶段等项目全生命周期，造价产品包括估算编制、审核，概算编制、审核，清单编制、审核，限价/预算编制、审核，施工阶段全过程造价咨询，结算编制、审核以及造价鉴定等7项。</p>
                </div>
                <img class="fr zaojIng" src="../../assets/businessScope/zaoj.png" alt="">
            </div>
       </div>
        <div class="main clearfix">
            <img class="fl xmImg" src="../../assets/businessScope/yej.png" alt="">
            <div class="fr contd">
                <p class="contT">业务荣誉</p>
                <p>凭借优质的资质资源、突出的业务能力以及丰富的业绩案例，集团获得了多家国家及省级行业权威机构认可：中国建设工程造价管理协会认证AAA级信用企业、中国建设工程造价管理协会会员单位、河北省建设工程造价管理协会副会长单位，并多次获得国家及省市级优秀造价成果奖项：中国建设工程造价管理协会优秀造价成果奖、河北省优秀造价成果一、二、三等奖等。</p>
            </div>
        </div>
    </div>
</template>

<script>
import businessBanner from '../../components/businessBanner'
	export default {
		name:'EarlierConsultation',
        components:{businessBanner},
        data() {
            return {
                
            }
        },
        mounted() {
           
        }
	}
</script>

<style scoped>
    .mainShows{
        width: 100%;
        background-color: #F7F9FD;
        margin-top: 42px;
    }
    .main{
        width: 1200px;
        margin: 0 auto;
        padding-top:47px;
        padding-bottom: 100px;
    }
    .jies{
        line-height: 30px;
    }
    .cont{
        width: 525px;
        color: #666;
    }
    .contT{
        color: #247CD6;
        font-size: 28px;
        margin-top: 60px;
        margin-bottom: 31px;
    }
    .contT+p{
        line-height: 36px;
    }
    .techBox{
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        width: 530px;
        text-align: center;
    }
    .techs img{
        width: 95px;
        height: 95px;
    }
    .techT{
        font-weight: bold;
        font-size: 27px;
        margin-top: 10px;
    }
    .techC{
        font-size: 14px;
        color: #666;
        margin-top: 10px;
    }
    .conts{
        width: 520px;
        color: #666;
        margin-top: 10px;
    }
    .dataLeft{
        width: 600px;
        margin-top: 56px;
    }
    .xmImg{
        width: 656px;
        margin-top: 33px;
    }
    .contd{
        width: 525px;
        color: #666;
    }
    .contLeft{
        width:525px;
        color: #666;
    }
    .zaojIng{
        width: 607px;
        display: block;
       margin-top: 42px;
    }
</style>