<template>
    <div>
        <div class="headerBanner">
            <div class="headerBannerBg" :style="`background-image: url(${bannerBg});`">
                <img class="title" :class="'title'+tabIndex" :src="title" alt="">
                <!-- <img class="desc" :src="desc" alt=""> -->
            </div>
            <div class="daohang">
                <div class="daohBox">
                    <div v-for="(item,index) in list" :key="index" :class="tabIndex==index+1?'active':''" @click="turnClick(item.url)">{{item.name}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '../assets/businessScope/banner.png'
import title1 from '../assets/businessScope/title1.png'
import title2 from '../assets/businessScope/title2.png'
import title3 from '../assets/businessScope/title3.png'
import title4 from '../assets/businessScope/title4.png'
import title5 from '../assets/businessScope/title5.png'
import title6 from '../assets/businessScope/title6.png'
import desc from '../assets/businessScope/desc.png'
export default {
    name: 'aboutBanner',
    props:['tabIndex'],
    data() {
        return {
            bannerBg:banner,
            title:'',
            desc:desc,
            list:[
                {
                    name:'前期咨询',
                    url:'/businessScope/earlierConsultation'
                },
                {
                    name:'造价咨询',
                    url:'/businessScope/costConsultation'
                },
                {
                    name:'招标代理',
                    url:'/businessScope/biddingAgent'
                },
                {
                    name:'管理咨询',
                    url:'/businessScope/projectManagement'
                },
                {
                    name:'全咨业务',
                    url:'/businessScope/consultationBusiness'
                },
                {
                    name:'数字化应用',
                    url:'/businessScope/BIMApplication'
                }
            ]
        };
    },
    watch: {
        $route: {
            handler(value){
                let bannerBg = value.matched[value.matched.length - 1].meta.bannerBg
                switch(bannerBg){
                    case 'banner001': 
                        this.bannerBg = banner
                        this.title = title1
                        break;
                    case 'banner002': 
                        this.bannerBg = banner
                        this.title = title2
                        break;
                    case 'banner003': 
                        this.bannerBg = banner
                        this.title = title3
                        break;
                    case 'banner004': 
                        this.bannerBg = banner
                        this.title = title4
                        break;
                    case 'banner005': 
                        this.bannerBg = banner
                        this.title = title5
                        break;
                    case 'banner006': 
                        this.bannerBg = banner
                        this.title = title6
                        break;
                    default:
                        this.bannerBg = banner
                        this.title = title1
                }
                window.scrollTo(0,0)
            },
            immediate: true
        }
    },
    mounted(){
        
    },
    methods: {
        //二级导航跳转
        turnClick(url){
            this.$router.push({
                path:url
            })
        }
    }
}
</script>
<style scoped>
     .headerBannerBg {
        height: 260px;
        background-size: cover;
        background-position: center;
    }
    .title{
        display: block;
        margin: 0 auto;
        padding-top: 108px;
    }
    .title1{
        width: 684px;
    }
    .title2{
        width: 884px;
    }
    .title3{
        width: 829px;
    }
    .title4{
        width: 829px;
    }
    .title5{
        width: 829px;
    }
    .title6{
        width: 1179px;
    }
    /* .desc{
        display: block;
        width: 722px;
        margin: 0 auto;
        margin-top: 35px;
    } */
    .daohang{
        width: 100%;
        height: 50px;
        background-color: #fff;
        /* border-bottom: 3px solid #F9F9FA; */
        box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.04);
    }
    .daohBox{
        width: 700px;
        margin: 0 auto;
        line-height: 48px;
        display: flex;
        justify-content: space-around;
        color: #333;
        font-size: 16px;
        font-weight: 400;
    }
    .daohBox div{
        cursor: pointer;
        padding: 0 5px;
    }
    .active{
        color: #247CD6;
        border-bottom: 2px solid #247CD6;
        padding: 0 5px;
        font-weight: bold;
    }
</style>