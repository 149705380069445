<template>
    <div class="introductionBox">
       <businessBanner tabIndex="1"></businessBanner>
       <div class="mainShow">
        <p class="font20 fontw">前期咨询</p>
        <p class="mt36 jies">集团前期咨询业务开展于2004年，经过多年的潜心发展，如今已成为资信水平领先，技术力量雄厚，业绩能力突出的综合型咨询业务。</p>
       </div>
       <div class="mainShows">
            <div class="main clearfix">
                <div class="fl cont">
                    <p class="contT">专项资信</p>
                    <p>建筑，电子、信息工程（含通信、广电、信息化），石化、化工、医药，机械（含智能制造），轻工、纺织，市政公用工程等六项专业甲级以及PPP咨询专项甲级等资信。</p>
                    <p class="erhP">生态建设工程和环境工程；农业、林业；水利水电；电力（含火电、水电、核电、新能源）；建材；其他（节能）等六项专业乙级资信。</p>
                </div>
                <img class="fr jiajImg" src="@/assets/businessScope/jiaj.png" alt="">
            </div>
       </div>
       <div class="main clearfix" style="padding-top:60px;padding-bottom:25px">
            <img class="fl xmImg" src="@/assets/businessScope/xingm.png" alt="">
            <div class="fr contd">
                <p class="contT">项目类型</p>
                <p>集团前期咨询项目年立项1000余项，项目类型涵盖规划咨询、项目咨询、评估咨询、专项评价以及综合决策咨询与管理等，咨询产品共计70项。</p>
            </div>
        </div>
       <div class="mainShows">
            <div class="main clearfix">
                <div class="fl contLeft">
                    <p class="contT">业务荣誉</p>
                    <p>凭借领先的资信水平、突出的业务能力以及丰富的业绩案例，集团获得了多项国家及省级行业权威机构认可：中国工程咨询协会特邀常务理事、河北省工程咨询协会常务理事、苏州市工程咨询协会会长单位。并多次获得国家及省市级工程咨询优秀成果奖项：全国优秀工程咨询成果二等奖、三等奖、优秀奖；河北省优秀工程咨询成果一等奖、二等奖、三等奖；湖北省优秀工程咨询成果一等奖；苏州市优秀工程咨询成果一等奖、二等奖、三等奖等。</p>
                </div>
                <div class="fr">
                    <img class="demo" src="@/assets/businessScope/demo1.png" alt="">
                </div>
            </div>
       </div>
    </div>
</template>

<script>
import businessBanner from '@/components/businessBanner'
	export default {
		name:'EarlierConsultation',
        components:{businessBanner},
        data() {
            return {
                
            }
        },
        mounted() {
           
        }
	}
</script>

<style scoped>
    .mainShows{
        width: 100%;
        background-color: #F7F9FD;
        margin-top: 42px;
    }
    .main{
        width: 1200px;
        margin: 0 auto;
        padding-top:47px;
        padding-bottom: 56px;
    }
    .jies{
        line-height: 30px;
    }
    .cont{
        width: 525px;
        color: #666;
    }
    .contT{
        color: #247CD6;
        font-size: 28px;
        margin-top: 60px;
        margin-bottom: 31px;
    }
    .contT+p{
        line-height: 36px;
    }
    .erhP{
        line-height: 36px;
        margin-top: 10px;
    }
    .jiajImg{
        width: 637px;
    }
    .conts{
        width: 520px;
        color: #666;
        margin-top: 10px;
    }
    .dataLeft{
        width: 600px;
        margin-top: 56px;
    }
    .dataS{
        width: 180px;
        margin-right: 120px;
        margin-bottom: 60px;
        float: left;
    }
    .icon{
        width: 50px;
        height: 50px;
        margin-right: 14px;
        margin-top: 8px;
    }
    .number{
        font-size: 27px;
        font-weight: bold;
    }
    .dept{
        margin-top: 5px;
        color: #666;
        font-size: 14px;
    }
    .xmImg{
        width: 641px;
    }
    .contd{
        width: 520px;
        color: #666;
    }
    .contLeft{
        width: 601px;
        color: #666;
    }
    .demo{
        width: 555px;
        display: block;
        margin-top: 16px;
    }
</style>