import Vue from 'vue'
import loading from "@/components/loading"

const createloading = Vue.extend(loading)
const instance = new createloading({
    el: document.createElement('div')
})

instance.show = false;


const $loading = {
    show(tips) {
        instance.show = true;
        instance.tips = tips;
        // document.body.appendChild(instance.$el)
    },
    hide() {
        setTimeout(() => {
            instance.show = false
        }, 300)

    }
}

export default {
    install() {
        if (!Vue.$loading) {
            Vue.$loading = $loading
        }

        Vue.mixin({
            created() {
                this.$loading = Vue.$loading
            }
        })
    }
}