<template>
    <div>
        <div class="headerBanner">
            <div class="headerBannerBg" :style="`background-image: url(${bannerBg});`">
                <img class="title" :src="title" alt="">
                <img class="desc" :src="desc" alt="">
            </div>
            <div class="daohang">
                <div class="daohBox">
                    <div v-for="(item,index) in list" :key="index" :class="tabIndex==index+1?'active':''" @click="turnClick(item.url)">{{item.name}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '../assets/humanResources/banner.png'
import title from '../assets/humanResources/title.png'
import desc from '../assets/humanResources/desc.png'
export default {
    name: 'aboutBanner',
    props:['tabIndex'],
    data() {
        return {
            bannerBg:banner,
            title:title,
            desc:desc,
            list:[
                {
                    name:'人才招聘',
                    url:'/humanResources/Job'
                },
                {
                    name:'团队活动',
                    url:'/humanResources/GroupActivities'
                }
            ]
        };
    },
    watch: {
        },
    mounted(){
        
    },
    methods: {
        //二级导航跳转
        turnClick(url){
            this.$router.push({
                path:url
            })
        }
    }
}
</script>
<style scoped>
     .headerBannerBg {
        height: 260px;
        background-size: cover;
        background-position: center;
    }
    .title{
        display: block;
        width:629px;
        margin: 0 auto;
        padding-top: 76px;
    }
    .desc{
        display: block;
        width: 335px;
        margin: 0 auto;
        margin-top: 30px;
    }
    .daohang{
        width: 100%;
        height: 50px;
        background-color: #fff;
        box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.04);
    }
    .daohBox{
        width: 200px;
        margin: 0 auto;
        line-height: 48px;
        display: flex;
        justify-content: space-around;
        color: #333;
        font-size: 16px;
        font-weight: 400;
    }
    .daohBox div{
        cursor: pointer;
        padding: 0 5px;
    }
    .active{
        color: #247CD6;
        border-bottom: 2px solid #247CD6;
        padding: 0 5px;
        font-weight: bold;
    }
</style>