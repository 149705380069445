<template>
    <div class="corporateCultureBox">
        <aboutBannerVue tabIndex="4"></aboutBannerVue>
        <div class="socialInfo">
           <p class="title">{{info.title}}</p>
            <div class="contents" v-html="info.content"></div>
           <!-- <el-descriptions title="" :column="1" border labelClassName="labelW">
                <el-descriptions-item label="项目概况"><p v-html="list[2]"></p></el-descriptions-item>
                <el-descriptions-item label="项目投资"><p v-html="list[4]"></p></el-descriptions-item>
                <el-descriptions-item label="服务范围" :span="2"><p v-html="list[6]"></p></el-descriptions-item>
                <el-descriptions-item label="项目图">
                    <img class="map" src="../../assets/aboutUs/social/map.png" alt="">
                </el-descriptions-item>
            </el-descriptions> -->
        </div>
    </div>
</template>

<script>
import aboutBannerVue from '../../components/aboutBanner'
	export default {
		name:'CorporateCulture',
        components:{aboutBannerVue},
        data() {
            return {
                info:'',
                list:[]
            }
        },
        mounted(){
           this.getInfo()
        },
       methods:{
         //获取社会价值详情
         getInfo(){
            this.Request({
                url: '/webindex/news/newsDetail',
                data: {
                    id: this.$route.query.id
                },
                method: 'get',
            }).then((data) => {
                this.info = data.tdata
                // var list = data.tdata.content.split('</p>')
                // list[2] = list[2].replace('项目概况：','')
                // list[4] = list[4].replace('项目投资：','')
                // list[6] = list[6].replace('服务范围：','')
                // this.list = list
            })
        }
       }
	}
</script>
<style scoped>
    .socialInfo{
        width: 1200px;
        margin: 42px auto;
        padding: 30px 40px 40px 40px;
        box-shadow: 0px 0px 10px 0px rgba(36,124,214,0.15);
    }
    .title{
        font-size: 24px;
        font-weight: 400;
        padding-bottom: 30px;
        margin-bottom: 27px;
        border-bottom: 1px solid #E8E8E8;
    }
    .map{
        width: 520px;
        height: 367px;
    }
    .contents{
        margin-top: 64px;
    }
    .contents img{
        max-width: 100%;
    }
</style>
<style>
    .labelW{
        width: 161px;
        text-align: center !important;
    }
</style>