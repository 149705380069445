import { render, staticRenderFns } from "./Introduction.vue?vue&type=template&id=06b187c1&scoped=true&"
import script from "./Introduction.vue?vue&type=script&lang=js&"
export * from "./Introduction.vue?vue&type=script&lang=js&"
import style1 from "./Introduction.vue?vue&type=style&index=1&id=06b187c1&scoped=true&lang=css&"
import style2 from "./Introduction.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b187c1",
  null
  
)

export default component.exports