<template>
    <div class="corporateCultureBox">
        <aboutBannerVue tabIndex="2"></aboutBannerVue>
        <div class="mainShow">
            <p class="font20 fontw">关于我们</p>
            <div class="clearfix content mt36">
                <div class="fl contentLeft">
                    <p>瑞和安惠项目管理集团有限公司创建于2000年，是集招标采购、造价咨询、工程监理、前期咨询、PPP咨询、项目管理、BIM应用及IPMP项目管理培训于一体的全过程工程咨询企业。</p>
                    <p>瑞和安惠始终秉承“诚信至高处，服务无限时”的服务理念，服务体系不断扩展，至今已发展成为拥有9个全过程咨询公司、11个区域分公司及事业部、多个省外机构以及1个信息技术公司的集团企业，服务网点遍布全省11个地市及雄安、天津、河南、山东、山西、江苏、湖北、海南、四川等地。</p>
                    <p>在“互联网+工程咨询”的浪潮中，瑞和安惠凭借多项行业甲级资质以及成熟的信息化技术，组建了完整的全过程工程咨询业务链，构建了完善的智能化OA办公系统，研发了多个数字化咨询业务平台，率先在工程咨询行业实现了数字化转型。</p>
                </div>
                <img class="fr rencai" src="../../assets/aboutUs/intro/rencai.png" alt="">
            </div>
            <div class="content">
                <p>从实践中来，到实践中去。瑞和安惠总结从业二十余年的项目管理精华与实践经验，投入各专业领域精英数十人，经过五年的梳理与提炼，出版发行了“工程咨询理论与实践研究系列丛书”，共计10本著作。</p>
                <p>引领技术创新，推动行业进步。瑞和安惠项目管理集团依托成熟的全过程工程咨询和BIM应用经验，依据现行国家标准，参与编制了多项国家及河北省工程建设标准。</p>
            </div>
            <p class="font20 fontw mt42">核心优势</p>
            <div class="yousBox" v-for="(item,index) in guocList" :key="index">
                <div class="clearfix">
                    <span class="points fl"></span>
                    <span class="fl">{{item.title}}</span>
                </div>
                <p>{{item.content}}</p>
            </div>
            <!-- <div class="guocBox">
                <div class="guocList" v-for="(item,index) in guocList" :key="index">
                    <p class="title">{{item.title}}</p>
                    <p :class="index!=8?'desc':'descLast'">{{item.content}}</p>
                </div>
            </div>
            <img class="guoBottom" src="../../assets/aboutUs/intro/bottom.png" alt=""> -->
        </div>
        <div class="organBox">
            <div class="organs">
                <p class="font20 fontw">组织架构</p>
                <div class="organsBg">
                    <img src="../../assets/aboutUs/intro/jituan.png" alt="">
                </div>
            </div>
        </div>
        <div class="teamBox">
            <p class="font20 fontw">专家团队</p>
            <el-carousel indicator-position="outside" v-if="teamList.length!=0" :autoplay="true" :interval="5000" @change="changeSwiper" arrow="never" height="400px">
                <el-carousel-item v-for="(item,index) in length" :key="index">
                    <div class="team">
                        <div class="teamList" v-for="(item,index) in teamList" :key="index">
                            <img class="teamImg" :src="item.picture" alt="">
                            <p class="fontw name">{{item.title}}</p>
                            <div class="tag" v-if="item.tag">
                                <span>{{item.tag}}</span>
                            </div>
                            <el-popover  placement="top" title="专家优势" width="200" trigger="hover">
                                <div class="miaoshu" v-html="'<p class=\'fontw mt10 mb10\'>执（职）业资格</p>'+item.power+'<p class=\'fontw mt10 mb10\'>擅长领域--</p>'+item.area"></div>
                                <p class="miaoshu overh2" slot="reference" v-html="item.power?item.power+'、'+item.area:''"></p>
                            </el-popover>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
            <div v-if="teamList.length==0">
                <el-empty description="暂无数据"></el-empty>
            </div>
        </div>
    </div>
</template>

<style scoped>
   
</style>
<script>
    import aboutBannerVue from '../../components/aboutBanner'
    import teamList from '../../js/person.js'
	export default {
		name:'CorporateCulture',
        components:{aboutBannerVue},
        data() {
            return {
                guocList:[
                    {
                        title:'全过程综合咨询服务能力',
                        content:'瑞和安惠以工程咨询、造价咨询、招标代理、管理咨询、项目管理培训与推广为起点，着眼于全过程工程咨询与项目管理咨询的综合企业集团，从项目的决策阶段、前期阶段到设计阶段、实施阶段、竣工移交阶段，瑞和安惠的专业服务涵盖了工程项目的全过程。'
                    },
                    {
                        title:'具有项目各阶段深度服务能力',
                        content:'瑞和安惠一直都着重于各阶段服务的创新与提升，各阶段形成了单项业务的服务标准模式，每项服务都有一批领先专才团队，积累了丰富的咨询经验，具备深度咨询服务能力。'
                    },
                    {
                        title:'组织机构完善，驻地机构遍布全省，服务更及时、高效',
                        content:'按照现代企业管理制度和项目管理的主旨，瑞和安惠实施项目化管理，建立了适合工程咨询企业的项目管理运行机制——职能式与矩阵式相结合的复合式组织结构。集团设有财务中心、技术中心、行政人事法务中心、运营中心、信息中心等运营保障部门，同时全省各地市设有直属分公司或办事处，并在多个省份设有独立分支机构。'
                    },
                    {
                        title:'具有丰富的人才资源',
                        content:'瑞和安惠非常重视对人才的吸纳、培养，并努力为专业人才的成长创造条件，目前拥有三百余名专业知识结构合理、实践经验丰富的专职技术人员。其中，中、高级职称以上专业技术人员及各类注册执业人员均超过从业人员总数的60%。在2008年开始进行IPMP认证，截止目前，39人通过IPMP A/B/C级项目经理认证。'
                    },
                    {
                        title:'以项目管理为导向的组织机构和管理体系',
                        content:'瑞和安惠运用现代项目管理的技术、方法、理念以及集团公司现阶段各项业务实际情况，以ISO9000为基础，建立了适用于全过程工程项目管理服务和各阶段服务的项目管理体系，并形成了各项业务的服务标准——项目管理手册（PMHANDBOOK）。在2018年通过了国际项目管理协会（IPMA）面向组织的企业项目管理能力认证（IPMA Delta），并取得IV级（体系化管理级）国际认证。'
                    },
                    {
                        title:'面向项目全过程管理的信息化系统',
                        content:'瑞和安惠项目管理依据自身项目管理实践经验，结合项目管理需要及信息化发展的具体情况，自主研发了公司项目管理信息化系统，从2004年开始，经过不断完善，系统已经涵盖了公司所有项目的项目管理及日常管理。'
                    },
                    {
                        title:'项目管理理论应用与实践经验丰富',
                        content:'瑞和安惠发起组建了河北省项目管理协会，依托协会构建政府、企业交流平台；结合政府投资项目特点，运用现代项目管理，推进政府投资项目管理的普及与深化；创新现代项目管理普及、推广形式，促进现代项目管理的应用。先后为曹妃甸基础设施投资有限公司、华北制药等大型机构咨询、编制项目管理手册，在现代项目管理应用领域发挥积极作用。'
                    },
                    {
                        title:'政府投资项目管理领域遥遥领先',
                        content:'瑞和安惠依托全过程咨询服务优势率先在河北省政府投资领域做了一些有益的尝试，探索政府投资项目代建模式。运用现代项目管理，结合政府投资特点，进行政府投资项目培训与管理咨询；积极参与河北省政府投资项目代建制制度制定；率先参与政府投资项目代建制,先后承接了承德医学院新校区项目、曹妃甸工业区四大联检机构办公楼、承德奥林匹克体育中心（河北第一个政府投资代建制项目）、唐山南湖西北片区安置区工程等重大政府投资项目项目管理、代建工作。'
                    },
                ],
                pageindex:1,
                pagesize:10,
                teamList:[],
                length:1,
            }
        },
        mounted(){
            this.getteam()
        },
        methods:{
            // 获取专家团队列表
            getteam(){
                this.teamList = teamList
                this.length =Math.ceil(teamList.length / 4)
                // this.Request({
                //     url: '/webindex/zjrh/expertTeamList',
                //     method: 'get',
                //     data:{
                //         pageindex:this.pageindex,
                //         pagesize:8
                //     }
                // }).then((data) => {
                //     if(data.rows && data.rows.length > 0){
                //         this.teamList = data.rows
                //         this.length =Math.ceil( data.rows.length / 4)
                //     }
                // })
            },

            //修改轮播图索引
            changeSwiper(e){
                this.teamList= teamList.slice(4*e,4*e+4)
                // this.getteam()
            }
        }
	}
</script>

<style scoped>
    .content{
        font-size: 16px;
        color: #333333;
        font-weight: 400;
        line-height: 36px;
        text-indent: 2em;
    }
    .contentLeft{
        width: 585px;
    }
    .rencai{
        width: 580px;
        height: 293px;
        margin-top: 18px;
    }
    .yousBox{
        line-height:36px;
        margin-top: 15px;
    }
    .yousBox p{
        text-indent: 2em;
        margin-left: 2px;
    }
    .points{
        width: 16px;
        height: 16px;
        background: #247CD6;
        margin-left: 10px;
        margin-right: 9px;
        margin-top: 10px;
    }
    .points+span{
        color: #247CD6;
        font-size: 16px;
        font-weight: bold;
    }
   /*  .guocBox{
        width: 796px;
        margin: 0 auto;
        margin-top: 35px;
        display: flex;
        justify-content: space-around;
        flex-flow: wrap;
    }
    .guocList{
        width: 255px;
        height: 146px;
        background: url('../../assets/aboutUs/intro/kuang.png');
        background-size: 100% 100%;
        margin-bottom: 22px;
        padding: 25px 10px 25px 15px;
    }
    .guocList .title{
        font-weight: bold;
        font-size: 16px;
    }
    .guocList .desc{
        color: #666;
        font-size: 14px;
        margin-top: 10px;
        line-height: 24px;
    }
    .descLast{
        text-align: center;
        line-height: 90px;
    }
    .guoBottom{
        width: 1200px;
        margin-top: -60px;
        position: relative;
        z-index: -1;
    } */
    .organBox{
        width: 100%;
        height: 770px;
        background: url('../../assets/aboutUs/intro/introBg.png');
        background-size: 100% 100%;
        margin-top: 42px;
    }
    .organs{
        width: 1200px;
        margin: 0 auto;
        padding-top: 42px;
    }
    .organsBg{
        width: 1200px;
        height: 600px;
        margin: 0 auto;
        margin-top: 42px;
        padding-top: 43px;
        background-color: #fff;
    }
    .organsBg img{
        display: block;
        width: 955px;
        margin: 0 auto;
    }
    .teamBox{
        width: 1200px;
        margin: 0 auto;
        margin-top: 42px;
        padding-bottom: 20px;
    }
    .team{
        display: flex;
        justify-content: flex-start;
        flex-flow: wrap;
        margin-top: 36px;
        margin-left: 2px;
    }
    .teamList{
        width: 284px;
        height: 350px;
        box-shadow: 0px 0px 5px 2px rgba(45,45,45,0.06);
        border-radius: 5px;
        margin-right: 19px;
        margin-bottom: 20px;
        padding: 25px 16px;
    }
    .teamList:nth-child(4n){
        margin-right:0;
    }
    .teamImg{
        display: block;
        width: 115px;
        height: 150px;
        border-radius: 5px;
        margin: 0 auto;
    }
    .teamList .name{
        text-align: center;
        margin-top: 15px;
    }
    .teamList:hover .name{
        color: #247CD6;
    }
    .tag{
        margin-top: 15px;
    }
    .tag span{
        display: block;
        width: 106px;
        height: 24px;
        margin: 0 auto;
        text-align: center;
        line-height: 24px;
        background-color: #DBF0FF;
        color: #157FCC;
        font-size: 14px;
        border-radius: 3px;
    }
    .miaoshu{
        margin-top: 20px;
        color: #666;
        line-height: 24px;
        font-size: 14px;
    }
</style>
<style>
    .teamBox .el-carousel__container{
        height: 400px;
        max-height: 810px;
    }
    .teamBox .el-carousel__button{
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }
    .teamBox .el-carousel__indicators--outside button{
        background-color: #D5D5D5;
    }
    .teamBox .el-carousel__indicator.is-active button{
        background-color: #247CD6;
    }
</style>