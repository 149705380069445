<template>
    <div class="introductionBox">
       <businessBanner tabIndex="6"></businessBanner>
       <div class="mainShow">
            <p class="font20 fontw">数字化应用</p>
            <p class="mt36 jies"> 数字化是以数字技术为基础，通过信息化手段改变传统咨询服务工作方式，实现数字化转型和创新。《工程咨询行业2021—2025年发展规划纲要》提出，“运用‘互联网+’、人工智能、大数据、云计算等新一代信息技术提高工程咨询数字化水平建立行业数据库与分析系统，逐步形成高效的行业信息资源管理和共享体系”。为此瑞和安惠也提出了“数字化赋能全过程工程咨询”的战略目标，并在此规划下积极开展数字化应用，应用自主研发的OA、CRM、PMO、惠招标、惠造价、惠管理、云咨空间等平台，同时借助BIM技术等其他数字化工具，在公司运营和项目管控中全面开展了数字化应用，并将应用过程积累的信息和数据进行整合，形成了数据资产。</p>
       </div>
       <div class="mainShows mt42">
            <div class="main clearfix">
                <div class="fl cont">
                    <p class="contT">OA系统（办公自动化系统）</p>
                    <p>涵盖合同管理、项目管理、人力资源、行政办公、档案资料、知识管理、考核评价等系统，全部运行项目及行政办公纳入OA，全部无纸化办公，通过OA系统提高了办公效率，对项目进行全面、全程监控。</p>
                </div>
                <img class="fr bImg1" src="@/assets/businessScope/bim1.png" alt="">
            </div>
       </div>
       <div class="mainShowd clearfix">
            <img class="fl bImg2" src="@/assets/businessScope/bim2.png" alt="">
            <div class="fr contLeft">
                <p class="contT">CRM（客户关系管理系统）</p>
                <p>通过打造数智化共享价值平台，利用汇集商机线索、公海分配、客户管理和回访管理相关功能，提升客户、资源跟踪效率及客户满意度。实现对市场端的全面信息推送、动态跟踪过程、重点环节支持、实时数据分析，全面能市场人员。</p>
            </div>
        </div>
        <div class="mainShows">
            <div class="main clearfix">
                <div class="fl cont">
                    <p class="contT" style="margin-top:80px">PMO系统（项目管理管控系统）</p>
                    <p>利用PMO系统集成项目管理、人力资源管理、财务管理等功能的优势，对项目管控情况进行总体内部监控，发现预警，及时采用有效措施纠偏，全过程工程咨询等中长期项目全部纳入进行管控。</p>
                </div>
                <img class="fr bImg3" src="@/assets/businessScope/bim3.png" alt="">
            </div>
       </div>
       <div class="mainShowd clearfix">
            <img class="fl bImg4" src="@/assets/businessScope/bim4.png" alt="">
            <div class="fr contRight">
                <p class="contT">BIM技术应用</p>
                <p>瑞和安惠2013年组建BIM中心，是河北省首家ATC（Revit）授权培训中心。利用BIM技术可视化、协调性、模拟性、优化性和可出图性优势，结合无人机、VR、全景影像等设备，先后在枣强县人民医院、曹妃甸环保产业园、玉田县医院整体迁建、中国(河北)自贸试验区正定片区金融创新及进口商品展示交易中心、石家庄市第四十中新校区改建等项目成功运用BIM技术，并积极研究BIM技术的创新应用点。先后获得了“龙图杯”三等奖、中建协BIM大赛卓越工程项目二等奖、河北省第一～四届“燕赵杯”BIM技术应用大赛一、二等奖。</p>
            </div>
        </div>
        <div class="mainShows">
            <div class="main clearfix">
                <div class="fl cont">
                    <p class="contT" style="margin-top:30px">惠招标（电子招标投标交易平台）</p>
                    <p>业务功能覆盖发标、投标、开标、评标、定标等招投标全部业务环节，并引入了河北CA、银联支付、电子保函、支付宝、电子发票等第三方平台接口。瑞和安惠利用“惠招标”处理招投标活动中的发标、投标、开标、评标、定标等环节，应用招标文件制作工具以填空或选择的形式完成招标文件的编制，从登记项目信息开始，包括使用辅助工具制作招标文件、发布公告、审核报名及缴费情况（如有）、澄清踏勘、抽取专家、发布中标候选人公示、发布中标公告等。实现了线上开展招标活动，并全程留痕，所有操作以数字电文的形式存储，可追溯、可审计。</p>
                </div>
                <img class="fr bImg5" src="@/assets/businessScope/bim5.png" alt="">
            </div>
       </div>
       <div class="mainShowd clearfix">
            <img class="fl bImg6" src="@/assets/businessScope/bim6.png" alt="">
            <div class="fr contRight" style="margin-top:0">
                <p class="contT">惠造价（造价业务管理系统）</p>
                <p>瑞和安惠自主研发了集业务管理与流程管控于一体的造价业务管理平台——惠造价，员工通过“惠造价”实现线上工作推送、任务分工、进度编制及质量审核，实现全过程造价咨询的线上办公及档案留存。“惠造价”集成了合同登记、项目立项、资料准备、计划编制、初稿审核、终稿审核、项目归档业务的全流程管控，运用信息化技术处理使其简单、自动和智能。</p>
            </div>
        </div>
        <div class="mainShows">
            <div class="main clearfix">
                <div class="fl cont">
                    <p class="contT" style="margin-top:20px">惠管理（全咨业务管理系统）</p>
                    <p>瑞和安惠自主研发了基于轻量化BIM的全过程咨询协同平台——惠管理。通过“惠管理”平台让项目经理掌控单个或多个项目的目标控制情况，随时随地掌握项目人员工作情况。“惠管理”基于轻量化BIM模型，具有灵活、简便的体系架构，贯穿项目全生命周期，以业主为核心，各参建方高效协同，动态据支持决策，可扩展构件级信息，全程记录，永久追溯，可交付完整竣工模型，后续服务延伸至运维阶段。为客户提供透明、高效、节资、节时的超值服务。</p>
                </div>
                <img class="fr bImg7" src="@/assets/businessScope/bim7.png" alt="">
            </div>
       </div>
       <div class="mainShowd clearfix">
            <img class="fl bImg8" src="@/assets/businessScope/bim8.png" alt="">
            <div class="fr contRight">
                <p class="contT">云咨空间</p>
                <p>云咨空间是专门为工程咨询供需双方搭建的智库平台，运用2B2C模式，通过云计算、大数据、区块链、AI技术、数字经济等先进技术手段，为需求方快速精准匹配随需而动的服务资源，解决项目机会、工程师和专家、行业智库等一系列资源共享问题。瑞和安惠利用“云咨空间”开展前期规划咨询、项目咨询、专项咨询，线上进行需求发布、项目协作、资料查询等工作，实现投资决策综合性咨询的资源共享、人才共享、共同协作，提高了工作效率。</p>
            </div>
        </div>
        <div class="mainShows">
            <div class="shows">
                <p>除以上数字化平台、系统工具应用外，在数字化方面，瑞和安惠还参与了河北省多项“建筑信息模型”相关标准、信息化研究课题及中咨协会《新基建数字化项目管理指南》《投资决策综合性咨询数字化应用指南》等数字化应用相关标准，理论与实践相结合，持续助力、推进数字化在工程咨询行业的应用与发展。</p>
            </div>
       </div>
    </div>
</template>

<script>
import businessBanner from '../../components/businessBanner'
	export default {
		name:'EarlierConsultation',
        components:{businessBanner},
        data() {
            return {
                
            }
        },
        mounted() {
           
        }
	}
</script>

<style scoped>
    .mainShows{
        width: 100%;
        background-color: #F7F9FD;
    }
    .mainShowd{
        width: 1200px;
        margin: 0 auto;
        padding: 56px 0;
    }
    .main{
        width: 1200px;
        margin: 0 auto;
        padding-top:47px;
        padding-bottom: 60px;
        position: relative;
    }
    .jies{
        line-height: 30px;
        text-indent: 2em;
    }
    .cont{
        width: 525px;
        color: #666;
    }
    .contT{
        color: #247CD6;
        font-size: 28px;
        margin-top: 50px;
        margin-bottom: 20px;
    }
    .contT+p{
        line-height: 36px;
    }
    .contLeft{
        width:451px;
        color: #666;
    }
    .contRight{
        width:553px;
        color: #666;
        margin-top: 20px;
    }
    .bImg1{
        width: 514px;
        height: 229px;
        margin-right: 77px;
        margin-top: 26px;
    }
    .bImg2{
        width: 708px;
        height: 293px;
    }
    .bImg3{
        width: 747px;
        height: 278px;
        margin-right: -72px;
        margin-top: 20px;
    }
    .bImg4{
        width: 566px;
        height: 399px;
        margin-left: 22px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .bImg5{
        width:629px;
        height: 353px;
        margin-top: 25px;
    }
    .bImg6{
        width:547px;
        height: 325px;
        margin-left: 31px;
    }
    .bImg7{
        width: 620px;
        height: 285px;
        margin-top:30px;
    }
    .bImg8{
        width: 523px;
        height: 399px;
        margin-top: 14px;
    }
    .shows{
        width: 1200px;
        margin: 0 auto;
        padding: 37px 0;
        line-height: 36px;
        text-indent: 2em;
        color: #333;
    }
</style>