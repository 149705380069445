// 该文件专门用于创建整个应用的路由器
import VueRouter from 'vue-router'
//引入组件
import Layout from '../layout/Index'
// 首页
import Index from '../views/index/Index'
// //关于瑞和
// import About from '../views/aboutUs/Index'
// 通用布局盒子
import CommonLayout from '../layout/CommonLayout'
// 走进瑞和
// 公司简介
import Introduction from '../views/aboutUs/Introduction'
// 企业文化
import CorporateCulture from '../views/aboutUs/CorporateCulture'
// 战略会列表
import Stratage from '../views/aboutUs/Stratage'
// 战略会详情
import StratageInfo from '../views/aboutUs/StratageInfo'
//奖项与荣誉
import AwardsAndHonors from '../views/aboutUs/AwardsAndHonors'
//社会价值
import SocialValue from '../views/aboutUs/SocialValue'
//社会价值更多列表
import SocialValueList from '../views/aboutUs/SocialValueList'
//社会价值详情
import SocialValueInfo from '../views/aboutUs/SocialValueInfo'
//党工妇团
import workWomenCorps from '../views/aboutUs/workWomenCorps'
//分支机构
import Branch from '../views/aboutUs/Branch'
// 业务范围
// 前期咨询
import EarlierConsultation from '../views/businessScope/EarlierConsultation'
// 造价咨询
import CostConsultation from '../views/businessScope/CostConsultation'
// 招标代理
import BiddingAgent from '../views/businessScope/BiddingAgent'
// 项目管理
import ProjectManagement from '../views/businessScope/ProjectManagement'
// 全咨业务
import ConsultationBusiness from '../views/businessScope/ConsultationBusiness'
// 数字化应用应用
import BIMApplication from '../views/businessScope/BIMApplication'


// 人力资源
// 人才招聘
import Job from '../views/humanResources/Job'
// 在线写简历
import Resume from '../views/humanResources/Resume'
// 团队活动
import GroupActivities from '../views/humanResources/GroupActivities'

// 合作共赢
import cooperate from '../views/cooperation/Index'
// 媒体中心
// 行业资讯
import IndustryNews from '../views/mediaCenter/IndustryNews'
import IndustryNewsDetails from '../views/mediaCenter/IndustryNewsDetails'
// 企业动态
import CompanyNews from '../views/mediaCenter/CompanyNews'
import CompanyNewsDetails from '../views/mediaCenter/CompanyNewsDetails'
// 公告
import Notice from '../views/mediaCenter/Notice'
import NoticeDetails from '../views/mediaCenter/NoticeDetails'
// 公示
import Public from '../views/mediaCenter/Public'
import PublicDetails from '../views/mediaCenter/PublicDetails'
// 澄清与修改
import Clarify from '../views/mediaCenter/Clarify'
import ClarifyDetails from '../views/mediaCenter/ClarifyDetails'
// 公众参与公示
import NoticePub from '../views/mediaCenter/NoticePub'
import NoticePubDetails from '../views/mediaCenter/NoticePubDetails'


//联系我们
//联系方式
import ContactUs from '../views/linkUs/ContactUs'
//反馈中心
import FeedBack from '../views/linkUs/Feedback'

//创建并暴露一个路由器
export default new VueRouter({
	mode:'history',
	routes:[
		{
			path: '/',//主要列表
			redirect: '/index',
		},
		{
			path:'/',  //首页
			component:Layout,
			children:[
				{
					path:'index',
					component:Index,
				}
			]
		},
		{
			path:'/aboutUs', //关于瑞和
			component:CommonLayout,
			children: [
				{
					path:'CorporateCulture',  //企业文化
					component:CorporateCulture,
					meta: {
						title: 'aTitle',
						bannerBg: 'banner001',
						desc: 'adesc',
					}
				},
				{
					path:'Stratage',  //战略会列表
					component:Stratage,
					meta: {
						title: 'aTitle',
						bannerBg: 'banner001',
						desc: 'adesc',
					}
				},
				{
					path:'StratageInfo',  //战略会详情
					component:StratageInfo,
					meta: {
						title: 'aTitle',
						bannerBg: 'banner001',
						desc: 'adesc',
					}
				},
				{
					path:'Introduction', //公司简介
					component:Introduction,
					meta: {
						title: 'aTitle',
						bannerBg: 'banner002',
						desc: 'adesc',
					}
				},
				{
					path:'AwardsAndHonors', //奖项与荣誉
					component:AwardsAndHonors,
					meta: {
						title: 'aTitle',
						bannerBg: 'banner003',
						desc: 'adesc',
					}
				},		
				{
					path:'SocialValue', //社会价值
					component:SocialValue,
					meta: {
						title: 'aTitle',
						bannerBg: 'banner004',
						desc: 'adesc',
					}
				},
				{
					path:'SocialValueList', //社会价值更多列表
					component:SocialValueList,
					meta: {
						title: 'aTitle',
						bannerBg: 'banner004',
						desc: 'adesc',
					}
				},	
				{
					path:'socialValueInfo', //社会价值详情
					component:SocialValueInfo,
					meta: {
						title: 'aTitle',
						bannerBg: 'banner004',
						desc: 'adesc',
					}
				},	
				{
					path:'workWomenCorps', //党工妇团
					component:workWomenCorps,
					meta: {
						title: 'dTitle',
						bannerBg: 'banner005',
						desc: 'ddesc',
					}
				},
				{
					path:'Branch', //分支机构
					component:Branch,
					meta: {
						title: 'aTitle',
						bannerBg: 'banner006',
						desc: 'adesc',
					}
				},
			]
		},
		{
			path:'/businessScope',
			component:CommonLayout,
			redirect: '/businessScope/earlierConsultation',
			children: [
				{
					path:'earlierConsultation',
					component:EarlierConsultation,
					meta: {
						title: '前期咨询',
						bannerBg: 'banner001',
						color: '#253A87',
						id:"100140"
					}
				},
				{
					path:'costConsultation',
					component:CostConsultation,
					meta: {
						title: '造价咨询',
						bannerBg: 'banner002',
						color: '#253A87',
						id:"100137"
					}
				},
				{
					path:'biddingAgent',
					component:BiddingAgent,
					meta: {
						title: '招标代理',
						bannerBg: 'banner003',
						color: '#253A87',
						id:"100136"
					}
				},
				{
					path:'projectManagement',
					component:ProjectManagement,
					meta: {
						title: '管理咨询',
						bannerBg: 'banner004',
						color: '#253A87',
						id:"100138"
					}
				},
				{
					path:'consultationBusiness',
					component:ConsultationBusiness,
					meta: {
						title: '全咨业务',
						bannerBg: 'banner005',
						color: '#253A87',
						id:"100142"
					}
				},
				{
					path:'BIMApplication',
					component:BIMApplication,
					meta: {
						title: '数字化应用',
						bannerBg: 'banner006',
						color: '#253A87',
						id:"100144"
					}
				},
			]
		},
		{
			path:'/humanResources',
			component:CommonLayout,
			children:[
				{
					path:'job',
					component:Job,
					name: 'job',
					meta: {
						title: "人才招聘",
						bannerBg: 'banner007',
						color: '#253A87'
					}
				},
				{
					path:'resume',
					component:Resume,
					meta: {
						title: "我的简历",
						bannerBg: 'banner007',
						color: '#253A87'
					}
				},
				{
					path:'groupActivities',
					component:GroupActivities,
					meta: {
						title: "团队活动",
						bannerBg: 'banner007',
						color: '#253A87'
					}
				}
			]
		},
		{
			path:'/cooperate',
			component:CommonLayout,
			children:[
				{
					path:'index',
					component:cooperate,
					meta: {
						title: "合作共赢",
						bannerBg: 'banner008',
						color: '#253A87'
					}
				},
			]
		},
		{
			path:'/mediaCenter',
			component:CommonLayout,
			children:[
				{
					path:'industryNews',
					component:IndustryNews,
					meta: {
						title: "行业资讯",
						bannerBg: 'banner009',
						color: '#253A87'
					}
				},
				{
					path:'IndustryNewsDetails',
					component:IndustryNewsDetails,
					meta: {
						title: "行业资讯详情",
						bannerBg: 'banner009',
						color: '#253A87'
					}
				},
				{
					path:'CompanyNews',
					component:CompanyNews,
					meta: {
						title: "企业动态",
						bannerBg: 'banner009',
						color: '#253A87'
					}
				},
				{
					path:'CompanyNewsDetails',
					component:CompanyNewsDetails,
					meta: {
						title: "企业动态详情",
						bannerBg: 'banner009',
						color: '#253A87'
					}
				},
				{
					path:'Notice',
					component:Notice,
					meta: {
						title: "公告",
						bannerBg: 'banner009',
						color: '#253A87'
					}
				},
				{
					path:'NoticeDetails',
					component:NoticeDetails,
					meta: {
						title: "公告详情",
						bannerBg: 'banner009',
						color: '#253A87'
					}
				},
				{
					path:'Public',
					component:Public,
					meta: {
						title: "公示",
						bannerBg: 'banner009',
						color: '#253A87'
					}
				},
				{
					path:'PublicDetails',
					component:PublicDetails,
					meta: {
						title: "公示详情",
						bannerBg: 'banner009',
						color: '#253A87'
					}
				},
				{
					path:'Clarify',
					component:Clarify,
					meta: {
						title: "澄清与修改",
						bannerBg: 'banner009',
						color: '#253A87'
					}
				},
				{
					path:'ClarifyDetails',
					component:ClarifyDetails,
					meta: {
						title: "澄清与修改详情",
						bannerBg: 'banner009',
						color: '#253A87'
					}
				},
				{
					path:'NoticePub',
					component:NoticePub,
					meta: {
						title: "公众参与公示",
						bannerBg: 'banner009',
						color: '#253A87'
					}
				},
				{
					path:'NoticePubDetails',
					component:NoticePubDetails,
					meta: {
						title: "公众参与公示详情",
						bannerBg: 'banner009',
						color: '#253A87'
					}
				}
			]
		},
		{
			path:'/linkUs',
			component:CommonLayout,
			children:[
				{
					path:'ContactUs',
					component:ContactUs,
					meta: {
						title: "联系方式",
						bannerBg: 'banner001',
						color: '#253A87'
					}
				},
				{
					path:'FeedBack',
					component:FeedBack,
					meta: {
						title: "反馈中心",
						bannerBg: 'banner002',
						color: '#253A87'
					}
				}
			]
		}
	]
})
