<template>
    <div class="corporateCultureBox">
        <aboutBannerVue tabIndex="1"></aboutBannerVue>
        <div class="socialInfo" v-if="id">
            <p class="title">{{info.title}}</p>
            <div class="contents" v-html="info.content"></div>
        </div>
        <div class="socialInfo" v-else>
           <p class="titles">2022年战略会</p>
           <div class="contBox">
            <p>第一章 总 则 第一条 为规范雄安新区工程建设项目招标投标活动，保护国家利益、社会公共利益和招标投标活动当事人的合法权益，提高资源配置效率，创造“雄安质量”，建设“廉洁雄安”，根据《中华人民共和国招标投标法》（以下简称《招标投标法》）、《中华人民共和国招标投标法实施条例》（以下简称《实施条例》）、《电子招标投标办法》等有关法律、法规、规章，结合雄安新区实际，制定本办法。</p>
            <p>第二条 雄安新区范围内工程建设项目的招标投标及其管理活动，适用本办法。</p>
            <p>第三条 雄安新区范围内工程建设项目的招标投标活动，遵循公开、诚信、高效和择优的原则。</p>
            <p>第四条 雄安新区公共服务局是雄安新区工程建设项目招标投标活动的主管部门，负责招标投标活动的指导协调和综合监督。雄安新区综合执法局依法对招标投标活动中的违法违规行为进行查处。审计机关对使用财政性资金的项目实行全过程跟踪审计。</p>
            <p>第五条 加强招标投标信息化建设，推行全程电子化招标投标。</p>
            <p>第六条 实行全流程透明化管理，除涉及国家秘密、商业秘密和个人隐私的内容外，招标投标信息全部公开。</p>
            <p>第七条 在招标投标活动中，全面推行建筑信息模型（BIM）、城市信息模型（CIM）技术，实现工程建设项目全生命周期管理。</p>
            <p>第八条 创新招标投标体制机制，建立招标投标信用体系，提高工作效率，降低社会成本，保障工程质量，做到廉洁诚信。</p>
            <div class="clearfix mt20">
                <img class="zhanImg" src="@/assets/aboutUs/culture/zhan1.png" alt="">
                <img class="zhanImgs" src="@/assets/aboutUs/culture/zhan2.png" alt="">
                <img class="zhanImgs mr17" src="@/assets/aboutUs/culture/zhan3.png" alt="">
                <img class="zhanImgs mr17" src="@/assets/aboutUs/culture/zhan4.png" alt="">
                <img class="zhanImgs" src="@/assets/aboutUs/culture/zhan5.png" alt="">
            </div>
            <p>第二章 招标准备</p>
            <p>第九条 对于符合《雄安新区建设项目投资审批改革试点方案》规定且列入雄安新区立项计划的项目，满足招标相关技术条件的，招标人可以先行招标。招标完成后，未完成其他必要审批、核准手续的，不得开展后续活动。</p>
            <p>招标人先行招标的，应当报雄安新区公共服务局批准，并提交风险承诺，自行承担因项目立项、规划条件等发生变化而导致招标失败的风险。</p>
            <p>第十条 下列依法必须招标的项目，应当公开招标：</p>
           </div>
        </div>
    </div>
</template>

<script>
import aboutBannerVue from '../../components/aboutBanner'
	export default {
		name:'CorporateCulture',
        components:{aboutBannerVue},
        data() {
            return {
                info:'',
                id:''
            }
        },
        mounted(){
            if(this.$route.query.id){
                this.id = this.$route.query.id
                this.getInfo()
            }
        },
       methods:{
         //获取行业资讯详情
         getInfo(){
            this.Request({
                url: '/webindex/news/newsDetail',
                data: {
                    id: this.$route.query.id
                },
                method: 'get',
            }).then((data) => {
                this.info = data.tdata
            })
        }
       }
	}
</script>
<style scoped>
    .socialInfo{
        width: 1200px;
        margin: 42px auto;
        padding: 30px 40px 40px 40px;
        box-shadow: 0px 0px 10px 0px rgba(36,124,214,0.15);
    }
    .title{
        font-size: 24px;
        font-weight: 400;
        padding-bottom: 30px;
        margin-bottom: 27px;
        border-bottom: 1px solid #E8E8E8;
    }
    .map{
        width: 520px;
        height: 367px;
    }
    .contents{
        margin-top: 64px;
    }
    .contents img{
        max-width: 100%;
    }
    .titles {
        font-size: 24px;
        font-weight: 400;
        padding-bottom: 30px;
        margin-bottom: 27px;
        padding-top: 20px;
        text-align: center;
    }
    .contBox{
        line-height: 36px;
        font-size: 16px;
        color: #666;
    }
    .zhanImg{
        float: left;
        width: 736px;
        height: 232px;
        margin-right: 16px;
        margin-bottom: 17px;
    }
    .zhanImgs{
        float: left;
        width: 359px;
        height: 232px;
        margin-bottom: 17px;
        /* margin-right: 17px; */
    }
    .mr17{
        margin-right: 17px;
    }
</style>