<template>
    <div class="introductionBox">
        <cooperation></cooperation>
        <div class="cooperBox">
            <div class="youBox">
                <div class="fl youLeft">
                    <div :class="tabIndex==index?'active':'moren'" v-for="(item,index) in list" :key="index" @click="changState(index)">
                        <span>{{item.title}}</span>
                        <img class="leftBg" v-if="tabIndex==index" src="@/assets/cooperate/leftBg.png" alt="">
                    </div>
                </div>
                <div class="fr youRight">
                    <div class="tabC1" v-if="tabIndex==0">
                        <p>瑞和安惠项目管理集团创建于2000年。</p>
                        <p>集PPP咨询、前期咨询、造价咨询、招标采购、管理咨询、BIM应用及IPMP项目管理培训于一体的全过程工程咨询企业。</p>
                        <p>凭借多项甲级资质以及成熟的信息化技术，构建了完整的全过程工程咨询业务链。</p>
                    </div>
                    <div class="" v-if="tabIndex==1">
                        <div class="tabC2">
                            <div class="monBox">
                                <p class="money">5000万元</p>
                                <p class="desc">注册资金</p>
                            </div>
                            <div class="monBox">
                                <p class="money">240余人</p>
                                <p class="desc">各类注册师</p>
                            </div>
                            <div class="monBox">
                                <p class="money">200余人</p>
                                <p class="desc">中高级职称</p>
                            </div>
                        </div>
                        <div class="tabC2">
                            <div class="monBox">
                                <p class="money">40余个</p>
                                <p class="desc">分支机构</p>
                            </div>
                            <div class="monBox">
                                <p class="money">5000余项</p>
                                <p class="desc">年均项目</p>
                            </div>
                            <div class="monBox">
                                <p class="money">超2亿元</p>
                                <p class="desc">年总产值</p>
                            </div>
                        </div>
                    </div>
                    <div class="tabC1" v-if="tabIndex==2">
                        <p>20多年风雨兼程、拼搏进取，瑞和安惠秉承“诚信至高处、服务无限时”的服务宗旨；</p>
                        <p>形成了总计 93 项的产品体系：<br>其中包括前期咨询 70 项；招标咨询 6 项；造价咨询  7 项；管理咨询 4  项； BIM咨询 6 项 。</p>
                    </div>
                    <div class="tabC1" v-if="tabIndex==3">
                        <p>前期咨询： <br>中国工程咨询协会核发的综合甲级资信、①建筑，②电子、信息工程（含通信、广电、信息化）， ③石化、化工、医药，④机械（含智能制造），⑤轻工、纺织， ⑥市政工程等六个专业甲级资信；<br>①生态建设工程和环境工程，②农业、林业，③水利水电，④电力（含火电、水电、核电、新能源），⑤建材， ⑥其他（节能）等六项专业乙级资信；</p>
                        <p>PPP咨询：中国工程咨询协会核发的政府和社会资本合作（PPP）咨询专项甲级资信；</p>
                        <p>造价咨询：中华人民共和国住房和城乡建设部核发的工程造价咨询甲级资质；</p>
                        <p>招标采购：建设工程、政府采购、中央投资、国际招标等招标代理甲级资质；</p>
                        <p>工程监理：中华人民共和国住房和城乡建设部核发的房屋建筑工程监理甲级资质及河北省住房和城乡建设厅核发的市政公用工程监理乙级资质。</p>
                    </div>
                    <div class="tabC1" v-if="tabIndex==4">
                        <p>专业人才团队，行业专家保障；</p>
                        <p>瑞和安惠拥有结构合理、学历高、技术水平强、经验丰富的工程 咨询职业化技术团队，<br>并有一支由近200名省内各学科知名学者和工程技术人员组成的专家队伍；</p>
                        <p>各类注册师240余人：<br>其中 注册一级造价师 30余人， 注册咨询师 50余 人， 各专业均配备齐全；</p>
                        <p>中高级职称200余人，以上注册类人员均有中高级以上职称。</p>
                    </div>
                    <div class="tabC3" v-if="tabIndex==5">
                        <div>河北省诚信共建企业</div>
                        <div>2023年度市信用协会信用评价3A</div>
                        <div>河北省守合同重信用企业</div>
                        <div>省信用协会信用评价AAA等级证书</div>
                        <div>河北省AAA级信用企业</div>
                        <div>质量、安全、职业健康管理体系证书（2023）</div>
                        <div>石家庄市守合同重信用企业</div>
                        <div>中国建设工程造价管理协会3A信用等级证书</div>
                        <div>石家庄市工商守合同重信用企业</div>
                        <div>石家庄市工商行政管理局重合同守信用企业</div>
                        <div>河北省计价信用承诺评选AAAAA级单位</div>
                        <div>2021年中招协3A</div>
                    </div>
                    <div class="tabC4" v-if="tabIndex==6">
                        <div><span class="yuand"></span> 出版“工程咨询理论与实践研究系列丛书”：</div>
                        <p class="pl20">电子招标投标系统研究与实践</p>
                        <p class="pl20">工程咨询企业项目管理办公室（PMO）理论与实践</p>
                        <p class="pl20">工程咨询企业信息化管理实务</p>
                        <p class="pl20">工程总承包管理理论实务</p>
                        <p class="pl20">建设项目全过程投资控制理论与操作指南</p>
                        <p class="pl20">企业项目化管理理论与实践</p>
                        <p class="pl20">全过程工程咨询理论与实践指南</p>
                        <p class="pl20">政府和社会资本合作（ppp）项目绩效评价实施指南</p>
                        <p class="pl20">政府和社会资本合作（ppp）项目实施指南</p>
                        <p class="pl20">重大投资项目社会稳定风险评估研究与实践</p>
                        <div class="mt20"><span class="yuand"></span> 编制“全过程工程咨询（PMC）管理办法”</div>
                        <div class="mt20"><span class="yuand"></span> 参编多项国家级及省级标准</div>
                    </div>
                    <div class="" v-if="tabIndex==7">
                        <div class="tabC2">
                            <div>
                                <img src="@/assets/cooperate/baoz1.png" alt="">
                                <p>专业的服务团队</p>
                            </div>
                            <div>
                                <img src="@/assets/cooperate/baoz2.png" alt="">
                                <p>强大的技术支持</p>
                            </div>
                            <div>
                                <img src="@/assets/cooperate/baoz3.png" alt="">
                                <p>快速的响应机制</p>
                            </div>
                        </div>
                        <div class="tabC2">
                            <div>
                                <img src="@/assets/cooperate/baoz4.png" alt="">
                                <p>开放的资源共享</p>
                            </div>
                            <div>
                                <img src="@/assets/cooperate/baoz5.png" alt="">
                                <p>迅捷的数字赋能</p>
                            </div>
                            <div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <p>在这个领域我们深耕多年，积累了丰富的经验和专业技术，形成了独特的竞争优势。我们希望与您建立战略合作关系，共同发展壮大。</p>
                <p>首先，我们的公司非常重视合作伙伴的资源整合和效率提升。我们相信，与有志者共事，一定会有好的成果，互利共赢。因此，我们争取与您建立长期、稳定的合作关系。我们相信您的公司在该领域内拥有独特的竞争力，我们希望能够与您一起将这种竞争力转化为实际的合作项目，实现共赢。</p>
                <p>其次，我们的公司拥有一支领先行业的专业团队，具有丰富的实践经验和先进的工作思维。公司一直秉承“诚信至高处，服务无限时”的管理理念，真诚期待您的深入合作，共谋发展，共创辉煌！</p>
            </div>
            <div class="linkBox">
                <div class="">
                    <p class="font18 fontw">SO，我们牵手吧~</p>
                    <div class="clearfix">
                        <img class="ewm1 fl" src="@/assets/cooperate/ewm1.png" alt="">
                        <img class="ewm2 fr" src="@/assets/cooperate/ewm2.png" alt="">
                    </div>
                </div>
                <div class="">
                    <p class="font18 fontw">联系我们</p>
                    <div class="linkInfo">
                        <p>总机：0311-69052012</p>
                        <p>合作热线：18303114488   15383838810</p>
                        <p>网址：www.ruihepm.com</p>
                        <p>E-mail：2771514159@qq.com</p>
                        <p>地址：石家庄市建设南大街269号师大科技园B座11、12层</p>
                    </div>
                </div>
            </div>
            <div class="linkUs">
                <el-form class="formBox" ref="form" :model="form" label-width="120px" :inline="true" :rules="rules">
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="form.name" style="width:335px"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="phone" >
                        <el-input v-model="form.phone" style="width:335px" maxlength="11"></el-input>
                    </el-form-item>
                    <el-form-item label="给我们留言" prop="content">
                        <el-input v-model="form.content" type="textarea" class="wby" :rows="6" resize="none" maxlength="500" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
                <div class="subBtn">
                    <el-button @click="submitData('form')">提交</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import cooperation from '@/components/cooperation'
	export default {
		name:'Collaboration',
        components:{cooperation},
        data() {
            return {
                tabIndex:0,
                list:[
                    {
                        title:'全过程咨询优势'
                    },
                    {
                        title:'公司规模'
                    },
                    {
                        title:'业务产品体系优势'
                    },
                    {
                        title:'资质资信优势'
                    },
                    {
                        title:'人员优势'
                    },
                    {
                        title:'企业信誉优势'
                    },
                    {
                        title:'知识体系优势'
                    },
                    {
                        title:'服务保障'
                    }
                ],
                form:{
                    name:'',
                    phone:'',
                    content:'',
                    formtype:'leaveword'
                },
                rules:{
                    name:{
                        required:true,
                        message:'请输入姓名',
                        trigger:'blur'
                    },
                    phone:{
                        required:true,
                        validator: this.mobileValid,
                        trigger:'blur'
                    },
                    content:{
                        required:true,
                        message:'请输入留言',
                        trigger:'blur'
                    },

                }
            }
        },
        mounted() {
            
        },
        methods:{
           // 手机号码校验
            mobileValid(rule, value, callback) {
                if (!(/^1[3456789]\d{9}$/.test(value))) {
                    callback(new Error('请输入正确手机号码'));
                    this.isgetverifycode = false;
                } else {
                    this.isgetverifycode = true;
                    callback();
                }
            },
            //切换分类
            changState(index){
                this.tabIndex= index
            },
            // 提交留言
            submitData(form){
                this.$refs[form].validate((valid)=>{
                    if(valid){
                        this.Request({
                            url: '/webindex/msgFeedback/add',
                            method:'post',
                            data:this.form
                        }).then((data) => {
                            if(data.code=='v'){
                                this.$message.success(data.msg)
                                this.$refs[form].resetFields();
                           }
                        })
                    }
                })
            }
        }
	}
</script>

<style scoped>
    .cooperBox{
        width: 1200px;
        margin: 0 auto;
        margin-top: 47px;
        padding-bottom: 47px;
    }
    .youBox{
        width: 1200px;
        height: 625px;
        background-color: #066EFC;
        border-radius: 10px;
        padding: 44px 53px 41px 52px;
    }
    .youLeft{
        width: 241px;
        height: 540px;
        background-color: #3B88FC;
    }
    .active{
        color: #004FFF;
        position: relative;
        height: 66px;
        line-height: 66px;
        padding-left: 23px;
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
    }
    .active span{
        position: absolute;
        top: 0;
        left: 23px;
        width: 241px;
        height: 66px;
        z-index: 5;
    }
    .leftBg{
        position: absolute;
        top: 0;
        left: 0;
        width: 276px;
        height: 66px;
        z-index: 1;
    }
    .moren{
        color: #fff;
        border-bottom: 1px dotted #73AAFC;
        line-height: 66px;
        padding-left: 23px;
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
    }
    .moren:last-child{
        border: none;
    }
    .moren:hover{
        color: #004FFF;
    }
    .youRight{
        width: 802px;
        height: 540px;
        background: url('../../assets/cooperate/rightBg.png');
        background-size: 100% 100%;
        padding: 41px 51px 41px 47px;
    }
    .tabC1{
        font-weight: bold;
        line-height: 30px;
    }
    .tabC1 p{
        margin-bottom: 20px;
    }
    .tabC2{
        display: flex;
        justify-content: space-between;
        margin-bottom: 49px;
    }
    .tabC2 div{
        width: 120px;
        font-weight: bold;
    }
    .tabC2 img{
        width: 100px;
        height: 100px;
        margin-bottom: 15px;
    }
    .tabC3{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .tabC3 div{
        width: 50%;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .tabC4{
        font-weight: bold;
    }
    .tabC4 p{
        margin-top: 10px;
        padding-left: 26px;
    }
    .yuand{
        float: left;
        margin-top: 3px;
        margin-right: 10px;
        width: 16px;
        height: 16px;
        background: #066EFC;
        border-radius: 50%;
    }
    .monBox{
        width: 120px;
    }
    .money{
        font-size: 24px;
        color: #066EFC;
        margin-bottom: 10px;
    }
    .content{
        margin-top: 36px;
        color: #333;
        line-height: 30px;
        /* text-indent: 2em; */
    }
    .content p{
        margin-bottom: 30px;
    }
    .linkBox{
        width: 860px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }
    .linkInfo{
        margin-top: 14px;
        color: #333;
        line-height: 30px;
    }
    .ewm1{
        width: 167px;
        height: 165px;
        margin-top: 8px;
        margin-left: -27px;
        margin-right:35px;
    }
    .ewm2{
        width: 174px;
        height: 179px;
    }
    .linkUs{
        width: 1200px;
        height: 387px;
        box-shadow: 0px 0px 7px 0px rgba(36,124,214,0.16);
        padding-top: 50px;
        margin-top: 33px;
        /* padding-left:150px; */
    }
    .formBox{
        width: 1000px;
        margin: 0 auto;
    }
    .wby{
        width: 800px;
    }
    .subBtn{
        width: 120px;
        margin: 0 auto;
        margin-top: 10px;
    }
    .subBtn button{
        width: 120px;
        height: 48px;
        background: #247CD6;
        color: #fff;
    }
</style>
