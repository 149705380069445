<template>
    <div class="introductionBox">
       <humanResources tabIndex="2"></humanResources>
       <div class="humanBox">
            <div class="jobBox">
               <el-image v-for="(item,index) in imgList" :key="index" class="imgs" :class="'imgs'+(index+1)" :src="item" alt="" :preview-src-list="imgList" fit="cover" />
            </div>
       </div>
    </div>
</template>

<script>
import humanResources from '../../components/humanResources'
	export default {
		name:'EarlierConsultation',
        components:{humanResources},
        data() {
            return {
                imgList:[
                    require('@/assets/humanResources/team/1.jpg'),
                    require('@/assets/humanResources/team/2.jpg'),
                    require('@/assets/humanResources/team/3.jpg'),
                    require('@/assets/humanResources/team/4.jpg'),
                    require('@/assets/humanResources/team/5.jpg'),
                    require('@/assets/humanResources/team/6.jpg'),
                    require('@/assets/humanResources/team/7.jpg'),
                    require('@/assets/humanResources/team/8.jpg'),
                    require('@/assets/humanResources/team/9.jpg'),
                    require('@/assets/humanResources/team/10.jpg'),
                    require('@/assets/humanResources/team/11.jpg'),
                    require('@/assets/humanResources/team/12.jpg'),
                    require('@/assets/humanResources/team/13.jpg'),
                    require('@/assets/humanResources/team/14.jpg'),
                    require('@/assets/humanResources/team/15.jpg'),
                    require('@/assets/humanResources/team/16.jpg'),
                    require('@/assets/humanResources/team/17.jpg'),
                    require('@/assets/humanResources/team/18.jpg'),
                    require('@/assets/humanResources/team/19.jpg'),
                    require('@/assets/humanResources/team/20.jpg'),
                    require('@/assets/humanResources/team/21.jpg'),
                    require('@/assets/humanResources/team/22.jpg'),
                    require('@/assets/humanResources/team/23.jpg'),
                    require('@/assets/humanResources/team/24.jpg'),
                    require('@/assets/humanResources/team/25.jpg'),
                    require('@/assets/humanResources/team/26.jpg'),
                    require('@/assets/humanResources/team/27.jpg'),
                    require('@/assets/humanResources/team/28.jpg'),
                    require('@/assets/humanResources/team/29.jpg'),
                    require('@/assets/humanResources/team/30.jpg'),
                    require('@/assets/humanResources/team/31.jpg'),
                    require('@/assets/humanResources/team/32.jpg'),
                    require('@/assets/humanResources/team/33.jpg'),
                    require('@/assets/humanResources/team/34.jpg'),
                    require('@/assets/humanResources/team/35.jpg'),
                    require('@/assets/humanResources/team/36.jpg'),
                    require('@/assets/humanResources/team/37.jpg'),
                    require('@/assets/humanResources/team/38.jpg'),
                    require('@/assets/humanResources/team/39.jpg'),
                    require('@/assets/humanResources/team/40.jpg'),
                    require('@/assets/humanResources/team/41.jpg'),
                    require('@/assets/humanResources/team/42.jpg'),
                    require('@/assets/humanResources/team/43.jpg'),
                    require('@/assets/humanResources/team/44.jpg'),
                    require('@/assets/humanResources/team/45.jpg'),
                    require('@/assets/humanResources/team/46.jpg'),
                    require('@/assets/humanResources/team/47.jpg'),
                    require('@/assets/humanResources/team/48.jpg'),
                    require('@/assets/humanResources/team/49.jpg'),
                    require('@/assets/humanResources/team/50.jpg'),
                    require('@/assets/humanResources/team/51.jpg')
                ]
            }
          },
        mounted() {
           
        },
        methods:{
        }
	}
</script>

<style scoped>
   .humanBox{
       background-color: #F7F8F9;
       padding-top: 42px;
   }
   .jobBox{
          /* width: 1500px;
          height: 977px; */
        width: 1200px;
        height: 782px;
        margin: 0 auto;
        position: relative;
        background: url('../../assets/humanResources/teamBg.png');
        background-size: 100% 100%;
   }
   .imgs{
        position: absolute;
        background-color: #fff;
   }
   .imgs1{
        top: 25px;
        left: 108px;
        width: 80px;
        height: 50px;
   }
   .imgs2{
        top: 25px;
        left: 200px;
        width: 80px;
        height: 50px;
   }
   .imgs3{
        top: 88px;
        left: 62px;
        width: 75px;
        height: 57px;
   }
   .imgs4{
        top: 87px;
        left: 134px;
        width: 133px;
        height: 85px;
   }
   .imgs5{
        top: 51px;
        left: 264px;
        width: 125px;
        height: 72px;
        z-index: 2;
   }
   .imgs6{
        top: 154px;
        left: 48px;
        width: 50px;
        height: 50px;
        z-index: 2;
   }
   .imgs7{
        top: 178px;
        left: 84px;
        width: 78px;
        height: 79px;
   }
   .imgs8{
        top: 177px;
        left: 180px;
        width: 155px;
        height: 99px;
        z-index: 3;
   }
   .imgs9{
        top: 104px;
        left: 271px;
        width: 167px;
        height: 108px;
   }
   .imgs10{
        top: 269px;
        left: 101px;
        width: 50px;
        height: 50px;
   }


   .imgs11{
        top: 250px;
        left: 160px;
        width: 51px;
        height: 50px;
        z-index: 4;
   }
   .imgs12{
        top: 275px;
        left: 232px;
        width: 79px;
        height: 50px;
   }
   .imgs13{
        top: 269px;
        left: 319px;
        width: 51px;
        height: 51px;
   }
   .imgs14{
        top: 328px;
        left: 128px;
        width: 78px;
        height: 50px;
   }
   .imgs15{
        top: 318px;
        left: 214px;
        width: 75px;
        height: 72px;
   }
   .imgs16{
        top: 334px;
        left: 296px;
        width: 129px;
        height: 83px;
   }
   .imgs17{
        top: 404px;
        left: 210px;
        width: 78px;
        height: 50px;
   }
   .imgs18{
        top: 427px;
        left: 363px;
        width: 78px;
        height: 50px;
   }
   .imgs19{
        top: 428px;
        left: 480px;
        width: 131px;
        height: 83px;
   }
   .imgs20{
        top: 413px;
        left: 608px;
        width: 131px;
        height: 83px;
   }


   .imgs21{
        top: 411px;
        left: 740px;
        width: 78px;
        height: 49px;
   }
   .imgs22{
        top: 330px;
        left: 438px;
        width: 98px;
        height: 83px;
   }
   .imgs23{
        top: 329px;
        left: 554px;
        width: 87px;
        height: 83px;
   }
   .imgs24{
        top: 302px;
        left: 649px;
        width: 178px;
        height:115px;
   }
   .imgs25{
        top: 205px;
        left: 369px;
        width: 178px;
        height: 114px;
   }
   .imgs26{
        top: 261px;
        left: 543px;
        width: 107px;
        height: 68px;
        z-index: 2;
   }
   .imgs27{
        top: 235px;
        left: 663px;
        width: 74px;
        height: 70px;
   }
   .imgs28{
        top: 193px;
        left: 507px;
        width: 79px;
        height: 48px;
        z-index: 2;
   }
   .imgs29{
        top: 198px;
        left:576px;
        width: 95px;
        height: 61px;
   }
   .imgs30{
        top: 136px;
        left: 433px;
        width: 101px;
        height: 64px;
   }

   .imgs31{
        top: 155px;
        left: 547px;
        width: 52px;
        height: 50px;
        z-index: 3;
   }
   .imgs32{
        top: 149px;
        left: 615px;
        width: 52px;
        height: 50px;
   }
   .imgs33{
        top: 126px;
        left: 673px;
        width: 61px;
        height: 99px;
   }
   .imgs34{
        top: 110px;
        left: 742px;
        width: 78px;
        height: 74px;
   }
   .imgs35{
        top: 25px;
        left: 877px;
        width:79px;
        height: 49px;
   }
   .imgs36{
        top: 25px;
        left: 968px;
        width:79px;
        height: 49px;
   }
   .imgs37{
        top: 84px;
        left: 824px;
        width: 99px;
        height: 62px;
   }
   .imgs38{
        top: 84px;
        left: 934px;
        width: 94px;
        height: 56px;
   }
   .imgs39{
        top: 88px;
        left: 1039px;
        width: 51px;
        height: 50px;
   }
   .imgs40{
        top: 149px;
        left: 843px;
        width: 79px;
        height: 49px;
   }

   .imgs41{
        top: 131px;
        left: 930px;
        width: 122px;
        height: 78px;
   }
   .imgs42{
        top: 149px;
        left: 1055px;
        width: 85px;
        height: 93px;
   }
   .imgs43{
        top: 198px;
        left: 728px;
        width: 128px;
        height: 81px;
   }
   .imgs44{
        top: 202px;
        left: 889px;
        width: 79px;
        height: 49px;
   }
   .imgs45{
        top: 202px;
        left: 980px;
        width: 79px;
        height: 49px;
        z-index: 2;
   }
   .imgs46{
        top: 247px;
        left: 822px;
        width: 128px;
        height: 81px;
   }
   .imgs47{
        top: 270px;
        left: 946px;
        width: 79px;
        height: 49px;
   }
   .imgs48{
        top: 261px;
        left: 1035px;
        width: 51px;
        height: 49px;
   }
   .imgs49{
        top: 338px;
        left:869px;
        width: 51px;
        height: 49px;
   }
   .imgs50{
        top: 338px;
        left: 957px;
        width: 77px;
        height: 49px;
   }
   .imgs51{
        top: 395px;
        left: 835px;
        width: 101px;
        height: 49px;
   }
   .imgs{
     transform: scale(0.8);
   }
   .imgs img{
        width: 100%;
        height: 100%;
   }
</style>