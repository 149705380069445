<template>
    <div class="introductionBox">
       <businessBanner tabIndex="5"></businessBanner>
       <div class="mainShow">
            <p class="font20 fontw">全咨业务</p>
            <p class="mt36 jies">随着《国务院办公厅关于促进建筑业持续健康发展的意见》（国办发〔2017〕19号）及《国家发展改革委住房城乡建设部关于推进全过程工程咨询服务发展的指导意见》（发改投资规（2019）515号）等政策文件相继出台，“1+N”全过程工程咨询模式逐步兴起。</p>
            <p class="jies">集团公司凭借超前的发展理念、多元化的人才储备以及完善的服务链条，以“领先的全过程工程咨询服务提供商”为愿景，经历数年时间的整合与发展，至今已打造了10余个全过程咨询业务团队，可为客户提供投资决策综合性咨询以及工程建设全过程咨询两种模式的全过程工程咨询服务。</p>
       </div>
       <div class="mainShows">
            <div class="main clearfix">
                <p class="contT">典型案例</p>
                <!-- <p class="contp">多年来，集团公司持续改进全咨业务流程，不断完善全咨业务体系，完成了很多影响广泛的全过程工程咨询项目。</p>
                <p class="contp">其中典型的案例有雄县城区雄州路雨污分流改造工程全过程咨询，服务范围包括项目管理、造价咨询；</p>
                <p class="contp">中国烟草总公司河北省公司信息质检中心全过程工程咨询，服务范围包括项目管理、造价咨询、招标代理；</p>
                <p class="contp">石家庄市第四十中学新校区改建项目全过程工程咨询，服务范围包括项目管理、工程设计、造价咨询、招标代理、BIM应用等。</p> -->
                <div class="yejiBox">
                    <div class="yejiShow">
                        <p class="fenlT">投资决策阶段全过程项目案例</p>
                        <div class="clearfix" v-for="(item,index) in list1" :key="index">
                            <span class="fl point"></span>
                            <p class="fl contp">{{item.title}}</p>
                        </div>
                    </div>
                    <div class="yejiShow">
                        <p class="fenlT">工程建设阶段全过程项目案例</p>
                        <div class="clearfix" v-for="(item,index) in list2" :key="index">
                            <span class="fl point"></span>
                            <p class="fl contp">{{item.title}}</p>
                        </div>
                    </div>
                </div>
            </div>
       </div>
    </div>
</template>

<script>
import businessBanner from '../../components/businessBanner'
	export default {
		name:'EarlierConsultation',
        components:{businessBanner},
        data() {
            return {
                list1:[
                    {
                        title:'润英联(中国)有限公司进料线及卸车平台、丙类仓库建设项目'
                    },
                    {
                        title:'润英联(中国)有限公司年产5.1万吨润滑油添加剂项目'
                    },
                    {
                        title:'亿恩科化工新材料(苏州)有限公司新建年产4万吨电解液项目'
                    },
                    {
                        title:'默克电子科技(张家港)有限公司年产165吨半导体前驱材料及150万升电子特气纯化分装及危险化学品仓储项目'
                    },
                    {
                        title:'石家庄市儿童福利院项目'
                    },
                    {
                        title:'张家口生活垃圾焚烧发电项目'
                    },
                    {
                        title:'张家口餐厨垃圾处理项目'
                    },
                    {
                        title:'张家口下花园区城区排水管网及雨水泵站改造工程'
                    },
                    {
                        title:'曲阳县七里庄片区排水设施建设工程项目'
                    },
                    {
                        title:'井陉县租赁住房（人才公寓）建设项目'
                    },
                    {
                        title:'曲阳县人民医院迁建项目'
                    },
                    {
                        title:'宁晋经济开发区盐化工园战新产业孵化基地项目'
                    },
                    {
                        title:'2021年灵寿县城中村棚户区改造（南岗村）一期项目'
                    },
                    {
                        title:'昌黎县城乡静脉产业园特许经营项目'
                    }
                ],
                list2:[
                    {
                        title:'石家庄市第四十中学新校区改建项目'
                    },
                    {
                        title:'枣强县中医医院救治能力提升工程'
                    },
                    {
                        title:'石家庄民政救灾物资储备中心项目'
                    },
                    {
                        title:'承德市总工会职工综合服务中心改造项目'
                    },
                    {
                        title:'唐山南堡职业教育中学项目一期工程'
                    },
                    {
                        title:'衡水滏阳文化公园新建项目'
                    },
                    {
                        title:'枣强县中医医院救治能力提升工程'
                    },
                    {
                        title:'阜平县中医医院医疗康复托老中心工程'
                    },
                    {
                        title:'唐山南湖西北片区回迁安置区项目'
                    },
                    {
                        title:'雄安新区雄县2019植树造林项目'
                    },
                    {
                        title:'安新县雁翎沟综合治理工程'
                    },
                    {
                        title:'峰峰矿区农村生活污水治理工程'
                    },
                    {
                        title:'峰峰矿区污水处理厂尾水生态净化治理与资源化利用工程'
                    },
                    {
                        title:'易地扶贫搬迁村旅游产业发展项目(店房村)项目'
                    },
                    {
                        title:'武邑县新汽车站建设项目'
                    }
                ]
            }
        },
        mounted() {
           
        }
	}
</script>

<style scoped>
    .mainShows{
        width: 100%;
        background-color: #F7F9FD;
        margin-top: 42px;
        
    }
    .main{
        width: 1200px;
        margin: 0 auto;
        padding: 48px 0;
        padding-bottom: 67px;
    }
    .jies{
        line-height: 30px;
        text-indent: 2em;
    }
    .contT{
        color: #247CD6;
        font-size: 28px;
        margin-bottom: 20px;
    }
    .yejiBox{
        display: flex;
        justify-content:space-between;
        margin-top: 25px;
    }
    .yejiShow{
        width: 42%;
    }
    .yejiShow div{
        margin-top: 24px;
    }
    .fenlT{
        font-size: 20px;
        color: #333;
    }
    .point{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #888888;
        margin-right: 10px;
        margin-top: 10px;
    }
    .contp{
        width: calc(100% - 20px);
        color: #666;
        font-size: 16px;
        line-height: 26px;
    }
</style>