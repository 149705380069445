<template>
    <div class="corporateCultureBox">
        <aboutBannerVue tabIndex="1"></aboutBannerVue>
        <!-- 战略会 -->
        <div class="tacticBox">
            <div class="tactic" v-for="(item,index) in meetList" :key="index" @click="detail(item.id)">
                <img class="tacticImg" :src="item.picture" alt="">
                <div class="tacticDesc clearfix">
                    <span class="fl">{{item.title}}</span>
                    <img class="fr" src="../../assets/aboutUs/culture/right.png" alt="">
                </div>
            </div>
        </div>
        <div class="" v-if="meetList.length==0">
            <el-empty description="暂无数据"></el-empty>
        </div>
        <el-pagination class="pageBox" v-if="meetList.length!=0" background @current-change="handleCurrentChange" :current-page.sync="currentPage" layout="prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
</template>

<style scoped>
   
</style>
<script>
import aboutBannerVue from '../../components/aboutBanner'
	export default {
		name:'CorporateCulture',
        components:{aboutBannerVue},
        data() {
            return {
                currentPage:1,
                pageindex:1,
                total:0,
                meetList:[]
            }
        },
        mounted(){
            this.getMeetInfo()
        },
        methods:{

             //查询战略会列表
             getMeetInfo(){
                this.Request({
                    url: '/webindex/zjrh/strategicMeetingList',
                    method: 'get',
                    data:{
                        pageindex:this.pageindex,
                        pagesize:10
                    }
                }).then((data) => {
                    if(data.rows && data.rows.length > 0){
                        this.meetList = data.rows
                        this.total = data.total
                    }
                })
            },
            //查看战略会详情
            detail(id){
                window.location.assign('/aboutUs/StratageInfo?id='+id)
            },
            handleCurrentChange(val){
                this.currentPage = val
                this.pageindex=val
                this.getMeetInfo()
            }
        }
	}
</script>
<style scoped>
    .tacticBox{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 36px;
    }
    .tactic{
        width: 380px;
        height: 351px;
        border: 1px solid #F2F2F2;
        margin-right: 30px;
        margin-bottom: 26px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .tactic:nth-child(3n){
        margin-right: 0;
    }
    .tacticImg{
        width: 380px;
        height: 271px;
    }
    .tacticDesc{
        width: 380px;
        height: 80px;
        line-height: 80px;
        padding-left: 30px;
    }
    .tacticDesc img{
        width:15px;
        height: 12px;
        margin-top: 34px;
        margin-right: 30px;
    }
    .pageBox{
        text-align: center;
        margin-top: 35px;
        margin-bottom: 42px;
    }
</style>