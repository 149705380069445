<template>
    <div class="contactUs">
        <linkUs tabIndex="2"></linkUs>
        <div class="feedBox">
            <div class="feedCont">感谢您的反馈，我们欢迎您的评论。请留下您的联系方式，以便我们能够就您评论的内容与您进行互动。以下标注星号的为必填项。更多工作机会信息，请参阅我们的人才招聘页面。 </div>
            <el-form class="formBox" ref="form" label-width="110px" :model="form" :rules="rules">
                <el-form-item label="姓名" prop="name">
                    <el-input type="text" v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone">
                    <el-input type="text" v-model="form.phone" maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="公司名称" prop="companyName">
                    <el-input type="text" v-model="form.companyName"></el-input>
                </el-form-item>
                <el-form-item label="电子邮件" prop="email">
                    <el-input type="text" v-model="form.email"></el-input>
                </el-form-item>
                <el-form-item label="选择省/地区" prop="areaCode">
                    <el-cascader v-model="form.areaCode" :options="options" style="width: 100%;" @change="changeArea"></el-cascader>
                </el-form-item>
                <el-form-item label="给我们留言" prop="content">
                    <el-input type="textarea" v-model="form.content"  style="width: 460px;" :rows="5" maxlength="500" show-word-limit></el-input>
                </el-form-item>
                <div class="subBtn">
                    <el-button @click="submitData('form')">提交</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import linkUs from '@/components/linkUs'
import { options } from '@/js/cityq'
import {map} from '@/js/map'
	export default {
		name:'ContactUs',
        components:{linkUs},
        created(){
            this.options = options;
            this.mapList = map
        },
        data() {
            return {
                form:{
                    name:'',
                    phone:'',
                    companyName:'',
                    email:'',
                    area:'',
                    areaCode:'',
                    content:'',
                    formtype:'feedback'
                },
                mapList:[],
                rules:{
                    name:{
                        required:true,
                        message:'请输入姓名',
                        trigger:'blur'
                    },
                    phone:{
                        required:true,
                        validator: this.mobileValid,
                        trigger:'blur'
                    },
                    companyName:{
                        required:true,
                        message:'请输入公司名称',
                        trigger:'blur'
                    },
                    email:{
                        required:true,
                        validator: this.emailValid,
                        trigger:'blur'
                    },
                    areaCode:{
                        required:true,
                        message:'请选择省市区',
                        trigger:'change'
                    },
                    content:{
                        required:true,
                        message:'请输入留言',
                        trigger:'blur'
                    }
                   
                }
            }
        },
        methods:{
             // 手机号码校验
             mobileValid(rule, value, callback) {
                if (!(/^1[3456789]\d{9}$/.test(value))) {
                    callback(new Error('请输入正确手机号码'));
                    this.isgetverifycode = false;
                } else {
                    this.isgetverifycode = true;
                    callback();
                }
            },
            // 邮箱校验
            emailValid(rule, value, callback) {
                if (!(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(value))) {
                    callback(new Error('请输入正确邮箱'))
                } else {
                    callback()
                }
            },
            //选择省/地区
            changeArea(e){
                var address1 = ''
                var address2 = ''
                var address3 = ''
                for (const item in map){
                    if (item == e[0]) {
                        address1 = map[item]
                    }
                    if (item == e[1]) {
                        address2 = map[item]
                    }
                    if (item == e[2]) {
                        address3 = map[item]
                    }
                }
                this.form.area = address1 + address2 + address3
            },
            // 提交意见反馈
            submitData(form){
                this.$refs[form].validate((valid)=>{
                    if(valid){
                        this.Request({
                            url: '/webindex/msgFeedback/add',
                            method:'post',
                            data:this.form
                        }).then((data) => {
                           if(data.code=='v'){
                            this.$message.success(data.msg)
                            this.$refs[form].resetFields();
                           }
                        })
                    }
                })
            }
        }
	}
</script>

<style scoped lang="less">
    .feedBox{
        width: 1125px;
        height: 1056px;
        background: url('../../assets/linkUs/feedBg.png');
        background-size: 100% 100%;
        margin: 0 auto;
        margin-top: -20px;
        margin-bottom: 30px;
        padding-top: 160px;
    }
    .feedCont{
        width: 640px;
        margin: 0 auto;
        color: #666;
        line-height: 30px;
    }
    .formBox{
        width: 580px;
        margin: 0 auto;
        margin-top: 37px;
    }
    .subBtn{
        width: 120px;
        margin: 0 auto;
        margin-top: 10px;
    }
    .subBtn button{
        width: 120px;
        height: 48px;
        background: #247CD6;
        color: #fff;
    }
</style>

<style>
    .feedBox .el-input{
        width: 460px;
        height: 56px;
    }
    .feedBox .el-input input{
        height: 56px;
        line-height: 56px;
    }
    .feedBox .el-form-item__label{
        line-height: 56px;
    }
</style>